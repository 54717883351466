export const TOP_BAR_MENU_OVERVIEW = 0;
export const TOP_BAR_MENU_CALENDAR = 1;

export const STREAM_MENU_STREAM = 0;
export const STREAM_MENU_TRACKER = 1;
export const STREAM_MENU_STATISTICS = 2;

export const CHAT_MENU_CHAT = 0;
export const CHAT_MENU_TICKETS = 1;
export const CHAT_MENU_MINI_GAMES = 2;
