export const OBJECT_TYPE_SPORT = `SPORT`;
export const OBJECT_TYPE_LEAGUE = `LEAGUE`;
export const OBJECT_TYPE_MATCH = `MATCH`;
export const OBJECT_TYPE_MATCH_MARKETS_COUNT = `MATCH_MARKETS_COUNT`;
export const OBJECT_TYPE_MATCH_OVERVIEW = `MATCH_OVERVIEW`;
export const OBJECT_TYPE_MINI_SCOREBOARD = `MINI_SCOREBOARD`;
export const OBJECT_TYPE_MARKET_GROUP = `MARKET_GROUP`;
export const OBJECT_TYPE_MARKET = `MARKET`;
export const OBJECT_TYPE_SCOREBOARD_DATA = `SCOREBOARD_DATA`;
export const OBJECT_TYPE_SCOREBOARD_OVERVIEW = `SCOREBOARD_OVERVIEW`;
export const OBJECT_TYPE_ODDS = `ODDS`;
export const OBJECT_TYPE_TOP_MARKET_ASSIGNMENT = `TOP_MARKET_ASSIGNMENT`;
export const OBJECT_TYPE_BETSLIP_NOTIFICATION = `BETSLIP_NOTIFICATION`;
export const OBJECT_TYPE_LOGOUT_NOTIFICATION = `LOGOUT`;
export const OBJECT_TYPE_BALANCE_UPDATE_NOTIFICATION = `BALANCE`;
export const OBJECT_TYPE_SIMPLE_NOTIFICATION = `SIMPLEMESSAGE`;
