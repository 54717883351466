// HE stands for Hero Element - it is convention which is used by measuring tools, please keep it

export const PERFORMANCE_MARK_OVERVIEW_LOADED = `HE-overview-loaded`;
export const PERFORMANCE_MARK_MAIN_MENU_LOADED = `HE-main-menu-loaded`;

export const PERFORMANCE_MARK_TICKET_SUBMIT_START = `HE-ticket-submit-start`;
export const PERFORMANCE_MARK_TICKET_SUBMIT_END = `HE-ticket-submit-end`;

// User clicks on Sport icon in Sport menu
export const PERFORMANCE_MARK_SPORT_CLICK = `HE-sport-select-start`;
// Sport page is loaded - e.g. Tenis, Football, All, ...
export const PERFORMANCE_MARK_SPORT_END = `HE-sport-select-end`;

// User clicks on some match detail
export const PERFORMANCE_MARK_MATCH_DETAIL_START = `HE-match-detail-start`;
// Match detail is done loading (we can see its markets for example)
export const PERFORMANCE_MARK_MATCH_DETAIL_END = `HE-match-detail-end`;

// User clicked on some market odd in order to put it on the ticket
export const PERFORMANCE_MARK_BET_TICKET_CLICK = `HE-match-bet-ticket-start`;
// User's odd was added to the ticket
export const PERFORMANCE_MARK_BET_TICKET_ADDED = `HE-match-bet-ticket-end`;
