<template>
  <div class="error">
    <div class="error_404">
      DUMMY 404
    </div>
  </div>
</template>

<script>
  import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
  import { mapGetters } from 'vuex';

  export default {
    name: `NotFound`,
    computed: {
      ...mapGetters(`AppConfigurationStore`, {
        getConfigurationValue: APP_CONF_GET_CONF_VALUE,
      }),
      notFoundUrl() {
        return this.getConfigurationValue(`notFoundUrl`);
      },
    },
    mounted() {
      if (this.notFoundUrl) window.location.href = this.notFoundUrl;
    },
  };

</script>

<style lang="scss">
  .error {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;

    &_404 {
      color: var(--color-negative--darker);
    }
  }
</style>
