import { STOMP_CONNECTION_ERROR, STOMP_CONNECTION_SUCCESS } from '@/globals/enums/eventBus/StompEvents.enums';
import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
import translation from '@/plugins/translation';
import SockJs from 'sockjs-client';
import Stomp from 'webstomp-client';
import { ADD_DISCONNECTION_MESSAGE, DELETE_DISCONNECTION_MESSAGE } from '@/stores/WebsocketStore/constants';

import EventBus from '../services/EventBus';
import store from './store';

const CLOSE_EVENT_CODE = 1002;

export class SubscriptionManager {
  constructor(
    {
      onConnected = () => null,
      onError = () => null,
      reconnectAfterMillis = 1000,
      reconnectRetries = 5,
      // eslint-disable-next-line no-undef
      debug = NODE_ENV !== `production`,
      connectionOptions = {},
    } = {}) {
    this.DEFAULT_RECONNECTION_TIME = reconnectAfterMillis;
    this.MAX_TIMES_TO_RECONNECT = reconnectRetries;
    this.TIMES_RECONNECTED = 0;
    store.watch(
      (state) => state.AppConfigurationStore.configuration,
      () => {
        this.PERIOD_TO_WAIT_UNTIL_DISCONNECT_WARNING_SHOWN = store.getters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`msUntilDisconnectWarning`) || 5000;
        this.PERIOD_TO_WAIT_UNTIL_DISCONNECT_POPUP_SHOWN = store.getters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`msUntilDisconnectPopup`) || 15000;
      },
    );
    this.STOMP_CLIENT_OPTS = { debug, ...connectionOptions };
    this.ON_CONNNECTED_CALLBACK = onConnected;
    this.ON_ERROR_CALLBACK = onError;
    this.DISCONNECTION_POPUP_SHOWN = false;

    this.PENDING_SUBSCRIPTION_POOL = {};
    this.ACTIVE_SUBSCRIPTION_POOL = {};
    this.STOMP_CONNECTION_IS_ONLINE = true;

    this.shouldPreventWebsocketErrorEmit = false;

    // eslint-disable-next-line no-return-assign
    window.onbeforeunload = () => {
      this.shouldPreventWebsocketErrorEmit = true;
    };
    this.bindInternetConnectionListeners();
  }

  bindInternetConnectionListeners = () => {
    window.addEventListener(`offline`, () => {
      const closeEvent = new Event(`close`, this.generateCloseEventConfig());

      this.onConnectionError(closeEvent);
    });

    // Start checking for internet connection
    window.addEventListener(`online`, () => {
      this.tryReconnect();
    });
  };

  getCurrentTimestamp = () => new Date().getTime();

  generateCloseEventConfig = () => ({
    bubbles: false,
    cancelable: false,
    code: CLOSE_EVENT_CODE,
    reason: `Cannot connect to server`,
    timeStamp: this.getCurrentTimestamp(),
    type: `close`,
    wasClean: false,
  });

  setConnectionUrl = (url) => {
    this.url = url;
    this.client = this.createConnectionConstructor();
    return this;
  };

  addPendingSubscription = ({ id, topic, callback, headers = {} }) => {
    headers.id = id;
    this.PENDING_SUBSCRIPTION_POOL[id] = { id, topic, callback, headers };
  };

  removePendingSubscription = (id) => {
    delete this.PENDING_SUBSCRIPTION_POOL[id];
  };

  getPendingSubscription = (id) => this.PENDING_SUBSCRIPTION_POOL[id];

  addActiveSubscription = (subscriptionData) => {
    this.ACTIVE_SUBSCRIPTION_POOL[subscriptionData.id] = subscriptionData;
  };

  getActiveSubscription = (id) => this.ACTIVE_SUBSCRIPTION_POOL[id];

  removeActiveSubscription = (id) => {
    delete this.ACTIVE_SUBSCRIPTION_POOL[id];
  };

  // eslint-disable-next-line no-console
  log = (...logs) => this.STOMP_CLIENT_OPTS.debug && console.log(...logs);

  createConnectionConstructor = () => Stomp.over(new SockJs(this.url), this.STOMP_CLIENT_OPTS);

  connect = () => {
    if (this.client && this.client.connected) {
      this.client.disconnect();
    }

    this.client.connect({}, this.onConnectionConnected, this.onConnectionError);
    // eslint-disable-next-line no-console
    this.client.debug = (log) => this.STOMP_CLIENT_OPTS.debug && console.warn(log);
  };

  disconnect = () => {
    if (this.client && this.client.connected) {
      this.client.disconnect();
    }
  };

  isClientConnected() {
    return this.client && this.client.connected;
  }

  onConnectionConnected = () => {
    this.TIMES_RECONNECTED = 0;
    this.STOMP_CONNECTION_IS_ONLINE = true;
    this.DISCONNECTION_POPUP_SHOWN = false;
    this.log(`STOMP client Connected!`);
    store.commit(`WebsocketStore/${DELETE_DISCONNECTION_MESSAGE}`);
    this.subscribeToPendingSubscriptions();
    this.ON_CONNNECTED_CALLBACK();
  };

  showDisconnectionErrorPopup = (...args) => {
    if (this.MAX_TIMES_TO_RECONNECT >= this.TIMES_RECONNECTED) {
      !this.shouldPreventWebsocketErrorEmit && this.ON_ERROR_CALLBACK(args, {
        clientWasDisconnected: !this.STOMP_CONNECTION_IS_ONLINE,
        reconnectionAttempt: this.TIMES_RECONNECTED,
        maxReconnectionsAttempts: this.MAX_TIMES_TO_RECONNECT,
        timeUntilNextAttempt: this.getRetryDelay(this.TIMES_RECONNECTED),
      });
      this.TIMES_RECONNECTED += 1;
    }
  };

  getRetryDelay = (attemptNumber) => this.DEFAULT_RECONNECTION_TIME * attemptNumber;

  onConnectionError = (...args) => {
    if (this.STOMP_CONNECTION_IS_ONLINE) {
      this.log(`Connection error!`);
      this.STOMP_CONNECTION_IS_ONLINE = false;
      this.handleSoftDisconnect()
        .then(() => {
          this.handleHardDisconnect(args);
        });
    }

    if (!this.STOMP_CONNECTION_IS_ONLINE && this.TIMES_RECONNECTED <= this.MAX_TIMES_TO_RECONNECT) {
      let delay = 0;

      if (this.TIMES_RECONNECTED === 0) {
        delay = Math.floor(Math.random() * this.PERIOD_TO_WAIT_UNTIL_DISCONNECT_WARNING_SHOWN);
      } else {
        delay = this.getRetryDelay(this.TIMES_RECONNECTED);
      }

      this.log(`Reconnecting times ${this.TIMES_RECONNECTED}, time to try again in ${delay}ms`);

      setTimeout(() => {
        this.tryReconnect();
      }, delay);

      if (this.DISCONNECTION_POPUP_SHOWN) {
        this.showDisconnectionErrorPopup(args);
      }
    }
  };

  tryReconnect = () => {
    if (this.STOMP_CONNECTION_IS_ONLINE) {
      return;
    }

    this.restartConnection();
  };

  restartConnection = () => {
    clearTimeout(this.subTimer);
    this.moveActiveSubscriptionsToPending();
    this.client = this.createConnectionConstructor();
    this.connect();
  };

  moveActiveSubscriptionsToPending = () => {
    Object
      .values(this.ACTIVE_SUBSCRIPTION_POOL)
      .forEach(({ topic, callback, headers, id }) => {
        this.removeActiveSubscription(id);
        this.addPendingSubscription({ id, topic, callback, headers });
      });
  };

  subscribeToPendingSubscriptions = () => {
    if (!this.isClientConnected()) {
      return;
    }

    Object
      .values(this.PENDING_SUBSCRIPTION_POOL)
      .forEach(({ topic, callback, headers, id }) => {
        this.removePendingSubscription(id);

        if (this.ACTIVE_SUBSCRIPTION_POOL[id]) return;

        this.client.subscribe(topic, callback, headers);
        this.addActiveSubscription({ id, topic, callback, headers });
      });
  };

  subscribe = (id, topic, callback, headers = {}) => {
    clearTimeout(this.subTimer);
    this.addPendingSubscription({ id, topic, callback, headers });
    this.subTimer = setTimeout(this.subscribeToPendingSubscriptions, 100);
  };

  unsubscribe = (id, headers = {}) => {
    if (this.getPendingSubscription(id)) this.removePendingSubscription(id);

    if (this.getActiveSubscription(id) || this.client.subscriptions[id]) {
      this.client.unsubscribe(id, headers);
      this.removeActiveSubscription(id);
    }
  };

  handleSoftDisconnect = () => new Promise((resolve, reject) => {
    setTimeout(() => {
      // Check if user still disconnected and show banner
      if (!this.STOMP_CONNECTION_IS_ONLINE) {
        store.commit(`WebsocketStore/${ADD_DISCONNECTION_MESSAGE}`, {
          title: translation.getTranslatedMessage(`live3.connection.banner.title`),
          message: translation.getTranslatedMessage(`live3.connection.banner.message`),
        });
        return resolve();
      }
      return reject();
    }, this.PERIOD_TO_WAIT_UNTIL_DISCONNECT_WARNING_SHOWN);
  });

  handleHardDisconnect = (args) => new Promise((resolve, reject) => {
    setTimeout(() => {
      // Check if user still disconnected and show pop up
      if (!this.STOMP_CONNECTION_IS_ONLINE) {
        this.DISCONNECTION_POPUP_SHOWN = true;
        this.showDisconnectionErrorPopup(args);
        return resolve();
      }

      return reject();
    }, this.PERIOD_TO_WAIT_UNTIL_DISCONNECT_POPUP_SHOWN);
  });
}

const onConnected = (err) => EventBus.$emit(STOMP_CONNECTION_SUCCESS, err);
const onError = (...args) => EventBus.$emit(STOMP_CONNECTION_ERROR, ...args);

const fortunaSubscription = new SubscriptionManager({
  onConnected,
  onError,
  debug: false,
  reconnectAfterMillis: 5000,
  reconnectRetries: 10,
});

export default fortunaSubscription;

export function stompSubscribe(id, topic, callback, headers = {}) {
  if (!MOCK) {
    fortunaSubscription.subscribe(id, topic, callback, headers);
  } else {
    import(`../../mock/mockStomp.service`)
      .then(({ mockObserver }) => mockObserver)
      .then((mockObserver) => {
        mockObserver.subscribe((mockedMessage) => {
          if (mockedMessage.type === topic) callback(mockedMessage.message);
        });
      });
  }
}

export function stompUnsubscribe(id, headers = {}) {
  fortunaSubscription.unsubscribe(id, headers);
}

export function stompRestartConnection() {
  fortunaSubscription.tryReconnect();
}
