/**
 * Returns number value from string pixel CSS variable
 * e.g. "10px" => 10
 * @param {string} cssVariableName - name of CSS variable "--height-in-px"
 * @returns {number|undefined}
 */
export function getCSSVariableValue(cssVariableName) {
  const strValue = getComputedStyle(document.documentElement)
    .getPropertyValue(cssVariableName);

  if (strValue === ``) return undefined;

  // FYI parseInt(' 33px', 10); returns 33
  return parseInt(strValue, 10);
}
