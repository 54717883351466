export const SPORT_TYPE_ENTERTAINMENT = `ENTERTAINMENT`;
export const SPORT_TYPE_CYCLING = `CYCLING`;
export const SPORT_TYPE_FORMULA_1 = `FORMULA_1`;
export const SPORT_TYPE_ALPINE_SKIING = `ALPINE_SKIING`;
export const SPORT_TYPE_ATHLETICS = `ATHLETICS`;
export const SPORT_TYPE_CROSS_COUNTRY = `CROSS_COUNTRY`;
export const SPORT_TYPE_BIATHLON = `BIATHLON`;
export const SPORT_TYPE_CURLING = `CURLING`;
export const SPORT_TYPE_GOLF = `GOLF`;
export const SPORT_TYPE_MOTO_GP = `MOTO_GP`;
export const SPORT_TYPE_SPEEDWAY = `SPEEDWAY`;
export const SPORT_TYPE_SPEED_SKATING = `SPEED_SKATING`;
export const SPORT_TYPE_SNOWBOARDING = `SNOWBOARDING`;
export const SPORT_TYPE_SKI_JUMPING = `SKI_JUMPING`;
export const SPORT_OLYMPIC_GAMES = `OLYMPIC_GAMES`;
export const SPORT_NORDIC_COMBINATION = `NORDIC_COMBINATION`;

export const RACE_SPORT_TYPES = [
  SPORT_TYPE_ENTERTAINMENT,
  SPORT_TYPE_CYCLING,
  SPORT_TYPE_FORMULA_1,
  SPORT_TYPE_ALPINE_SKIING,
  SPORT_TYPE_ATHLETICS,
  SPORT_TYPE_CROSS_COUNTRY,
  SPORT_TYPE_BIATHLON,
  SPORT_TYPE_GOLF,
  SPORT_TYPE_MOTO_GP,
  SPORT_TYPE_SPEEDWAY,
  SPORT_TYPE_SPEED_SKATING,
  SPORT_TYPE_SNOWBOARDING,
  SPORT_TYPE_SKI_JUMPING,
  SPORT_OLYMPIC_GAMES,
  SPORT_NORDIC_COMBINATION,
];
