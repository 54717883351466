<template>
  <div
    :class="miniScoreboardColumnType"
    class="mini_scoreboard_column"
  >
    {{ team1Value }}<br>
    {{ team2Value }}
  </div>
</template>

<script>
  const EMPTY_VALUE = `-`;

  export default {
    name: `MiniScoreboardColumn`,
    props: {
      column: {
        type: Object,
        required: true,
      },
    },
    computed: {
      team1Value() {
        return this.column.team1 || EMPTY_VALUE;
      },
      team2Value() {
        return this.column.team2 || EMPTY_VALUE;
      },
      miniScoreboardColumnType() {
        return {
          [`mini_scoreboard_column--type_${this.column.type || ``}`]: true,
        };
      },
    },
  };

</script>

<style lang="scss">
  $col: '.mini_scoreboard_column';

  .mini_scoreboard--mode-COLUMN {

    overflow: hidden;

    #{$col} + #{$col} {
      border-left: 1px solid var(--color-background--lighter);
    }

    #{$col} {
      height: 100%;
      font-size: 12px;
      padding: 3px;
      overflow: hidden;
      text-align: center;
      line-height: 20px;
    }

    #{$col}--type_TOTAL_SCORE {
      grid-area: column2;
      color: var(--color-primary);
    }

    #{$col}--type_PARTIAL_SCORE_L1 {
      grid-area: column3;
    }

    #{$col}--type_PARTIAL_SCORE_L2 {
      grid-area: column4;
    }
  }

  .mini_scoreboard--no_server {
    #{$col}--type_TOTAL_SCORE {
      grid-area: column1;
    }

    #{$col}--type_PARTIAL_SCORE_L1 {
      grid-area: column2;
    }

    #{$col}--type_PARTIAL_SCORE_L2 {
      grid-area: column3;
    }
  }
</style>
