<template>
  <div
    v-if="matches.length > 0"
    class="league"
  >
    <LiveLeagueHeader
      v-if="!hideHeader"
      :leagueName="names"
      :leagueId="leagueId"
      :leagueMatchCount="matches.length"
      :showLeagueTopMarketNames="containsOnlyStreamMatches && !isRaceSportType"
      :collapsed="isCollapsed"
      :topMarketNames="topMarketNames"
      :isFavoriteLeague="true"
      :short="short"
      :showLiveBadge="showLiveBadge"
      selectedLeagueId=""
      :sportId="sportId"
      :collapsingCategory="collapsingCategory"
    />
    <HeightTransitionUtil>
      <div v-if="!isCollapsed">
        <LiveMatch
          v-for="(match) in matches"
          :key="match.id"
          :leagueId="leagueId"
          :matchId="match.id"
          :sportType="sportType"
          :matchDetailStyle="short"
          :marketsVisible="showTopMarket"
          :isLiveMatch="!match.mirrorReflex && isFeatureSupported(FEATURE_CB_LIVE)"
        />
      </div>
    </HeightTransitionUtil>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import {
    actions as liveSportStoreActions,
    getters as liveSportGetters,
  } from '@/stores/LiveSportStore';
  import { IS_LEAGUE_COLLAPSED } from '@/stores/LiveLeagueStore/constants';
  import { getters as matchGetters } from '@/stores/LiveMatchStore';
  import translation from '@/plugins/translation';
  import { FEATURE_CB_LIVE } from '@/config/constants/features';
  import { SportSortsFiltersType as FiltersType } from "@/globals/enums/enums";

  import HeightTransitionUtil from '@/globals/components/HeightTransitionUtil/HeightTransitionUtil';
  import LiveLeagueHeader from '../LiveLeague/LiveLeagueHeader';
  import LiveMatch from '../LiveMatch/LiveMatch';

  export default {
    components: {
      LiveLeagueHeader,
      LiveMatch,
      HeightTransitionUtil,
    },
    props: {
      sportId: {
        type: String,
        required: true,
      },
      sportType: {
        type: String,
        required: true,
      },
      short: {
        type: Boolean,
        default: false,
      },
      showTopMarket: {
        type: Boolean,
        required: false,
        default: false,
      },
      collapsingCategory: {
        type: String,
        default: `favorites`,
      },
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getFavoriteMatchesBySport: matchGetters.FAVORITE_MATCHES_BY_SPORT,
        isLeagueCollapsed: IS_LEAGUE_COLLAPSED,
        getSportFavoriteCollapseState: liveSportGetters.GET_SPORT_FAVORITE_COLLAPSE_STATE,
      }),
      ...mapState(`LiveStore`, {
        isStreamFilterActive: ({ LiveSportStore: { filtersMap } }) => !!filtersMap[FiltersType.FILTER_HAS_STREAM],
      }),
      leagueId() {
        return `FAVORITES.${this.sportId}`;
      },
      info() {
        return {
          id: this.leagueId,
          category: this.collapsingCategory,
        };
      },
      leagueMatches() {
        return this.getFavoriteMatchesBySport(this.sportId);
      },
      leagueMatchesWithStream() {
        return this.leagueMatches.filter((match) => !match.appliedFilter?.includes(FiltersType.FILTER_HAS_STREAM));
      },
      containsOnlyStreamMatches() {
        return this.leagueMatches.length !== 0 && this.leagueMatchesWithStream.length === 0;
      },
      matches() {
        return this.isStreamFilterActive ? this.leagueMatchesWithStream : this.leagueMatches;
      },
      names() {
        return translation.getTranslatedMessage(`live3.favorite.league.label`);
      },
      topMarket1Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.1`);
      },
      topMarket2Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.2`);
      },
      topMarket3Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.3`);
      },
      topMarketNames() {
        return [
          this.topMarket1Name,
          this.topMarket2Name,
          this.topMarket3Name,
        ];
      },
      showLiveBadge() {
        return this.isFeatureSupported(FEATURE_CB_LIVE)
          && this.matches.some(({ mirrorReflex }) => mirrorReflex === false);
      },
      isCollapsed() {
        return this.isLeagueCollapsed(this.info);
      },
      hideHeader() {
        return this.short;
      },
    },
    methods: {
      ...mapActions(`LiveStore`, {
        resetSportFilters: liveSportStoreActions.RESET_SPORT_FILTERS,
      }),
    },
  };
</script>
