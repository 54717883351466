import safeGet from 'lodash/get';

export function getOrDefault(target, path, defaultValue = undefined, predicate = () => true) {
  const value = safeGet(target, path, defaultValue);

  return predicate(value) ? value : defaultValue;
}

export function getNonNull(target, path, defaultValue = undefined) {
  return getOrDefault(target, path, defaultValue, (value) => value !== null);
}

export function getNonFalse(target, path, defaultValue) {
  return getOrDefault(target, path, defaultValue, (value) => value !== false);
}
