import { SITE, subscriptionIds, topics } from '@/config/constants';
import { stompSubscribe, stompUnsubscribe } from '@/config/stomp';
import { parseIncomingMessage, processChangeMessage } from '@/globals/websocket/utils';
import handlersByMessageType from '../websocket/MarketDataWSHandler';
import { SubBox } from '../SubBox';

const subscribeMarket = (marketId) => {
  stompSubscribe(subscriptionIds.marketData(marketId),
    topics.marketData(SITE, marketId),
    (response) => {
      const message = parseIncomingMessage(response);

      processChangeMessage(message, handlersByMessageType, { marketId });
    });
};

const unsubscribeMarket = (marketId) => {
  stompUnsubscribe(subscriptionIds.marketData(marketId));
};

const MarketSubBox = new SubBox(subscribeMarket, unsubscribeMarket);

export { MarketSubBox };
