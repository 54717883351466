<template>
  <router-link
    :to="matchDetailRoute"
    class="live-match-info"
    :class="{
      'live-match-info--short': matchDetailStyle,
      'live-match-info--with-live-badge': isLiveMatch,
    }"
  >
    <div class="live-match-info__team live-match-info__team--1">
      {{ team1Names }}
    </div>
    <div class="live-match-info__team live-match-info__team--2">
      {{ team2Names }}
    </div>

    <LiveBadge
      :isLiveMatch="isLiveMatch"
    />

    <FavoritesButton
      :matchId="matchId"
      :stream="stream"
      :matchTrackerType="matchTrackerType"
      :showGameTime="showGameTime"
    />

    <InfoMatchStreamButton
      :matchId="matchId"
      :matchTrackerType="matchTrackerType"
      :stream="stream"
      :showGameTime="showGameTime"
    />

    <TooltipWrapper
      v-if="showGameTime"
      :text="tooltip"
      class="live-match-info__game-time"
    >
      {{ text }}
    </TooltipWrapper>

    <MiniScoreboard
      class="live-match-info__mini-scoreboard"
      :gameTime="gameTime"
      :miniScoreboard="matchMiniScoreboard"
      :showServer="showServer"
      :sportType="sportType"
    />
  </router-link>
</template>

<script>
  import { mapGetters } from 'vuex';

  import MiniScoreboard from "@/globals/components/MiniScoreboard/MiniScoreboard";
  import { SPORT_HOCKEY } from '@fortunaweb-fe/frontend-commons/dist/config/constants';
  import { SCOREBOARD_MODE_ONE_LINE } from "@/globals/enums/ScoreboardMode.enums";
  import { SPORT_TYPE_CURLING } from "@/globals/enums/RaceTypeSports.enums";
  import { FEATURE_CB_LIVE } from '@/config/constants/features';
  import LiveBadge from '@/globals/components/LiveMatch/LiveBadge';
  import FavoritesButton from '@/globals/components/LiveMatch/FavoritesButton';
  import InfoMatchStreamButton from '@/globals/components/LiveMatch/InfoMatchStreamButton';
  import { formatScoreboardDate } from '@/routes/MatchDetail/Scoreboard/ScoreboardHelpers';
  import { parseDate } from '@/services/TimeService';
  import { getters as liveMatchGetters } from '@/stores/LiveMatchStore';
  import TooltipWrapper from '@fortunaweb-fe/mooncake/dist/TooltipWrapper';
  import { stripTags } from '@/globals/utils/string';

  const hideMiniScoreboardServerForSports = [
    SPORT_TYPE_CURLING,
  ];

  export default {
    name: `LiveMatchInfo`,
    components: {
      MiniScoreboard,
      LiveBadge,
      FavoritesButton,
      InfoMatchStreamButton,
      TooltipWrapper,
    },
    props: {
      matchOverview: {
        type: Object,
        required: true,
      },
      matchId: {
        type: String,
        required: true,
      },
      sportId: {
        type: String,
        required: false,
        default: null,
      },
      sportType: {
        type: String,
        required: true,
      },
      matchDetailRoute: {
        type: Object,
        required: true,
      },
      matchMiniScoreboard: {
        type: Object,
        required: true,
      },
      matchTracker: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      team1Names: {
        type: String,
        required: true,
      },
      team2Names: {
        type: String,
        required: true,
      },
      stream: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      matchDetailStyle: {
        type: Boolean,
        required: false,
        default: false,
      },
      isLiveMatch: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getMatchById: liveMatchGetters.GET_MATCH_BY_ID,
      }),
      match() {
        return this.getMatchById(this.matchId);
      },
      matchTrackerType() {
        return this.matchTracker?.type;
      },
      isOneLineMode() {
        return this.matchMiniScoreboard.mode === SCOREBOARD_MODE_ONE_LINE;
      },
      showGameTime() {
        return !this.isOneLineMode;
      },
      showServer() {
        return (hideMiniScoreboardServerForSports.indexOf(this.sportType) === -1) && !this.isOneLineMode;
      },
      gameTime() {
        if (this.sportType === SPORT_HOCKEY && this.matchOverview?.hockeyGameTime) {
          return this.matchOverview.hockeyGameTime;
        }

        const { shortGameTime: short = null, gameTime: long = null } = this.matchOverview || {};

        return { short, long };
      },
      gameStartDate() {
        const { startDate } = this.match;
        // TODO check
        const formattedStartDate = formatScoreboardDate(parseDate(startDate), true);

        return {
          short: formattedStartDate,
          long: formattedStartDate,
        };
      },
      showTimeOrStartDate() {
        if (this.isFeatureSupported(FEATURE_CB_LIVE)) {
          return this.isLiveMatch ? this.gameTime : this.gameStartDate;
        }

        return this.gameTime;
      },
      text() {
        return stripTags(this.showTimeOrStartDate.short);
      },
      tooltip() {
        return stripTags(this.showTimeOrStartDate.long);
      },
    },
  };
</script>

<style lang="scss">
  $baseClass: '.live-match-info';

  #{$baseClass} {
    width: $match_header_width;
    min-width: $match_header_width;
    color: var(--color-primary-text);
    font-size: 12px;
    height: $match_row_height;
    max-height: $match_row_height;
    box-sizing: border-box;
    text-decoration: none;
    display: grid;
    text-align: right;
    grid-template-rows: 50% 50%;
    gap: 0 0;
    align-items: center;
    grid-template-columns: fit-content(200px) 1fr 20px auto $match_mini_scoreboard_width;
    grid-template-areas:
          "team1 live-badge stream-tracker favorite miniscoreboard"
          "team2 live-badge-2 game-info game-info-action miniscoreboard";

    &--short {
      width: 100%;
      min-width: $match_header_width--detail;
    }

    &__team {
      padding-left: 10px;
      padding-right: 5px;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;

      &--1 {
        padding-top: 5px;
        grid-area: team1;
      }

      &--2 {
        grid-area: team2;
        padding-bottom: 5px;
      }
    }

    &__game-time {
      grid-area: game-info / span 3;
      padding: 0 3px;
      white-space: nowrap;
      overflow: hidden;
      color: var(--color-secondary-text--darker);

      &:hover {
        color: var(--color-secondary-text--darker);
      }
    }

    &__mini-scoreboard {
      grid-area: miniscoreboard;
      border-left: 1px solid var(--color-background);
      flex-shrink: 0;
    }

    &:hover {
      @if ($theme == 'light') {
        background-color: var(--color-white);
      } @else {
        background-color: var(--color-background--darkest);
      }
    }
  }
</style>
