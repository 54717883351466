import { parseDate } from '@/services/TimeService';
import { SITE } from "@/config/constants";
import { normalize, schema } from 'normalizr';
import { getLocalized } from '../utils/localizations';
import { utoa } from '../utils/string';

function getMarketMapping(marketRows, marketId) {
  const marketMapping = {};

  if (marketRows) {
    marketRows.forEach((marketRowId) => {
      marketMapping[marketRowId] = marketId;
    });
  }

  return marketMapping;
}

export const LiveOddsSchema = new schema.Entity(`odds`, {}, {
  idAttribute: (value, parent) => parent.id,
  processStrategy: (value) => Object
    .values(value)
    .reduce((acc, odds) => {
      odds.forEach((odd) => {
        odd.names = getLocalized(odd, `names`, ``);
        odd.longNames = getLocalized(odd, `longNames`, ``);
        const id = odd.id ?? utoa(odd.names);

        acc[id] = odd;
      });
      return acc;
    }, {}),
});

export const LiveMarketSchema = new schema.Entity(`markets`, {
  odds: LiveOddsSchema,
}, {
  processStrategy(market /* , parent, key */) {
    const marketMapping = getMarketMapping(market.marketRows, market.id);

    const names = getLocalized(market, `names`, ``);
    const subNames = getLocalized(market, `subNames`, ``);
    const descriptions = getLocalized(market, `descriptions`, ``);

    return {
      ...market,
      names,
      subNames,
      descriptions,
      supportGroup: { ...market.supportGroup },
      supportGroupEx: { ...market.supportGroupEx },
      marketMapping,
    };
  },
});

export function normalizeMarket(market) {
  const normalizedData = normalize({ ...market }, LiveMarketSchema);
  const {
    markets,
  } = normalizedData.entities;

  return markets[market.id];
}

export const LiveTopMarketSchema = new schema.Entity(`topMarkets`,
  {
    market: LiveMarketSchema,
  },
  {
    processStrategy(topMarket) {
      const label = getLocalized(topMarket, `label`, ``);

      return {
        ...topMarket,
        label: !label ? `` : label.replace(/\.(?=\w)/g, `. `),
      };
    },
  });

export const LiveMatchOverviewSchema = new schema.Entity(`overview`, {}, {
  idAttribute: `matchId`,
  processStrategy(overview) {
    const actual = getLocalized(overview, `actual`, ``);
    const gameTime = getLocalized(overview, `gameTime`, ``);
    const shortGameTime = getLocalized(overview, `shortGameTime`, ``);
    const info = getLocalized(overview, `info`, ``);
    const shortActual = getLocalized(overview, `shortActual`, ``);
    const shortInfo = getLocalized(overview, `shortInfo`, ``);

    return { ...overview, actual, gameTime, shortGameTime, info, shortActual, shortInfo };
  },
});

export const LiveMiniScoreBoardSchema = new schema.Entity(`miniScoreboard`, {}, { idAttribute: `matchId` });

export const LiveMatchSchema = new schema.Entity(`matches`, {
  overview: LiveMatchOverviewSchema,
  miniScoreboard: LiveMiniScoreBoardSchema,
  topMarkets: new schema.Array(LiveTopMarketSchema),
}, {
  processStrategy(match) {
    const team1Names = getLocalized(match, `team1Names`, ``);
    const team2Names = getLocalized(match, `team2Names`, ``);
    const names = getLocalized(match, `names`, ``);
    const competitionNames = getLocalized(match, `competitionNames`, ``);
    const topMarkets = Object
      .keys(match.topMarkets)
      .map((slotId) => ({ ...match.topMarkets[slotId], matchId: match.id, slotId }));

    return {
      ...match,
      team1Names,
      team2Names,
      names,
      competitionNames,
      topMarkets,
    };
  },
});

export const LiveLeagueSchema = new schema.Entity(`leagues`,
  { matches: [LiveMatchSchema] },
  {
    processStrategy(league) {
      const names = getLocalized(league, `names`, ``);

      if (!league.flag) league.flag = undefined;
      return { ...league, names };
    },
  });

export const LiveSportSchema = new schema.Entity(`sports`,
  { leagues: [LiveLeagueSchema] },
  {
    processStrategy(sport) {
      const names = getLocalized(sport, `names`, ``);

      return { ...sport, names };
    },
  });

export const LiveSportArraySchema = new schema.Array(LiveSportSchema);

export const LiveMbmStatsSchema = new schema.Entity(`mbm`, {}, {
  idAttribute: (val, parent) => parent.matchId,
  processStrategy(stats) {
    return getLocalized({ stats }, `stats`, []);
  },
});

export const LiveScoreboardOverviewSchema = new schema.Entity(`overviews`, {
  mbm: LiveMbmStatsSchema,
}, {
  idAttribute: (value, parent) => value.matchId || parent.matchId,
  processStrategy(overview, parent) {
    const competitionNames = getLocalized(overview, `competitionNames`, ``);
    const gameTime = getLocalized(overview, `gameTime`, ``);
    const shortGameTime = getLocalized(overview, `shortGameTime`, ``);
    const names = getLocalized(overview, `names`, ``);
    const sportNames = getLocalized(overview, `sportNames`, ``);
    const team1Names = getLocalized(overview, `team1Names`, ``);
    const team2Names = getLocalized(overview, `team2Names`, ``);

    return {
      ...overview,
      matchId: overview.matchId || parent.matchId,
      competitionNames,
      gameTime,
      shortGameTime,
      names,
      sportNames,
      team1Names,
      team2Names,
    };
  },
});

export const LiveScoreboardDataSchema = new schema.Entity(`data`, {}, {
  idAttribute: (value, parent) => value.matchId || parent.matchId,
});

export const LiveScoreboardSchema = new schema.Entity(`scoreboards`, {
  overview: LiveScoreboardOverviewSchema,
  data: LiveScoreboardDataSchema,
}, {
  idAttribute: `matchId`,
});

export const ParticipantsSchema = new schema.Entity(`participants`, {
}, {
  idAttribute: (_, parent) => parent.matchId,
  processStrategy(participants) {
    const home = getLocalized(participants.HOME, `name`, `-`);
    const away = getLocalized(participants.AWAY, `name`, `-`);

    return { home, away };
  },
});

export const MarketGroupSchema = new schema.Entity(`groups`,
  { markets: [LiveMarketSchema] },
  {
    processStrategy(marketGroup) {
      const names = getLocalized(marketGroup, `names`, ``);

      return { ...marketGroup, names };
    },
  });

export const TicketSchema = new schema.Entity(`ticket`, {
  markets: [LiveMarketSchema],
});

export const LiveMatchDetailSchema = new schema.Entity(`matchDetail`, {
  groups: [MarketGroupSchema],
  scoreboard: LiveScoreboardSchema,
  participants: ParticipantsSchema,
});

export const AcceptedTicketLegsSchema = new schema.Entity(`legs`, {}, {
  idAttribute: (_leg, parent) => parent.numberHashed,
  processStrategy: ({ marketId, selectionId, ...rest }) => [{
    marketId: `L${SITE}${marketId}`,
    selectionId: `L${SITE}${selectionId}`,
    ...rest,
  }],
  mergeStrategy: (entityA, entityB) => [...entityA, ...entityB],
});

export const AcceptedTicketSchema = new schema.Entity(`tickets`, {
  legs: [AcceptedTicketLegsSchema],
}, {
  idAttribute: (ticket) => ticket.numberHashed,
});

export const TicketHistorySchema = new schema.Array(AcceptedTicketSchema);

export const CalendarMatchesSchema = new schema.Entity(`calendarMatches`, {}, {
  processStrategy({ enabledSites, leagueName, ...match }) {
    const team1Name = getLocalized(match, `team1Name`, ``);
    const team2Name = getLocalized(match, `team2Name`, ``);
    const league = getLocalized(leagueName, ``);
    const parsedStart = parseDate(match.start).valueOf();

    return {
      ...match,
      team1Name,
      team2Name,
      league,
      parsedStart,
    };
  },
});

export const CalendarSchema = new schema.Entity(`calendarSports`, {
  matches: [CalendarMatchesSchema],
}, {
  processStrategy({ sportType: sport, name, ...match }) {
    const names = getLocalized(name, ``);

    return { ...match, sport, names };
  },
});

export const WebMessageSchema = new schema.Entity(`messages`, {}, {
  // i18n is localized map
  processStrategy({ i18n, ...message }) {
    const text = getLocalized(i18n, ``);

    return { ...message, text };
  },
});
