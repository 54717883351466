<template>
  <component
    :is="component"
    :text="tooltip"
    class="odds_button"
    :class="buttonClassNames"
    :onlyIfOverflowing="!isLocked"
    ellipsisElemClassName="odds_button__name"
    @click="toggle"
  >
    <span class="odds_button__name">
      {{ oddName }}
    </span>
    <Icon
      v-if="isLocked"
      name="lock"
      class="odds_button__value"
    />
    <span
      v-else-if="!isReadOnly"
      class="odds_button__value"
    >
      {{ formattedValue }}
    </span>
  </component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { addBet, removeBetslipOdd } from '@/services/http/TicketHttpHandler';
  import constants from '@/config/constants';
  import TooltipWrapper from '@fortunaweb-fe/mooncake/dist/TooltipWrapper';
  import Icon from '@/globals/components/Icon/Icon';
  import { formatOddsValue } from '@/globals/utils/formatters';
  import { getters as liveOddsGetters } from '@/stores/LiveOddsStore';

  import {
    ODDS_BUTTON_NAME_TYPE_SHORT,
    ODDS_BUTTON_STYLE_TYPE_HORIZONTAL,
    LOCKED_ODDS_DISPLAY_TYPES,
    ODDS_BUTTON_LOCKED,
  } from '@/globals/enums/Odds.enums';

  const ODDS_ANIMATION_TIME = 6500;

  const formatOddsValueCache = {};
  const cachedFormatOddsValue = (value) => {
    if (formatOddsValueCache[value]) {
      return formatOddsValueCache[value];
    }

    const formattedValue = formatOddsValue(value);

    formatOddsValueCache[value] = formattedValue;
    return formattedValue;
  };

  export default {
    name: `OddsButton`,
    components: {
      TooltipWrapper,
      Icon,
    },
    props: {
      id: {
        type: String,
        required: true,
      },
      marketId: {
        type: String,
        required: true,
      },
      mode: {
        type: String,
        required: false,
        default: ODDS_BUTTON_STYLE_TYPE_HORIZONTAL,
      },
      nameType: {
        type: String,
        required: false,
        default: ODDS_BUTTON_NAME_TYPE_SHORT,
      },
      isSupportiveToSelected: {
        type: Boolean,
        required: false,
        default: false,
      },
      isSubmissionInProgress: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        changeState: `idle`,
      };
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getOdd: liveOddsGetters.GET_ODD,
        selectedOdds: liveOddsGetters.SELECTED_ODDS,
        getAllTicketOdds: liveOddsGetters.GET_ALL_TICKET_ODDS,
      }),
      component() {
        return this.tooltip ? `TooltipWrapper` : `div`;
      },
      odd() {
        return this.getOdd(this.id, this.marketId);
      },
      oddName() {
        return (this.isReadOnly || this.nameType === ODDS_BUTTON_NAME_TYPE_SHORT)
          ? this.odd.names : (this.odd.longNames || this.odd.names);
      },
      teamAssociation() {
        return this.odd?.teamAssociation;
      },
      isSelected() {
        return this.selectedOdds[this.marketId]?.includes(this.id);
      },
      isOnAnotherTicket() {
        return this.getAllTicketOdds?.includes(this.id);
      },
      isReadOnly() {
        return this.odd.readOnly === true;
      },
      isLockedDisplayType() {
        return LOCKED_ODDS_DISPLAY_TYPES[this.odd.displayType];
      },
      isLocked() {
        return !this.isReadOnly && (typeof this.odd.value !== `number` || this.odd.value < constants.MIN_LEGAL_ODDS_VALUE || this.isLockedDisplayType);
      },
      buttonClassNames() {
        const { mode, changeState, isLocked, isReadOnly, isSelected, isSupportiveToSelected, teamAssociation, isOnAnotherTicket } = this;

        return {
          [`odds_button--${mode}`]: true,
          [`odds_button--${changeState}`]: changeState,
          [`odds_button--${teamAssociation}`]: !!teamAssociation,
          'odds_button--locked': isLocked,
          'odds_button--readonly': isReadOnly,
          'odds_button--selected': isSelected,
          'odds_button--supportive': isSupportiveToSelected,
          'odds_button--active': isOnAnotherTicket,
        };
      },
      formattedValue() {
        return this.odd.value ? cachedFormatOddsValue(this.odd.value) : ``;
      },
      tooltip() {
        let tooltip;

        if (this.isReadOnly) {
          tooltip = this.oddName;
        } else if (this.isLocked && this.isLockedDisplayType) {
          tooltip = this.$t(LOCKED_ODDS_DISPLAY_TYPES[this.odd.displayType]);
        } else if (this.isLocked && !this.isLockedDisplayType) {
          tooltip = this.$t(LOCKED_ODDS_DISPLAY_TYPES[ODDS_BUTTON_LOCKED]);
        } else {
          tooltip = `${this.oddName} - ${this.formattedValue}`;
        }

        if (this.formattedValue.length <= 4) {
          tooltip = null;
        }

        return tooltip;
      },
    },
    watch: {
      odd(curr, prev) {
        if (!curr || !prev) {
          return;
        }
        if (curr.displayType !== prev.displayType && LOCKED_ODDS_DISPLAY_TYPES[curr.displayType]) {
          this.changeState = `idle`;
        } else if (curr.value !== prev.value) {
          this.changeState = curr.value > prev.value ? `positive` : `negative`;

          this.oddsChangeTimeout = setTimeout(() => {
            this.changeState = `idle`;
          }, ODDS_ANIMATION_TIME);
        }
      },
    },
    methods: {
      toggle() {
        if (this.isLocked || this.isReadOnly || this.isSubmissionInProgress || this.isSupportiveToSelected) {
          return;
        }

        if (!this.odd) {
          // eslint-disable-next-line no-undef
          if (NODE_ENV !== `production`) {
            // eslint-disable-next-line no-console
            console.warn(`OddsButton has no Odd model`);
          }
          return;
        }

        if (this.isSelected) removeBetslipOdd(this.odd);
        else {
          addBet(this.odd);
        }
      },
    },
    onDestroy() {
      if (this.oddsChangeTimeout) {
        clearTimeout(this.oddsChangeTimeout);
      }
    },
  };
</script>

<style lang="scss">
  $base_class: '.odds_button';
  $odds_indicator_class: '.odds_change_indicator';

  #{$base_class} {
    display: flex;
    font-size: 12px;
    line-height: 14px;
    position: relative;
    user-select: none;
    color: var(--color-primary);
    transition: box-shadow .2s;

    @if ($theme == 'light') {
      background: var(--color-white);
    } @else {
      background: var(--color-background--darkest);
    }

    &__value {
      display: block;
      font-size: 13px;
      font-weight: 500;
      color: var(--color-primary);
      flex: 0 0 auto;
    }

    &__name {
      display: block;
      padding: 0 3px 0;
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 1 auto;

      @if ($theme == 'light') {
        color: var(--color-secondary-text--darker);
      } @else {
        color: var(--color-primary-text);
      }
    }

    &--horizontal #{$base_class}__value:not(.icon)::before,
    &--vertical::before {
      display: flex;
      align-items: center;
      content: "▲";
      width: 0;
      height: 9px;
      opacity: 0;
      font-size: 9px;
      color: var(--color-positive);
      z-index: 20;
    }

    &--horizontal {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 8px 5px;

      #{$base_class}__name {
        text-align: left;
        flex: 1 1 auto;
        overflow: hidden;
      }

      #{$base_class}__value {
        text-align: right;
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        &::before {
          margin-right: 2px;
        }
      }
    }

    &--vertical {
      &::before {
        position: absolute;
        transform: translateY(-50%);

        @media(max-width: 1499px) {
          top: 14px;
          left: 2px;
        }

        @media(min-width: 1500px) {
          bottom: 5px;
          left: 8px;
        }
      }

      padding: 8px 8px 6px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:not(#{$base_class}--locked) {
      &#{$base_class} {
        &--negative,
        &--positive {
          &::before,
          &#{$base_class}__value::before,
          &#{$base_class}--horizontal #{$base_class}__value:not(.icon)::before {
            opacity: 1;
            width: 9px;
            /* animation: blink 6.5s linear 1 normal forwards; */
          }
        }

        &--negative {
          @include blinkBoxShadowStyle(var(--color-negative));
          color: var(--color-negative);
          /* animation: blink-box-shadow-red 6.5s linear 1 normal forwards; */

          &::before,
          #{$base_class}__value:not(.icon)::before {
            color: var(--color-negative);
          }

          &::before {
            transform: rotate(180deg) translateY(4px);
          }

          #{$base_class}__value:not(.icon)::before {
            transform: rotate(180deg);
          }

          & ~ #{$base_class}__name {
            @if ($theme == 'light') {
              color: var(--color-black);
            }
          }
        }

        &--positive {
          color: var(--color-positive);
          @include blinkBoxShadowStyle(var(--color-positive));
          /* animation: blink-box-shadow-green 6.5s linear 1 normal forwards; */

          & ~ #{$base_class}__name {
            @if ($theme == 'light') {
              color: var(--color-black);
            }
          }
        }
      }

      &:not(#{$base_class}--readonly):not(#{$base_class}--supportive) {
        cursor: pointer;
      }

      &:hover {
        @if ($theme == 'light') {
          background: var(--color-background--darkest);
        } @else {
          background: var(--color-background--darker);
        }
      }
    }

    &--active {
      background-color: var(--color-primary--darker);
      font-weight: 600;

      #{$base_class}__name,
      #{$base_class}__value {
        color: var(--color-background--darkest);
      }

      &:not(#{$base_class}--locked):hover {
        @if ($skin == 'psk') {
          background: var(--color-primary--dark);
        } @else {
          background: var(--color-primary);
        }
      }
    }

    /* SELECTED */
    &--selected {
      color: var(--color-black);
      font-weight: 600;
      background: var(--color-primary);

      &:not(#{$base_class}--locked):hover {
        @if ($skin == 'psk') {
          background: var(--color-secondary);
        } @else {
          background: var(--color-primary--darker);
        }

      }

      &,
      &#{$base_class}--locked,
      &--supportive {
        color: var(--color-background--darkest);
        #{$base_class}__name,
        #{$base_class}__value {
          color: var(--color-background--darkest);
        }
      }

      #{$odds_indicator_class} {
        color: var(--color-negative--darker);
        &--negative {
          @if ($theme == 'light') {
            color: var(--color-white);
            @include blinkBoxShadowStyle(var(--color-white));
          } @else {
            color: var(--color-negative--darker);
          }

          & ~ #{$base_class}__name {
            @if ($theme == 'light') {
              color: var(--color-white);
            }
          }
        }

        &--positive {
          color: var(--color-positive);
          @include blinkBoxShadowStyle(var(--color-positive));

          & ~ #{$base_class}__name {
            @if ($theme == 'light') {
              color: var(--color-positive--lighter);
            }
          }
        }
      }
    }

    &--locked,
    &--supportive {
      cursor: default;

      @if ($theme == 'light') {
        background: var(--color-background--darkest);
        color: var(--color-background--lighter);

        #{$base_class}__name,
        #{$base_class}__value {
          color: var(--color-background--lighter);
        }
      } @else {
        color: var(--color-secondary-text--darker);

        #{$base_class}__name,
        #{$base_class}__value {
          color: var(--color-secondary-text--darker);
        }
      }
    }

    &--readonly {
      color: transparent;

      &, &:hover {
        background: var(--color-background--darker);
      }
    }
  }
  .betting-disabled #{$base_class} {
    pointer-events: none;
  }
</style>
