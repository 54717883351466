import { normalize } from 'normalizr';

import store from '@/config/store';
import { LiveMarketSchema } from '@/globals/schemas/LiveSchema';
import { OBJECT_TYPE_MARKET } from '@/globals/websocket/ObjectType.enums';
import {
  OPERATION_DELETE,
  OPERATION_INSERT,
  OPERATION_UPDATE,
} from '@/globals/websocket/Operation.enums';
import {
  actions as marketStoreActions,
} from '@/stores/LiveMarketStore';
import { actions as liveOddsActions } from '@/stores/LiveOddsStore';
import { deleteMarketRowById } from '../bettingoffer/MarketService';

const marketHandler = {
  [OPERATION_DELETE]({ id: marketRowId } /* , { marketId } */) {
    deleteMarketRowById(marketRowId);
  },
  [OPERATION_INSERT]({ data } /* , { marketId } */) {
    const { markets, odds } = normalize(data, LiveMarketSchema).entities;
    const market = markets[data.id];

    store.dispatch(`LiveStore/${liveOddsActions.ODDS_ADD_LIST}`, odds);
    store.dispatch(`LiveStore/${marketStoreActions.CREATE_MARKET}`, market);
  },
  [OPERATION_UPDATE]({ data } /* , { marketId } */) {
    const { markets, odds } = normalize(data, LiveMarketSchema).entities;
    const market = markets[data.id];

    store.dispatch(`LiveStore/${liveOddsActions.ODDS_ADD_LIST}`, odds);
    store.dispatch(`LiveStore/${marketStoreActions.UPDATE_MARKET}`, market);
  },
};

const handlersByMessageType = {
  [OBJECT_TYPE_MARKET]: marketHandler,
};

export default handlersByMessageType;
