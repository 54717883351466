<template>
  <MatchStreamButton
    v-if="hasStreamIconVisible"
    :streamNotInTracker="streamNotInTracker"
    class="info-match-stream-button"
    :class="{ 'switch-grid': !showGameTime }"
    :matchId="matchId"
    :isAudio="isAudio"
  />
</template>

<script>
  import MatchStreamButton from '@/globals/components/MatchStreamButton/MatchStreamButton';
  import { StreamType } from '@/globals/enums/enums';
  import { MEDIA_TYPE_AUDIO } from '@/globals/enums/Stream.enums';

  export default {
    name: `InfoMatchStreamButton`,
    components: {
      MatchStreamButton,
    },
    props: {
      matchId: {
        type: String,
        required: true,
      },
      matchTrackerType: {
        type: String,
        required: false,
        default: null,
      },
      showGameTime: {
        type: Boolean,
        required: false,
        default: false,
      },
      stream: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    computed: {
      hasStream() {
        return !!this.stream?.provider;
      },
      isAudio() {
        return this.hasStream && this.stream.mediaType === MEDIA_TYPE_AUDIO;
      },
      hasStreamIconVisible() {
        return this.hasStream || this.matchTrackerType === StreamType.IMG;
      },
      streamNotInTracker() {
        return this.hasStream && this.matchTrackerType !== StreamType.IMG;
      },
    },
  };

</script>

<style lang="scss">
  .info-match-stream-button {
    justify-self: center;
    grid-area: stream-tracker;
    margin: 0 4px;
    color: var(--color-secondary-text--darker);

    &.switch-grid {
      grid-area: game-info;
    }

    &:hover {
      color: var(--color-primary-text);
    }
  }
</style>
