<template>
  <div
    :class="miniScoreboardModeClass"
    class="mini_scoreboard"
  >
    <template v-if="isOneLineMode">
      <template v-for="(column, index) in miniScoreboard.columns">
        <div
          :key="index"
          class="mini_scoreboard__teams"
        >
          <div>
            {{ column.team1 }} : {{ column.team2 }}
          </div>
          <div>
            <TooltipWrapper
              v-if="gameTime"
              :text="gameTimeLong"
              class="mini_scoreboard__game_time"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="gameTime.short" />
              <!-- eslint-enable -->
            </TooltipWrapper>
          </div>
        </div>
        <template v-if="showServer">
          <SportIcon
            v-if="miniScoreboard.server === 1"
            :key="`${index}-${1}`"
            class="mini_scoreboard__server_icon server_icon__left"
            :sport="serverIconSport"
            :size="serverIconSize"
          />
          <SportIcon
            v-if="miniScoreboard.server === 2"
            :key="`${index}-${2}`"
            class="mini_scoreboard__server_icon server_icon__left"
            :sport="serverIconSport"
            :size="serverIconSize"
          />
        </template>
      </template>
    </template>

    <template v-if="miniScoreboard.mode === 'COLUMN'">
      <div
        class="mini_scoreboard__columns"
        :class="{ 'mini_scoreboard__columns--three': miniScoreboard.columns.length === 3 }"
      >
        <SportIcon
          v-if="showServer && miniScoreboard.server === 1"
          :sport="serverIconSport"
          :size="serverIconSize"
        />
        <SportIcon
          v-if="showServer && miniScoreboard.server === 2"
          :sport="serverIconSport"
          :size="serverIconSize"
          class="sport-icon--bottom"
        />
        <MiniScoreboardColumn
          v-for="(column, key, index) in miniScoreboard.columns"
          :key="key"
          :column="column"
          :index="index"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import TooltipWrapper from "@fortunaweb-fe/mooncake/dist/TooltipWrapper";
  import { SCOREBOARD_MODE_ONE_LINE } from '@/globals/enums/ScoreboardMode.enums';
  import { stripTags } from '@/globals/utils/string';
  import MiniScoreboardColumn from './MiniScoreboardColumn';
  import SportIcon from '../SportIcon/SportIcon';

  export default {
    name: `MiniScoreboard`,
    components: {
      TooltipWrapper,
      MiniScoreboardColumn,
      SportIcon,
    },
    props: {
      miniScoreboard: {
        type: Object,
        required: true,
      },
      showServer: {
        type: Boolean,
        required: false,
        default: true,
      },
      sportType: {
        type: String,
        required: true,
      },
      gameTime: {
        type: Object,
        required: false,
        default: null,
      },
    },
    computed: {
      isOneLineMode() {
        return this.miniScoreboard.mode === SCOREBOARD_MODE_ONE_LINE;
      },
      serverIconSport() {
        return this.sportType || ``;
      },
      serverIconSize() {
        return `xs`;
      },
      miniScoreboardModeClass() {
        return {
          [`mini_scoreboard--mode-${this.miniScoreboard.mode}`]: true,
          'mini_scoreboard--no_server': !this.showServer,
        };
      },
      gameTimeLong() {
        return stripTags(this.gameTime.long);
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.mini_scoreboard';

  #{$base_class} {
    @include clearfix();
    height: 100%;

    position: relative;

    &--mode-EMPTY::after {
      content: '-';
      margin-top: calc($match_row_height / 2);
      margin-left: calc(calc($match_mini_scoreboard_width - 1px ) / 2); // -1px is for the border of parent
      transform: translate(-50%, -50%);
    }

    &__blank {
      height: 21px;
    }

    &--mode-ONE_LINE {
      overflow: hidden;
      position: relative;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      #{$base_class}__teams {
        @if ($theme == 'light') {
          color: var(--color-black);
        }
        & > div {
          flex: 1;
          min-height: 16px;
        }
      }

      #{$base_class}__server_icon {
        position: absolute;
        top: 25%;
        transform: translateY(-25%);
      }

      .server_icon__left {
        left: 5px;
      }

      .server_icon__right {
        right: 5px;
      }

      #{$base_class}__game_time {
        @if ($theme == 'light') {
          color: var(--color-secondary-text);
        } @else {
          color: var(--color-secondary-text--darker);
        }
        padding: 1px 5px 0 5px;
        font-size: 12px;
        width: $match_mini_scoreboard_width - 10px;
        position: fixed;
        height: 14px;
        text-align: center;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }

    }

    &__server {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      font-size: 12px;
      padding: 3px;
      overflow: hidden;
      border-right: none;

      &_icon {
        line-height: 8px;
        vertical-align: middle;
      }
    }

    &__columns {
      display: grid;
      grid-template-areas: 'column1 column2 column3';
      grid-template-columns: 0.7fr 1fr 1fr;
      width: 100%;
      height: 100%;

      &--three {
        grid-template-areas: 'column1 column2 column3 column4';
        grid-template-columns: 0.7fr 1fr 1fr 1.2fr;
      }
    }

    &--no_server {
      #{$base_class}__columns {
        grid-template-areas: 'column1 column2';
        grid-template-columns: 1fr 1fr;

        &--three {
          grid-template-areas: 'column1 column2 column3';
          grid-template-columns: 1fr 1fr 1.2fr;
        }
      }
    }

    .sport-icon {
      grid-area: column1;
      margin-left: 3px;
      margin-top: 6px;

      &--bottom {
        margin-top: 26px;
      }
    }
  }
</style>
