<template>
  <span
    :style="style"
    :class="{ error: !isImageLoaded }"
  />
</template>

<script>
  import axios from 'axios';
  import FortunaLogger from '@/services/Logger/Logger';
  import { loggerSeverity } from '@/globals/enums/Logger.enums';
  import { IMAGE_NOT_FOUND } from '../../enums/DebugEvent';

  /**
   * Image component, setting image as background of <span> element
   * In case of 404 image URL, error is logged via logger.js service
   */
  export default {
    name: `ImageLoggingError`,
    props: {
      src: {
        type: String,
        required: true,
      },
      className: {
        type: String,
        required: false,
        default: ``,
      },
    },
    data() {
      return {
        style: {},
        isImageLoaded: false,
      };
    },
    mounted() {
      this.setImageBackground(this.src);
    },
    methods: {
      setImageBackground(imageUrl) {
        if (!imageUrl) {
          this.isImageLoaded = false;
          return;
        }
        axios.get(this.src).then(() => {
          this.style = { backgroundImage: `url(${imageUrl}` };
          this.isImageLoaded = true;
        }).catch(() => FortunaLogger
          .logMessage(
            loggerSeverity.WARNING,
            {
              event: IMAGE_NOT_FOUND,
              data: {
                imageUrl: this.src,
              },
            },
          ),
        );
      },
    },
  };

</script>

<style lang="scss">

</style>
