import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
import {
  BROWSERS,
  STREAM_CONTENT_TYPE_DASH,
  STREAM_CONTENT_TYPE_HLS,
  STREAM_TYPE_DASH,
  STREAM_TYPE_HLS,
  StreamType,
} from '@/globals/enums/enums';
import { domFromXml } from '@/globals/utils/dom_utils';
import { polyfillFormData } from '@/globals/utils/form';
import {
  PERFORM_STREAM_MEDIUM_QUALITY_ID,
  PERFORM_STREAM_LOW_QUALITY_ID,
  PERFORM_STREAM_SECURED_TYPE_ID,
  PERFORM_STREAM_TYPE_ID,
  MEDIA_TYPE_AUDIO,
} from '@/globals/enums/Stream.enums';
import { IS_USER_LOGGED_IN } from '@/stores/UserStore/getters';
import translation from '@/plugins/translation';
import { getClientBrowser } from "@/globals/utils/browsers";
import axios from 'axios';
import safeGet from 'lodash/get';
import api from '../../config/api/live';
import imgApi from '../../config/api/img';
import {
  actions as streamStoreActions,
  getters as streamStoreGetters,
} from '../../stores/StreamStore';
import constants, { LOCATION_PROTOCOL_SECURED, resolveEndpoint } from '../../config/constants';
import store from '../../config/store';

const getConfigurationValue = (confValue, defaultValue = null) => store
  .getters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](confValue, defaultValue);

const getMediumQStreamElement = (xmlResponse) => {
  const id = getConfigurationValue(`performStreamMediumQualityId`, PERFORM_STREAM_MEDIUM_QUALITY_ID);

  return xmlResponse.getElementById(id);
};

const getLowQStreamElement = (xmlResponse) => {
  const id = getConfigurationValue(`performStreamLowQualityId`, PERFORM_STREAM_LOW_QUALITY_ID);

  return xmlResponse.getElementById(id);
};

const getDefaultStreamElement = (xmlResponse) => {
  const id = LOCATION_PROTOCOL_SECURED
    ? getConfigurationValue(`performStreamSecuredId`, PERFORM_STREAM_SECURED_TYPE_ID)
    : getConfigurationValue(`performStreamUnsecuredId`, PERFORM_STREAM_TYPE_ID);

  return xmlResponse.getElementById(id);
};

const createLocalizedError = (key) => Promise.reject(
  new Error(translation.getTranslatedMessage(key)),
);

export const streamProviders = {
  [StreamType.IMG](data) {
    const {
      operatorId,
      auth,
      timestamp,
      eventId,
      isAutoplay,
    } = data;

    return imgApi.get(resolveEndpoint(`IMG_STREAM`, { eventId }), { params: { operatorId, auth, timestamp } })
      .then((response) => (safeGet(response, `data.hlsUrl`) ? {
        url: response.data.hlsUrl,
        type: STREAM_CONTENT_TYPE_HLS,
        provider: StreamType.IMG,
        isAutoplay,
      } : createLocalizedError(`live3.stream.error.no_video_url`)));
  },
  [StreamType.LIVEBOX]({ version, apiKey, eventId, userId, clientIp, tts, timestamp, auth, isAutoplay }) {
    const postData = polyfillFormData();

    postData.set(`ver`, version);
    postData.set(`key`, apiKey);
    postData.set(`video_id`, eventId);
    postData.set(`user_id`, userId);
    postData.set(`ip`, clientIp);
    postData.set(`tts`, tts);
    postData.set(`nonce`, timestamp);
    postData.set(`sig`, auth);
    postData.set(`format`, `json`);

    const postSettings = { headers: { 'Content-Type': `application/x-www-form-urlencoded` } };

    return axios.post(resolveEndpoint(`LIVEBOX_STREAM`, {}), postData, postSettings).then((response) => (safeGet(response, `data.hls`) ? {
      // LIVETHREE-1338: force https
      url: response.data.hls.replace(/^http:/, `https:`),
      type: STREAM_CONTENT_TYPE_HLS,
      provider: StreamType.LIVEBOX,
      isAutoplay,
    } : createLocalizedError(`live3.stream.error.no_video_url`)));
  },
  [StreamType.PERFORM](data) {
    const performStreamUrl = getConfigurationValue(`performStreamUrl`);

    return axios.get(performStreamUrl, { params: { ...data } })
      .then((response) => {
        const xmlResponse = domFromXml(response.data);

        if (!xmlResponse) return createLocalizedError(`live3.stream.error.no_video_url`);

        const streamIdElement = getMediumQStreamElement(xmlResponse)
          || getLowQStreamElement(xmlResponse)
          || getDefaultStreamElement(xmlResponse);

        if (!streamIdElement) return createLocalizedError(`live3.stream.error.no_video_url`);

        const videoUrl = streamIdElement.getElementsByTagName(`streamLaunchCode`)[0].textContent;

        return videoUrl ? {
          url: videoUrl,
          type: STREAM_CONTENT_TYPE_HLS,
          provider: StreamType.PERFORM,
          isAutoplay: data.isAutoplay,
        } : createLocalizedError(`live3.stream.error.no_video_url`);
      });
  },
  [StreamType.SPORTSMAN](data) {
    const { partnerId, streamId, ...restParams } = data;
    const params = {
      ...restParams,
      // lowercase name is required by sportradar provider
      streamid: streamId,
      // lowercase name is required by sportradar provider
      partnerid: partnerId,
      protocol: `hls`,
    };

    return axios.get(resolveEndpoint(`SPORTSMAN_STREAM`, { partnerId, streamId }), { params })
      .then((response) => {
        const xmlResponse = domFromXml(response.data);
        const tokenElement = xmlResponse.getElementsByTagName(`token`)[0];
        const tokenStatusText = tokenElement.getAttribute(`statusText`);
        const tokenUrl = tokenElement.getAttribute(`url`);

        if (tokenStatusText !== `success`) {
          return createLocalizedError(`live3.stream.error.unknown_error`);
        }
        if (!(xmlResponse && tokenUrl)) {
          return createLocalizedError(`live3.stream.error.no_video_url`);
        }

        return {
          url: tokenUrl,
          type: STREAM_CONTENT_TYPE_HLS,
          provider: StreamType.SPORTSMAN,
          isAutoplay: data.isAutoplay,
        };
      });
  },
  [StreamType.TWITCH]({ eventId, isAutoplay }) {
    return eventId ? {
      url: `${constants.TWITCH_API_URL}?channel=${eventId}&autoplay=${isAutoplay}&parent=${constants.PARENT_URL}`,
      provider: StreamType.TWITCH,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.YOUTUBE]({ eventId }) {
    return eventId ? {
      url: `${constants.YOUTUBE_API_URL}embed/${eventId}?autoplay=1`,
      provider: StreamType.YOUTUBE,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.MIXER]({ eventId }) {
    return eventId ? {
      url: `${constants.MIXER_API_URL}embed/player/${eventId}?disableLowLatency=1`,
      provider: StreamType.MIXER,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.BETBAZAR]({ eventId }) {
    return eventId ? {
      url: `${constants.BETBAZAR_API_URL}embed.php/?match_id=${eventId}`,
      provider: StreamType.BETBAZAR,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.BETRADAR]({ streamUri, isAutoplay }) {
    return streamUri ? {
      url: streamUri,
      type: STREAM_CONTENT_TYPE_HLS,
      provider: StreamType.BETRADAR,
      isAutoplay,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.BETRADAR_AV]({ streamUri, isAutoplay }) {
    return streamUri ? {
      url: streamUri,
      type: STREAM_CONTENT_TYPE_HLS,
      provider: StreamType.BETRADAR_AV,
      isAutoplay,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.BETGENIUS]({ streamUrls, permissions, isAutoplay }) {
    const browser = getClientBrowser();
    const isDrmLicenced = !!streamUrls[0].drm;
    const streamTypeByClientBrowser = browser === BROWSERS.SAFARI || !isDrmLicenced ? STREAM_TYPE_HLS : STREAM_TYPE_DASH;

    const streamContentType = {
      [STREAM_TYPE_HLS]: STREAM_CONTENT_TYPE_HLS,
      [STREAM_TYPE_DASH]: STREAM_CONTENT_TYPE_DASH,
    }[streamTypeByClientBrowser];

    const clientStreamDelivery = streamUrls
      .find((streamDelivery) => streamDelivery.streamType === streamTypeByClientBrowser);

    return clientStreamDelivery ? {
      url: clientStreamDelivery.streamUrl,
      type: streamContentType,
      provider: StreamType.BETGENIUS,
      drm: clientStreamDelivery.drm,
      permissions,
      isAutoplay,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.CT_SPORT]({ streamUri, isAutoplay }) {
    return streamUri ? {
      url: streamUri,
      type: STREAM_CONTENT_TYPE_HLS,
      provider: StreamType.CT_SPORT,
      isAutoplay,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.SPORT_RADIO]({ streamId, isAutoplay }) {
    const streamUrl = getConfigurationValue(`gbvisionUrl`);

    return streamId ? {
      url: `${streamUrl}${streamId}/playlist.m3u8`,
      provider: StreamType.SPORT_RADIO,
      isAutoplay,
      mediaType: MEDIA_TYPE_AUDIO,
    } : createLocalizedError(`live3.stream.error.no_video_url`);
  },
  [StreamType.PROMO]() {
    return {
      type: constants.MP4_STREAM_TYPE,
      url: getConfigurationValue(`defaultVideoUrl`),
      provider: StreamType.PROMO,
      coverImage: getConfigurationValue(`playerCoverImageUrl`),
      isAutoplay: false,
      globalOptions: {
        // autoplay: false,
        controls: true,
        // preload: 'auto',
        // fluid: false,
        // muted: false,
        controlBar: {
          remainingTimeDisplay: false,
          playToggle: {},
          progressControl: {
            seekBar: NODE_ENV === `development`,
          },
          fullscreenToggle: {},
          volumeMenuButton: {
            inline: false,
            vertical: true,
          },
        },
        techOrder: [`html5`],
        plugins: {},
      },
    };
  },
  [StreamType.FALLBACK]() {
    return {
      provider: StreamType.FALLBACK,
      coverImage: getConfigurationValue(`playerCoverImageUrl`),
    };
  },
};

export async function fetchStream(streamData, force = false) {
  const { matchId, isAutoplay = true } = streamData || {};

  store.dispatch(`StreamStore/${streamStoreActions.FETCH_STREAM_PENDING}`);

  return new Promise((resolve, reject) => {
    if (!store.getters[`UserStore/${IS_USER_LOGGED_IN}`] && matchId) {
      return reject(new Error(translation.getTranslatedMessage(`live3.stream.error.not_authorized`)));
    }

    if (!matchId) return resolve({ data: { provider: StreamType.PROMO } });

    return api.get(resolveEndpoint(`LIVE_STREAM`, { matchId }))
      .then(resolve)
      .catch(reject);
  })
    .then((response) => {
      const streamHandlerExists = streamProviders[safeGet(response, `data.provider`)];

      if (!streamHandlerExists) return createLocalizedError(`live3.stream.error.unsupported_provider`);

      return streamProviders[response.data.provider]({
        ...response.data.data,
        isAutoplay,
      });
    })
    .then((stream) => store.dispatch(`StreamStore/${streamStoreActions.FETCH_STREAM_SUCCESS}`,
      { ...stream, matchId, force }))
    .catch((err) => {
      store.dispatch(`StreamStore/${streamStoreActions.FETCH_STREAM_ERROR}`, { err, ...streamProviders[StreamType.FALLBACK](), matchId });
    });
}

export function refetchStream() {
  const stream = store.getters[`StreamStore/${streamStoreGetters.GET_STREAM}`];

  if (stream && stream.matchId) {
    fetchStream({ matchId: stream.matchId });
  } else if (stream && !stream.matchId) {
    fetchStream();
  }
}
