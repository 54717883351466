export function polyfillFormData() {
  if (window.FormData === undefined) {
    this.processData = true;
    this.contentType = `application/x-www-form-urlencoded`;
    this.set = (name, value) => {
      this[name] = value === undefined ? `` : value;
      return true;
    };
  } else {
    const formData = new FormData();

    formData.processData = false;
    formData.contentType = false;
    return formData;
  }
  return this;
}
