import translation from '@/plugins/translation';
import remove from 'lodash/remove';
import isObjectLike from 'lodash/isObjectLike';
import has from 'lodash/has';
import get from 'lodash/get';
import { getLocalized } from './localizations';

/**
 * ucs-2 string to base64 encoded ascii
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa
 *
 * @param str
 * @returns {string}
 */
export function utoa(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export const snakeCaseToCamelCase = (string) => string
  .toLowerCase()
  .split(`_`)
  .map((word, index) => index === 0 // eslint-disable-line no-confusing-arrow
    ? word
    : (word.substring(0, 1).toUpperCase() + word.substring(1)))
  .join(``);

/**
 * Safely unescapes string containig escaped characters like &lt; etc..
 * @param string String to escape
 * @returns {string} Unescaped string
 */
export const unescapeHTMLString = (string) => {
  const createdDoc = new DOMParser().parseFromString(string, `text/html`);

  return createdDoc.documentElement.innerText;
};

export const stripTags = (value) => {
  const regex = /(<([^>]+)>)/ig;

  return value.replace(regex, ``);
};

/**
 * Replaces an interpolation value inside string with a value from an object
 * @param string {string} String to process
 * @param valueMap {object} Key:Value map of replacements
 * @param interpolationCharacters {array} Array of interpolation characters, which indicates template
 * @returns {string}
 */
export const replaceInStringTemplate = (string, valueMap, interpolationCharacters = [`{{`, `}}`]) => string.replace(
  new RegExp(`(${interpolationCharacters.join(`\\w+`)})+?`, `gi`),
  (template) => valueMap[template.replace(new RegExp(interpolationCharacters.join(`|`), `ig`), ``)],
);

export const returnStringByLocalization = (localizedObject) => (typeof localizedObject === `object` ? getLocalized(localizedObject, ``) : localizedObject);

/**
 * Safely replace string contains line breaks with HTML line break <br />
 * @param string String to replace
 * @returns {string} Replaced string
 */
export const replaceLineBreaks = (string) => ((string) ? string.replace(/(?:\\r\\n|\\r|\\n)/g, `<br />`) : ``);

const hasObjectProp = (prop) => (data) => isObjectLike(data) && has(data, prop);
const translateProp = (prop) => (data) => translation.getTranslatedMessage(get(data, prop));

/**
 * Translate keys from array and put HTML line break <br /> between
 * @param messages Array to translate
 * @returns {string} Translated result
 */
export const joinTranslatedMessages = (messages) => (messages ? remove(messages, hasObjectProp(`text`))
  .map(translateProp(`text`))
  .join(`<br />`) : ``);
