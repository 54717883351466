import { getStreamErrorMessage } from '@/services/StreamService';

export const types = {
  FETCH_STREAM_PENDING: `FETCH_STREAM_PENDING`,
  FETCH_STREAM_ERROR: `FETCH_STREAM_ERROR`,
  FETCH_STREAM_SUCCESS: `FETCH_STREAM_SUCCESS`,
  SET_STREAM_PROMO: `SET_STREAM_PROMO`,
  LOCK_STREAM: `LOCK_STREAM`,
  UNLOCK_STREAM: `UNLOCK_STREAM`,
};

export const actions = {
  FETCH_STREAM_PENDING: `FETCH_STREAM_PENDING`,
  FETCH_STREAM_ERROR: `FETCH_STREAM_ERROR`,
  FETCH_STREAM_SUCCESS: `FETCH_STREAM_SUCCESS`,
  SET_STREAM_PROMO: `SET_STREAM_PROMO`,
  LOCK_STREAM: `LOCK_STREAM`,
  UNLOCK_STREAM: `UNLOCK_STREAM`,
};

export const getters = {
  GET_STREAM: `getStream`,
};

const streamStore = {
  namespaced: true,
  state: {
    stream: {},
    isLoading: false,
    isError: false,
    errorMessage: null,
    locked: {},
  },
  mutations: {
    [types.FETCH_STREAM_PENDING](state) {
      if (!state.locked.url) {
        state.errorMessage = null;
        state.isLoading = true;
        state.isError = false;
      }
    },
    [types.FETCH_STREAM_ERROR](state, { err, ...stream }) {
      state.stream = stream;
      if (!state.locked.url) state.errorMessage = getStreamErrorMessage(err);
      if (!state.locked.url) state.isLoading = false;
      if (!state.locked.url) state.isError = true;
    },
    [types.FETCH_STREAM_SUCCESS](state, { force, ...stream }) {
      state.stream = stream;
      if (state.locked.url && force) state.locked = stream;
      if (!state.locked.url) state.isLoading = false;
      if (!state.locked.url) state.isError = false;
    },
    //
    [types.LOCK_STREAM](state, id) {
      state.locked = id;
    },
    [types.UNLOCK_STREAM](state) {
      state.stream = state.locked;
      state.locked = {};
    },
  },
  actions: {
    [actions.FETCH_STREAM_PENDING]({ commit }) {
      commit(types.FETCH_STREAM_PENDING);
    },
    [actions.FETCH_STREAM_ERROR]({ commit }, data) {
      commit(types.FETCH_STREAM_ERROR, data);
    },
    [actions.FETCH_STREAM_SUCCESS]({ commit }, data) {
      commit(types.FETCH_STREAM_SUCCESS, data);
    },
    [actions.LOCK_STREAM]({ commit }, data) {
      commit(types.LOCK_STREAM, data);
    },
    [actions.UNLOCK_STREAM]({ commit }) {
      commit(types.UNLOCK_STREAM);
    },
  },
  getters: {
    [getters.GET_STREAM](state) {
      return state.stream;
    },
  },
};

export default streamStore;
