/**
 * Bounds value of {@link index} to be within range <0, arr.length> (both inclusive).
 *
 * @param {Array} arr
 * @param {Number} index
 * @return {Number}
 */
export function sanitizeIndex(arr, index) {
  if (index < 0) {
    index = 0;
  }
  if (index > arr.length) {
    index = arr.length;
  }
  return index;
}

/**
 * Returns index of an item from `array` which has the lowest distance
 * from `value` according to classifier function `distanceClassifier`.
 *
 * Returned value is within range <0, array.length - 1> (both inclusive)
 * or `null` if `array` is empty.
 *
 * @param {Array<T>} array
 * @param {T} value
 * @param {Function} distanceClassifier classifier function of form `(a:T, b:T): Number`
 *                   which returns distance of 2 values
 * @return {Number|null}
 */
export function findNearestIndex(array, value, distanceClassifier = (a, b) => Math.abs(a - b)) {
  if (!array || !array.length) {
    return null;
  }
  if (array.length === 1) {
    return 0;
  }

  return array
    .map((item, index) => ({
      index,
      distance: distanceClassifier(item, value),
    }))
    .reduce((nearest, item) => ((nearest.index === null || item.distance < nearest.distance)
      ? item
      : nearest), { index: null, distance: null })
    .index;
}

/**
 * Returns an element from `array` which has the lowest distance
 * from `value` according to classifier function `distanceClassifier`.
 * or `null` if `array` is empty.
 *
 * @param {Array<T>} array
 * @param {T} value
 * @param {Function} distanceClassifier classifier function of form `(a:T, b:T): Number`
 *                   which returns distance of 2 values
 * @return {T|null}
 */
export function findNearestElement(array, value, distanceClassifier = (a, b) => Math.abs(a - b)) {
  const index = findNearestIndex(array, value, distanceClassifier);

  return (index !== null) ? array[index] : null;
}

/**
 * Method for merging string, object, and array into one object.
 * Used for merging class objects in vue functional components.
 * AOS means array, object, string.
 *
 * @param {Array, Object, String} aosOne
 * @param {Array, Object, String} aosTwo
 * @return {Object}
 */
export function mergeAOS(aosOne, aosTwo) {
  let finalObject = {};

  if (aosTwo instanceof Object) {
    finalObject = { ...aosOne, ...aosTwo };
  } else if (aosTwo instanceof Array) {
    finalObject = { ...aosOne, [aosTwo.join(` `)]: true };
  } else if (typeof aosTwo === `string`) {
    finalObject = { ...aosOne, [aosTwo]: true };
  } else {
    finalObject = { ...aosOne };
  }
  return finalObject;
}
