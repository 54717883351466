import Vue from 'vue';

export const actionNames = {
  UPDATE_WEB_MESSAGES: `updateWebMessages`,
};

export const getterNames = {
  GET_WEB_MESSAGES: `getWebMessages`,
};

export const mutationNames = {
  UPDATE_WEB_MESSAGE: `updateWebMessageMutation`,
};

const webMessagesStore = {
  namespaced: true,
  state: {
    isLoading: false,
    isError: false,
    webMessages: {},
  },
  mutations: {
    // UPDATE existing message, INSERT new message if one with such ID does not exists yet
    [mutationNames.UPDATE_WEB_MESSAGE](state, webMessage) {
      const { id: webMessageId } = webMessage;

      if (!state.webMessages[webMessageId]) {
        Vue.set(state.webMessages, webMessage.id, webMessage);
      } else {
        Object.entries(webMessage).forEach(([key, value]) => {
          if (state.webMessages[webMessageId][key] !== value) {
            Vue.set(state.webMessages[webMessageId], key, value);
          }
        });
      }
    },
  },
  actions: {
    [actionNames.UPDATE_WEB_MESSAGES]({ commit }, webMessages) {
      Object.values(webMessages).forEach((webMessage) => {
        commit(mutationNames.UPDATE_WEB_MESSAGE, webMessage);
      });
    },
  },
  getters: {
    [getterNames.GET_WEB_MESSAGES](state) {
      return Object.values(state.webMessages);
    },
  },
};

export default webMessagesStore;
