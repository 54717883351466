<template>
  <div
    :class="extraClasses"
    class="sport-header"
    @click.self="attemptToggle"
  >
    <Icon
      v-if="extended"
      ref="arrow"
      size="xxxs"
      :style="initialCollapseState()"
      class="sport-header__state_arrow"
      name="accordion-open"
      @click="attemptToggle"
    />

    <SportIcon
      :sport="sportType"
      size="s"
      @click="attemptToggle"
    />

    <span
      class="sport-header__name"
      @click="attemptToggle"
    >
      {{ name }}
    </span>

    <span
      class="sport-header__count"
      @click="attemptToggle"
    >
      <template v-if="extended && collapseState">
        ({{ matchCount }})
      </template>
    </span>

    <div class="sport-header__actions">
      <template v-if="short">
        <button
          class="sport-header__button"
          @click.prevent="changeTopMarketsVisibility"
        >
          <Icon
            :title="$t(
              `live3.sport_sorts_filters.${marketsVisible ? `hide_sport_markets` : `show_sport_markets`}`,
            )"
            :name="`${marketsVisible ? 'up' : 'down'}-circled`"
            class="sport-header__icon"
          />
        </button>
      </template>

      <button
        v-if="!hideLeagueCollapse"
        class="sport-header__button"
        @click="toggleLeagues"
      >
        <Icon
          :title="$t(
            `live3.sport_sorts_filters.${isAllLeaguesOpen ? `hide_leagues` : `show_leagues`}`,
          )"
          :name="`ic_list-${isAllLeaguesOpen ? 'collapse' : 'expand' }`"
          class="sport-header__icon"
        />
      </button>
    </div>
  </div>
</template>

<script>
  import Icon from '@/globals/components/Icon/Icon';
  import SportIcon from '@/globals/components/SportIcon/SportIcon';

  export default {
    name: `SportHeader`,
    components: {
      SportIcon,
      Icon,
    },
    props: {
      sportId: {
        type: String,
        required: true,
      },
      isAllLeaguesOpen: {
        type: Boolean,
        required: true,
      },
      marketsVisible: {
        type: Boolean,
        required: false,
        default: false,
      },
      sportType: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      matchCount: {
        type: [String, Number],
        required: true,
      },
      collapseState: {
        type: Boolean,
        required: true,
      },
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
      extended: {
        type: Boolean,
        required: false,
        default: true,
      },
      hideLeagueCollapse: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: [
      `toggleLeagues`,
      `toggleSport`,
    ],
    computed: {
      extraClasses() {
        const classes = [];

        classes.push(`sport-header--${this.sportType}`);
        if (this.extended) classes.push(`sport-header--extended`);
        return classes;
      },
    },
    methods: {
      initialCollapseState() {
        return {
          transform: this.collapseState ? `rotateZ(-90deg)` : `rotateZ(0)`,
        };
      },
      changeTopMarketsVisibility() {
        this.$emit(`update:marketsVisible`, !this.marketsVisible);
      },
      attemptToggle() {
        if (!this.extended) {
          return;
        }
        this.$emit(`toggleSport`, this.sportId);
      },
      toggleLeagues() {
        this.$emit(`toggleLeagues`);
      },
    },
  };

</script>

<style lang="scss">
  @import "~@/styles/definitions/sizing";
  @import "~@/styles/definitions/sport-colors";
  @import "~@/styles/mixins/sport-header-color-variant";

  $base_class: '.sport-header';

  #{$base_class} {
    top: 0;
    position: sticky;
    height: var(--sport-header-height);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 8px;
    z-index: 2;
    color: var(--color-white);

    @include sportHeaderColorVariant($sport_color_default);

    &--extended {
      cursor: pointer;
    }

    &__state_arrow {
      flex: 0 0 auto;
      margin-right: 10px;
    }

    .sport-icon {
      margin-right: 10px;
      flex: 0 0 auto;
    }

    &__name {
      flex: 0 1 auto;
      overflow: hidden;
    }

    &__count {
      padding-left: 3px;
      flex: 1 1 auto;
    }

    &__actions {
      flex: 0 0 auto;
      height: 100%;
    }

    &__button {
      outline: none;
      background: none;
      padding: 0 5px;
      margin: auto;
      border: 0;
      cursor: pointer;
      appearance: none;
      height: 100%;

      &:last-child {
        padding-right: 13px;
      }
    }

    &__icon {
      color: var(--color-white);
      flex: 0 0 auto;
      transition: color .3s linear;

      &:hover {
        color: var(--color-white);
      }
    }
  }

  .icon--ic_list-expand, .icon--ic_list-collapse {
    font-size: 20px;
  }
</style>
