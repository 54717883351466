<template>
  <div class="league">
    <LiveLeagueHeader
      :leagueName="league.names"
      :leagueFlag="league.flag"
      :leagueId="leagueId"
      :leagueMatchCount="leagueMatchCount"
      :showLeagueTopMarketNames="containsOnlyStreamMatches && !isRaceSportType"
      :collapsed="isCollapsed"
      :topMarketNames="topMarketNames"
      :isFavoriteLeague="false"
      :short="short"
      :showLiveBadge="showLiveBadge"
      :selectedLeagueId="selectedLeagueId"
      :sportId="sportId"
      :collapsingCategory="collapsingCategory"
    />

    <HeightTransitionUtil>
      <div v-if="!isCollapsed">
        <LiveMatch
          v-for="id in matchesId"
          :key="id"
          :leagueId="leagueId"
          :matchId="id"
          :sportType="sportType"
          :matchDetailStyle="short"
          :marketsVisible="showTopMarket"
        />

        <NoMatchInViewMessage
          v-if="containsOnlyStreamMatches && isStreamFilterActive"
          v-once
          key="no-match-with-filter"
          :sportId="sportId"
          :removeReasonAction="resetSportFilters"
          :streamFilterActive="isStreamFilterActive"
        />

        <EmptyMessage
          v-else-if="!leagueMatchCount"
          :message="$t(`live3.offerFilter.duration.league.empty`)"
        />
      </div>
    </HeightTransitionUtil>
  </div>
</template>

<script>
  import { SportSortsFiltersType as FiltersType } from "@/globals/enums/enums";
  import { mapGetters, mapActions, mapState } from 'vuex';
  import NoMatchInViewMessage from '@/globals/components/NoMatchInViewMessage/NoMatchInViewMessage';
  import {
    actions as liveSportStoreActions,
  } from '@/stores/LiveSportStore';

  import {
    GET_LEAGUE_BY_ID,
    IS_LEAGUE_COLLAPSED,
  } from '@/stores/LiveLeagueStore/constants';
  import LiveMatch from '@/globals/components/LiveMatch/LiveMatch';
  import LiveLeagueHeader from '@/globals/components/LiveLeague/LiveLeagueHeader';
  import HeightTransitionUtil from '@/globals/components/HeightTransitionUtil/HeightTransitionUtil';
  import EmptyMessage from '@/globals/components/EmptyMessage/EmptyMessage';
  import { getters as liveMatchGetters } from '@/stores/LiveMatchStore';

  import { RACE_SPORT_TYPES } from '@/globals/enums/RaceTypeSports.enums';

  import { FEATURE_CB_LIVE } from '@/config/constants/features';

  export default {
    name: `League`,
    components: {
      NoMatchInViewMessage,
      LiveMatch,
      LiveLeagueHeader,
      HeightTransitionUtil,
      EmptyMessage,
    },
    props: {
      flag: {
        type: String,
        required: false,
        default: ``,
      },
      leagueId: {
        type: String,
        required: true,
      },
      sportId: {
        type: String,
        required: true,
      },
      sportType: {
        type: String,
        required: true,
      },
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
      showTopMarket: {
        type: Boolean,
        required: false,
        default: false,
      },
      selectedLeagueId: {
        type: String,
        required: true,
      },
      collapsingCategory: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        info: {
          id: this.leagueId,
          category: this.collapsingCategory,
        },
      };
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getLeagueById: GET_LEAGUE_BY_ID,
        isLeagueCollapsed: IS_LEAGUE_COLLAPSED,
        getFilteredMatchesByLeagueAndSport: liveMatchGetters.GET_FILTERED_MATCHES_BY_LEAGUE,
      }),
      ...mapState(`LiveStore`, {
        isStreamFilterActive: ({ LiveSportStore: { filtersMap } }) => !!filtersMap[FiltersType.FILTER_HAS_STREAM],
      }),
      league() {
        return this.getLeagueById(this.leagueId);
      },
      leagueMatches() {
        return this.getFilteredMatchesByLeagueAndSport(this.leagueId, this.sportId);
      },
      matches() {
        return this.isStreamFilterActive ? this.leagueMatchesWithStream : this.leagueMatches;
      },
      matchesId() {
        return this.matches.map(({ id }) => id);
      },
      leagueMatchesWithStream() {
        return this.leagueMatches.filter((match) => !match.appliedFilter?.includes(FiltersType.FILTER_HAS_STREAM));
      },
      containsOnlyStreamMatches() {
        return this.leagueMatches.length !== 0 && this.leagueMatchesWithStream.length === 0;
      },
      leagueMatchCount() {
        return this.matches.length;
      },
      isRaceSportType() {
        return RACE_SPORT_TYPES.indexOf(this.sportType) > -1;
      },
      topMarket1Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.1`);
      },
      topMarket2Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.2`);
      },
      topMarket3Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.3`);
      },
      topMarketNames() {
        return [
          this.topMarket1Name,
          this.topMarket2Name,
          this.topMarket3Name,
        ];
      },
      showLiveBadge() {
        return this.isFeatureSupported(FEATURE_CB_LIVE)
          && this.leagueMatches?.some(({ mirrorReflex }) => mirrorReflex === false);
      },
      isCollapsed() {
        return this.isLeagueCollapsed(this.info);
      },
    },
    methods: {
      ...mapActions(`LiveStore`, {
        resetSportFilters: liveSportStoreActions.RESET_SPORT_FILTERS,
      }),
    },
  };

</script>
