/* eslint-disable prefer-const, no-unused-vars */

const ISO_INSTANT_OR_OFFSET = /(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)\.(\d+)(Z|([+-])(\d+):(\d+))/;

/**
 * @param {String} str
 * @returns {number|null}
 */
export function parseDateStringToUtc(str) {
  if (!str) return null;
  const matches = str.match(ISO_INSTANT_OR_OFFSET);

  if (!matches) return null;

  let [
    _,
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    millis,
    zoneInfo,
    sign,
    hoursOffset,
    minutesOffset,
  ] = matches;

  year = parseInt(year, 10);
  month = parseInt(month, 10) - 1;
  day = parseInt(day, 10);
  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);
  seconds = parseInt(seconds, 10);
  millis = parseInt(millis.substring(0, 3), 10);

  if (zoneInfo !== `Z`) {
    const k = sign === `+` ? -1 : +1;

    hoursOffset = parseInt(hoursOffset, 10);
    minutesOffset = parseInt(minutesOffset, 10);
    hours += (k * hoursOffset);
    minutes += (k * minutesOffset);
  }

  return Date.UTC(year, month, day, hours, minutes, seconds, millis);
}

export function formatMillisecondsToTime(millisecondsCount, format) {
  const secondsCount = millisecondsCount / 1000;
  let hours = Math.floor(secondsCount / 3600);
  let minutes = Math.floor((secondsCount - (hours * 3600)) / 60);
  let seconds = Math.floor(secondsCount - (hours * 3600) - (minutes * 60));

  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  switch (format) {
    case `short`:
      return `${hours}:${minutes}`;
    case `long`:
      return `${hours}:${minutes}:${seconds}`;
    default:
      return ``;
  }
}
