import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
import { v4 as uuidv4 } from 'uuid';
import { normalize } from 'normalizr';
import api from '@/config/api/live';
import prematchApi from '@/config/api/prematch';
import { TICKET_SUBMIT } from '@/globals/enums/DebugEvent';
import {
  actions as ticketStoreActions,
  getters as ticketStoreGetters,
  types as ticketStoreMutations,
} from '@/stores/ticket/TicketStore';
import {
  actions as marketStoreActions,
  getters as marketStoreGetters,
} from '@/stores/LiveMarketStore';
import {
  COMBINED_BETSLIP_ADD_BET,
  COMBINED_BETSLIP_BET_REMOVED_FROM_OFFER,
  COMBINED_BETSLIP_SELECTION_PRODUCT_TYPE,
  resolvePrematchEndpoint,
  resolveLiveTicketEndpoint,
} from '@/config/constants';
import store from '@/config/store';
import {
  TicketGroup,
  OperationState,
  TICKET_ITEM_STATUS_INACTIVE,
  TICKET_OPERATION_PHASE_ACCEPTED,
  TICKET_OPERATION_PHASE_INVALID,
  TICKET_OPERATION_PHASE_REJECTED,
  TICKET_OPERATION_PHASE_TIMED_OUT,
  TICKET_OPERATION_PHASE_VALID,
  PaymentKind,
} from '@/globals/enums/ticket.enums';
import { TicketSchema } from '@/globals/schemas/LiveSchema';
import * as userStoreGetters from '@/stores/UserStore/getters';
import {
  actions as liveOddsStore,
  actions as oddsStoreActions,
  types as oddsStoreMutations,
} from '@/stores/LiveOddsStore';
import { getNonNull } from '@/globals/utils/getters';
import { getCommunicationErrorMessage, createErrorMessage } from '@/globals/utils/localizations';
import { PromiseQueue } from '@/factories/PromiseQueue';
import { SEVERITY_SUCCESS } from '@/globals/enums/Severity.enums';
import { GTM_BETSLIP_ID_NOT_AVAILABLE } from '@/globals/enums/gtm.enums';
import { gtmService } from '@/services/GTMService/GTMService';
import { deleteMarketRowById } from '@/services/bettingoffer/MarketService';
import { MarketSubBox } from '@/services/SubBoxes/MarketDataSubBox';
import { loggerSeverity } from '@/globals/enums/Logger.enums';
import FortunaLogger from '@/services/Logger/Logger';
import EventBus from '@/services/EventBus';
import { PERFORMANCE_MARK_TICKET_SUBMIT_END } from '@/globals/enums/Performance.enums';
import {
  addMatchToFavorites,
} from '@/services/favorites/FavoriteMatchesService';
import translation from '@/plugins/translation';
import { SUBMIT_SUCCESS } from '@/globals/enums/eventBus/BettingEvents.enums';
import { isCbEnabled } from '@/globals/utils/combinedBetslip';
import { fetchUserInfo } from './UserHttpHandler';

export const ticketPromiseQueue = new PromiseQueue();

function processTicket({ data }) {
  // first make sure we have relevant up-to-date betting offer
  // by fetching snapshot and subscribing to relevant topics
  const { markets = {} } = normalize(data, TicketSchema).entities;
  const ticketData = getNonNull(data.ticket, `items`, []);

  store.dispatch(`LiveStore/${marketStoreActions.ADD_MARKET_LIST}`, markets);

  store.dispatch(`TicketStore/${ticketStoreActions.ACCEPT_ODDS_LIST}`,
    ticketData.reduce((values, { selectedOdds: { id, value } }) => {
      values[id] = value;
      return values;
    }, {})).then(() => {
    store.dispatch(`LiveStore/${oddsStoreActions.RESTORE_TICKET_ODDS_SELECTION}`);
  });

  return store
    .dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, data)
    .then(({ removedMarkets, newMarkets }) => {
      newMarkets.forEach((market) => MarketSubBox.addSubscriber(market, `betslip`));
      removedMarkets.forEach((market) => MarketSubBox.removeSubscriber(market, `betslip`));

      return { removedMarkets, newMarkets, data };
    });
}

export function ticketCreate({ stake, tipIds }) {
  return api
    .post(resolveLiveTicketEndpoint(`TICKET_CREATE`), { stake, tipIds })
    .then(processTicket);
}

export async function changeMode(ticketMode) {
  store.dispatch(
    `TicketStore/${ticketStoreActions.OPERATION_PENDING}`,
    `CHANGE_MODE`,
  );
  try {
    const { data } = await api.post(resolveLiveTicketEndpoint(`TICKET_CHANGE_MODE`), {
      ticketMode,
    });

    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`,
      data,
    );
  } catch (err) {
    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      getCommunicationErrorMessage(`change_mode`),
    );
  }
}

export async function changeBetGroup(tipId, info, group) {
  store.dispatch(
    `TicketStore/${ticketStoreActions.OPERATION_PENDING}`,
    `CHANGE_BET_GROUP`,
  );

  // sets group in store for certain bet
  store.commit(
    `TicketStore/${ticketStoreMutations.SWITCH_ODD_GROUP}`,
    { tipId, group },
  );

  try {
    const { data } = await api.post(resolveLiveTicketEndpoint(`TICKET_CHANGE_BET_GROUP`), {
      group,
      tipId,
      info,
    });

    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`,
      data,
    );

    if (data.phase === TICKET_OPERATION_PHASE_INVALID && data.operation === OperationState.STOPPED) {
      const previousGroup = (group === TicketGroup.A) ? TicketGroup.B : TicketGroup.A;

      store.commit(
        `TicketStore/${ticketStoreMutations.SWITCH_ODD_GROUP}`,
        { tipId, group: previousGroup },
      );
    }
  } catch (err) {
    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      getCommunicationErrorMessage(`change_bet_group`),
    );
  }
}

export function fetchTicket() {
  if (isCbEnabled()) {
    return;
  }

  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `FETCH`);

  // eslint-disable-next-line consistent-return
  return api
    .get(resolveLiveTicketEndpoint(`TICKET_INFO`))
    .then(processTicket)
    .catch((/* err */) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
        getCommunicationErrorMessage(`fetch`));
      return {};
    });
}

export async function markBetValue(index, value) {
  store.dispatch(
    `TicketStore/${ticketStoreActions.OPERATION_PENDING}`,
    `MARK_BET_VALUE`,
  );
  try {
    const { data } = await api.post(resolveLiveTicketEndpoint(`TICKET_MARK_BET_VALUE`), {
      index,
      value,
      equally: false,
    });

    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`,
      data,
    );
  } catch (err) {
    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      getCommunicationErrorMessage(`mark_bet_value`),
    );
  }
}

export async function changeBetValue(index, value) {
  store.dispatch(
    `TicketStore/${ticketStoreActions.OPERATION_PENDING}`,
    `CHANGE_BET_VALUE`,
  );
  try {
    const { data } = await api.post(resolveLiveTicketEndpoint(`TICKET_CHANGE_BET_VALUE`), {
      index,
      value,
      equally: false,
    });

    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`,
      data,
    );
  } catch (err) {
    store.dispatch(
      `TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      getCommunicationErrorMessage(`change_bet_value`),
    );
  }
}

export async function addBet({ id: oddsId, tipId, info, value, marketId }) {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `ADD_BET`);
  store.dispatch(`LiveStore/${liveOddsStore.TOGGLE_ODDS_SELECTION}`, {
    isSelected: true,
    oddsId,
    marketId,
  });
  // TnT: They want to track whether different odd was selected from same market
  const marketOnTicket = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET_ITEM_FOR_MARKET}`](marketId);

  try {
    if (isCbEnabled()) {
      EventBus.$emit(COMBINED_BETSLIP_ADD_BET, {
        marketId,
        selectionId: tipId,
        infoNumber: info,
        oddsValue: value,
        product: COMBINED_BETSLIP_SELECTION_PRODUCT_TYPE,
      });
    } else {
      const { data } = await api.post(resolveLiveTicketEndpoint(`TICKET_ADD_BET`), {
        tipId,
        info,
        value,
      });

      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, data)
        .then(({ removedMarkets, newMarkets, ticket }) => {
          // If null/undefined is returned in ticket, there was some error and ticket was not updated
          if (!ticket) {
            store.dispatch(`LiveStore/${liveOddsStore.RESTORE_TICKET_ODDS_SELECTION}`);
          }
          newMarkets.forEach((market) => MarketSubBox.addSubscriber(market, `betslip`));
          removedMarkets.forEach((market) => MarketSubBox.removeSubscriber(market, `betslip`));

          const [{ name, selectedOdds, id }] = data.ticket.items.filter((item) => item.marketId === marketId);

          gtmService.addToCart({
            betId: tipId,
            betRatio: selectedOdds.value,
            match: {
              name,
              id,
              marketId,
            },
          }, marketOnTicket);
        });
    }
    store.dispatch(`TicketStore/${ticketStoreActions.ACCEPT_ODDS}`, {
      tipId,
      value,
    });
  } catch (err) {
    store.dispatch(`LiveStore/${liveOddsStore.RESTORE_TICKET_ODDS_SELECTION}`);
    store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      getCommunicationErrorMessage(`add_bet`));
  }
}

export async function removeBetslipOdd({ id: oddsId, tipId, info, marketId, slotId }, emittedByCombinedBetslip = false) {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `REMOVE_BET`);
  store.dispatch(`LiveStore/${liveOddsStore.TOGGLE_ODDS_SELECTION}`, {
    isSelected: false,
    oddsId,
    marketId,
    slotId,
  });

  try {
    if (isCbEnabled()) {
      if (!emittedByCombinedBetslip) {
        EventBus.$emit(COMBINED_BETSLIP_BET_REMOVED_FROM_OFFER, {
          selectionId: tipId,
          marketId,
        });
      }
    } else {
      const { data } = await api.post(resolveLiveTicketEndpoint(`TICKET_REMOVE_BET`), {
        tipId,
        info,
      });

      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, data)
        .then(({ removedMarkets, newMarkets }) => {
          newMarkets.forEach((market) => MarketSubBox.addSubscriber(market, `betslip`));
          removedMarkets.forEach((market) => MarketSubBox.removeSubscriber(market, `betslip`));
        });
    }
    store.dispatch(`TicketStore/${ticketStoreActions.REMOVE_ACCEPTED_ODDS}`, { tipId });
    gtmService.removeFromCart({
      betIds: [tipId],
    });
  } catch (err) {
    store.dispatch(`LiveStore/${liveOddsStore.RESTORE_TICKET_ODDS_SELECTION}`);
    store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      getCommunicationErrorMessage(`remove_bet`));
  }
}

export function setPayValue(value) {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `SET_PAY_VALUE`);

  return api.post(resolveLiveTicketEndpoint(`TICKET_SET_PAY_VALUE`), {
    value,
  })
    .then((response) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, response.data);
    }, (/* err */) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
        getCommunicationErrorMessage(`set_pay_value`));
    });
}

export async function clearTicket(silent = false) {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `CLEAR`);

  try {
    if (!isCbEnabled()) {
      const response = await api.get(resolveLiveTicketEndpoint(`TICKET_CLEAR`));
      const betIds = [];

      store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET}`].items
        .forEach(({ marketId, selectedOdds }) => {
          MarketSubBox.removeSubscriber(marketId, `betslip`);
          betIds.push(selectedOdds.id);
        });
      gtmService.removeFromCart({ betIds });
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, response.data);
    }
    store.commit(`LiveStore/${oddsStoreMutations.CLEAR_SELECTED_ODDS}`);
  } catch (err) {
    store.dispatch(`LiveStore/${liveOddsStore.RESTORE_TICKET_ODDS_SELECTION}`);
    store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
      silent ? null : getCommunicationErrorMessage(`clear`));
  }
}

export function saveTicket() {
  return api.post(resolveLiveTicketEndpoint(`TICKET_SAVE`));
}

const handleErrorPhase = (errorKey, errorMessageArr) => {
  const errMessage = createErrorMessage(errorKey, { closable: true });

  store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_ERROR}`, errMessage);
  gtmService.betAcceptance(null, errorMessageArr[0]);
};

const handlersByPhase = {
  [TICKET_OPERATION_PHASE_ACCEPTED]: () => {
    store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_SUCCESS}`,
      {
        closable: true,
        severity: SEVERITY_SUCCESS,
        ticketSubmitted: true,
        text: translation.getTranslatedMessage(`live3.ticket.submission.accepted`),
        size: `normal`,
      });
    fetchUserInfo(true).then(() => {
      store.commit(`LiveStore/${oddsStoreMutations.CLEAR_SELECTED_ODDS}`);
    });
  },
  [TICKET_OPERATION_PHASE_REJECTED]: (errorMessages) => {
    handleErrorPhase(`live3.ticket.authorization.rejected`, errorMessages);
  },
  [TICKET_OPERATION_PHASE_TIMED_OUT]: (errorMessages) => {
    handleErrorPhase(`live3.ticket.authorization.timed.out`, errorMessages);
  },
  [TICKET_OPERATION_PHASE_INVALID]: (errorMessages) => {
    store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_ERROR}`);
    gtmService.betAcceptance(null, errorMessages[0]);
  },
  [TICKET_OPERATION_PHASE_VALID]: () => {
    store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_ERROR}`);
  },
};

const TICKET_RESOLVE_TIMEOUT = 5000;

/**
 * Adopt removed markets in case betting offer gets out ouf of sync
 * (i.e. we missed some websocket update)
 *
 * @param messages
 */
function synchronizeRemovedMarketsFromKom(messages) {
  const actualTicket = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET}`];
  const betslipCodes = store
    .getters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`inactiveMarketBetslipCodes`, []);
  const { items: actualTicketItems } = actualTicket;

  messages.forEach(({ itemIndex, code }) => {
    if (betslipCodes.includes(code)) {
      const ticketItem = actualTicketItems[itemIndex];
      const { marketId } = ticketItem;

      deleteMarketRowById(marketId);
    }
  });
}

function handleTicketSubmissionResponse(transactionId, data) {
  const {
    operation,
    phase,
    messages = [],
  } = data;

  const { ticketID: betslipId } = data.ticket || {};
  const ticketItems = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET}`].items;

  if (phase === TICKET_OPERATION_PHASE_ACCEPTED && operation === OperationState.FINISHED) {
    ticketItems.forEach(({ marketId }) => {
      MarketSubBox.removeSubscriber(marketId, `betslip`);
      addMatchToFavorites(store.getters[`LiveStore/${marketStoreGetters.GET_MATCH_ID_BY_MARKET_ID}`](marketId));
    });
    gtmService.betAcceptance(betslipId || GTM_BETSLIP_ID_NOT_AVAILABLE);
    performance.conditionalMark(PERFORMANCE_MARK_TICKET_SUBMIT_END);
    window.dispatchEvent(new CustomEvent(SUBMIT_SUCCESS, { detail: ticketItems }));
  }
  if (messages.length) {
    store.dispatch(
      `LiveStore/${oddsStoreActions.ODDS_CHANGE_BY_KOM_RESPONSE}`,
      { komMessages: messages },
    );
    synchronizeRemovedMarketsFromKom(messages);
  }
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, data);
  if (operation === OperationState.RUNNING) {
    // eslint-disable-next-line no-use-before-define
    setTimeout(() => resolveTicket(transactionId),
      store.getters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](
        `ticketResolveTimeoutMillis`,
        TICKET_RESOLVE_TIMEOUT,
      ),
    );
  } else {
    const handler = handlersByPhase[phase];

    if (handler) {
      handler(messages);
    } else {
      const messageOptions = data && data.messages && data.messages[0];

      store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_ERROR}`,
        createErrorMessage(`live3.ticket.submission.unknown.error`, messageOptions));
    }
  }
}

export function resolveTicket(transactionId) {
  api.post(resolveLiveTicketEndpoint(`TICKET_RESOLVE`), {
    transactionId,
  })
    .then(
      ({ data }) => handleTicketSubmissionResponse(transactionId, data),
      (/* err */) => {
        const message = createErrorMessage(`live3.ticket.submission.network.error`);

        store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`, message);
        store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_ERROR}`, message);
      });
}

export function submitTicket(changesHandlingType, payValue, odds) {
  store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_START}`, true);
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `TICKET_SUBMIT`);
  gtmService.betPlacement();

  const transactionId = uuidv4();

  const { id: userId } = store.getters[`UserStore/${userStoreGetters.GET_USER_INFO}`];
  const {
    items,
    paymentType,
    pointsForBet,
    totalPayInPoints,
    ...ticket
  } = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET}`];
  const selectedOdds = items.map(({ selectedOdds: { value, oddsId } }) => ({ value, oddsId }));
  const realTotalWin = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET_TOTAL_WIN}`];

  FortunaLogger.logMessage(loggerSeverity.INFO, {
    event: TICKET_SUBMIT,
    data: { ticket, changesHandlingType, selectedOdds, userId, realTotalWin },
  }, true);

  return api.post(resolveLiveTicketEndpoint(`TICKET_SUBMIT`), {
    transactionId,
    changesHandlingType,
    payValue,
    odds,
  })
    .then(({ data }) => handleTicketSubmissionResponse(transactionId, data),
      (error) => {
        const message = createErrorMessage((error.response && error.response.status === 401)
          ? `live3.ticket.submission.not.logged`
          : `live3.ticket.submission.network.error`);

        store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`, message);
        store.dispatch(`TicketStore/${ticketStoreActions.SUBMISSION_ERROR}`, message);
      });
}

export function changePaymentKind(paymentKind) {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `SET_PAYMENT_KIND`);
  api.post(resolveLiveTicketEndpoint(`TICKET_SET_PAYMENT_KIND`), {
    paymentKind,
  })
    .then((response) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, response.data);
      if (!response.data?.ticket) {
        store.commit(`TicketStore/${ticketStoreMutations.SET_PAYMENT_KIND}`, PaymentKind.CREDIT);
      }
    }, () => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
        getCommunicationErrorMessage(`set_payment_kind`));
      store.commit(`TicketStore/${ticketStoreMutations.SET_PAYMENT_KIND}`, PaymentKind.CREDIT);
    });
}

export function changeChampionship(championship) {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `SET_CHAMPIONSHIP`);
  api.post(resolveLiveTicketEndpoint(`TICKET_SET_CHAMPIONSHIP`, { championship }))
    .then((response) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, response.data);
      if (!response.data?.ticket) {
        store.commit(`TicketStore/${ticketStoreMutations.SET_CHAMPIONSHIP}`, false);
      }
    }, () => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
        getCommunicationErrorMessage(`set_championship`));
      store.commit(`TicketStore/${ticketStoreMutations.SET_CHAMPIONSHIP}`, false);
    });
}

export function acceptChanges() {
  const ticketItems = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET_ITEMS}`];
  const items = [];
  const acceptedOdds = {};

  ticketItems.forEach((item) => {
    const {
      selectedOdds,
      status,
      valueFromOffer: value,
    } = item;

    items.push({
      oddsId: selectedOdds.id,
      active: status !== TICKET_ITEM_STATUS_INACTIVE,
      value,
    });
    acceptedOdds[selectedOdds.id] = value;
  });
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `TICKET_ACCEPT_CHANGES`);
  api.post(resolveLiveTicketEndpoint(`TICKET_ACCEPT_CHANGES`), {
    items,
  })
    .then(({ data }) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, data);
      store.dispatch(`TicketStore/${ticketStoreActions.ACCEPT_ODDS_LIST}`, acceptedOdds);
      store.dispatch(`LiveStore/${liveOddsStore.RESTORE_TICKET_ODDS_SELECTION}`);
    }, () => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
        getCommunicationErrorMessage(`accept_changes`));
      store.dispatch(`LiveStore/${liveOddsStore.RESTORE_TICKET_ODDS_SELECTION}`);
    });
}

export function checkTicketByIdAndCode(ticketId, protectionCode) {
  return prematchApi.get(resolvePrematchEndpoint(`TICKET_CHECK`), { params: { ticketId, protectionCode } });
}
