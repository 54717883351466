<template>
  <div
    class="live_league_header"
    :class="{
      'live_league_header--collapsed': isCollapsed,
      short: short && !isFeatureSupported(FEATURE_CB_LIVE),
      //TODO: remove short-cb & isCombineBetslip comp. prop after CB is done
      'short-cb': short && isFeatureSupported(FEATURE_CB_LIVE),
      'live_league_header--collapsible': isCollapsible,
    }"
    @click="onClick"
  >
    <Icon
      v-if="isCollapsible"
      ref="arrow"
      class="live_league_header__state_arrow"
      :class="{ 'live_league_header__state_arrow--collapsed': isCollapsed }"
      size="xxxs"
      name="accordion-open"
    />
    <div class="live_league_header__league_name">
      <ImageErrorLogger
        v-if="showFlags"
        :src="leagueFlagImg"
        class="live_league_header__flag"
      />
      <div class="live_league_header__league_name_inner">
        {{ leagueName }}
      </div>
      <template v-if="isCollapsed">
        ({{ leagueMatchCount }})
      </template>
    </div>

    <template v-if="!hideTopMarkets">
      <div
        v-for="(marketName, index) in topMarketNames"
        :key="index"
        :class="{ [`live_league_header__top_market_name--${index + 1}`]: true }"
        class="live_league_header__top_market_name"
      >
        {{ marketName }}
      </div>
    </template>
    <Badge
      v-else-if="showLiveBadge && isCollapsed"
      type="live"
      class="live_league_header__live-badge"
    >
      {{ $t(`live3.badge.live`) }}
    </Badge>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
  import { TOGGLE_LEAGUE } from '@/stores/LiveLeagueStore/constants';

  import Badge from '@fortunaweb-fe/mooncake/dist/Badge';
  import Icon from '../Icon/Icon';
  import ImageErrorLogger from '../ImageErrorLogger/ImageErrorLogger';

  export default {
    name: `LiveLeagueHeader`,
    components: {
      ImageErrorLogger,
      Icon,
      Badge,
    },
    props: {
      collapsed: {
        type: Boolean,
        required: true,
      },
      leagueId: {
        type: String,
        required: true,
      },
      leagueName: {
        type: String,
        required: true,
      },
      leagueFlag: {
        type: String,
        required: false,
        default: null,
      },
      leagueMatchCount: {
        type: [String, Number],
        required: true,
      },
      topMarketNames: {
        type: Array,
        required: true,
      },
      isFavoriteLeague: {
        type: Boolean,
        required: false,
        default: false,
      },
      selectedLeagueId: {
        type: String,
        required: true,
      },
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
      showLiveBadge: {
        type: Boolean,
        required: false,
        default: false,
      },
      sportId: {
        type: String,
        required: true,
      },
      collapsingCategory: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        info: {
          id: this.leagueId,
          category: this.collapsingCategory,
        },
      };
    },
    computed: {
      ...mapGetters(`AppConfigurationStore`, {
        getConfigurationValue: APP_CONF_GET_CONF_VALUE,
      }),
      isCollapsed() {
        return this.isCollapsible && this.collapsed;
      },
      isCollapsible() {
        return this.selectedLeagueId !== this.leagueId;
      },
      hideTopMarkets() {
        return this.isCollapsed || this.short;
      },
      leagueFlagImg() {
        return this.leagueFlag ? `/static/flags/${this.leagueFlag.toLowerCase()}.png` : ``;
      },
      showFlags() {
        return this.getConfigurationValue(`showLeagueFlags`, false);
      },
    },
    methods: {
      ...mapActions(`LiveStore`, {
        setLeagueCollapseState: TOGGLE_LEAGUE,
      }),
      onClick() {
        if (!this.isCollapsible) return;
        this.setLeagueCollapseState(this.info);
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.live_league_header';
  $arrow_cell_width: 25px;
  $name_cell_width: 315px;

  #{$base_class} {
    display: grid;
    grid-template-columns: $arrow_cell_width $name_cell_width repeat(2, 1fr);
    position: sticky;
    top: 40px;
    height: var(--league-header-height);
    max-height: var(--league-header-height);
    overflow: hidden;

    @if ($theme == 'light') {
      background: var(--color-background--darker);
    } @else {
      background-color: var(--color-background--lighter);
    }
    border-bottom: 1px solid transparent;
    padding-right: 45px;
    z-index: 1;
    font-size: 13px;
    align-items: center;
    transition: border .3s linear;

    @if ($theme == 'light') {
      background: var(--color-background--darker);
    } @else {
      background-color: var(--color-background--lighter);
    }

    //TODO: keep only 'short' after CB is done
    &.short, &.short-cb {
      grid-template-columns: $arrow_cell_width auto;
      justify-items: center;
      padding-right: 0;
    }

    &--collapsible {
      cursor: pointer;

      #{$base_class}__league_name {
        grid-column: 2 / span 1;
        padding-left: 0;
      }
    }

    &--collapsed {
      border-bottom: 1px solid var(--color-background--darkest);

      //TODO: rewrite to 'short' after CB is done
      &.short-cb {
        grid-template-columns: $arrow_cell_width auto min-content;;
      }
    }

    &__state_arrow {
      grid-column-start: 1;
      z-index: z_index_for_layer($CONTENT_LAYER_1);
      transform-origin: 50% 50%;
      line-height: 1;
      position: absolute;
      top: 8px;
      left: 8px;
      @if ($theme == 'light') {
        color: var(--color-background-ticket--lighter);
      } @else {
        color: var(--color-white);
      }

      &--collapsed {
        transform: rotate(-90deg);
      }
    }

    &__league_name {
      grid-column: 1 / span 2;
      padding-left: 8px;
      justify-self: normal;
      white-space: nowrap;
      display: flex;
      align-items: center;
      overflow: hidden;

      &_inner {
        flex: 0 1 auto;
        padding-right: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @if ($theme == 'light') {
        color: var(--color-secondary-text--darker);
      } @else {
        color: var(--color-secondary-text);
      }
    }

    &__live-badge {
      grid-column-start: 3;
      justify-self: baseline;
      display: inline-block;
      width: 30px;
    }

    &__flag {
      flex: 0 0 auto;
      width: var(--league-header-height) / 1.6;
      height:  var(--league-header-height) / 1.6;
      background: no-repeat center center;
      background-size: contain;
      display: inline-block;
      margin-right: 3px;
      vertical-align: middle;

      &.error {
        display: none;
      }
    }

    &__top_market_name {
      text-align: center;
      justify-self: center;
      width: 100%;

      @if ($theme == 'light') {
        color: var(--color-secondary-text--darker);
      } @else {
        color: var(--color-secondary-text);
      }

      &--3 {
        display: none;
      }
    }

    @media (min-width: $top_market_3_visibility_breakpoint) {
      &__top_market_name--3 {
        display: table-cell;
      }

      grid-template-columns: $arrow_cell_width $name_cell_width repeat(3, 1fr);
    }
  }

</style>
