<template>
  <Icon
    class="match_stream_button"
    :title="$t('live3.match_stream_button.stream_available')"
    :name="isAudio ? `sound-on` : `play`"
    @click.prevent="playStream"
  />
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import {
    MATCH_STREAM_BUTTON_SHOW_VIDEO,
    MATCH_STREAM_BUTTON_SHOW_TRACKER,
  } from '@/globals/enums/eventBus/streamEvents.enums';
  import { fetchStream } from '@/services/http/StreamHttpHandler';
  import { ROUTE_MATCH_DETAIL } from '@/config/router/routes';
  import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
  import { isCbEnabled } from '@/globals/utils/combinedBetslip';
  import Icon from '../Icon/Icon';

  export default {
    name: `MatchStreamButton`,
    components: {
      Icon,
    },
    props: {
      matchId: {
        type: String,
        required: true,
      },
      streamNotInTracker: {
        type: Boolean,
        required: false,
        default: true,
      },
      isAudio: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      ...mapGetters(`AppConfigurationStore`, {
        getConfigurationValue: APP_CONF_GET_CONF_VALUE,
      }),
      ...mapState(`StreamStore`, {
        selectedMatchId: (state) => {
          if (!state.locked.url) {
            return state.stream.matchId;
          }
          return state.locked.matchId;
        },
      }),
      showCombinedBetslip() {
        return isCbEnabled();
      },
    },
    methods: {
      playStream() {
        if (!this.showCombinedBetslip) {
          if (!this.streamNotInTracker) {
            this.$eventBus.$emit(MATCH_STREAM_BUTTON_SHOW_TRACKER, { matchId: this.matchId });
          } else {
            fetchStream({ matchId: this.matchId }, true);
            this.$eventBus.$emit(MATCH_STREAM_BUTTON_SHOW_VIDEO);
          }
        } else {
          const targetTab = !this.streamNotInTracker ? `tracker` : `stream`;

          this.$router.push({
            name: ROUTE_MATCH_DETAIL,
            params: {
              matchId: this.matchId,
            },
            query: {
              tab: targetTab,
            },
          });
        }
      },
    },
  };
</script>
