import axios from 'axios';
import { getRequestLocale } from "@/config/locale";

const prematchApi = axios.create({
  timeout: 15000,
  withCredentials: true,
  headers: { 'Accept-Language': getRequestLocale() },
});

export default prematchApi;
