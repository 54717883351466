import result from 'lodash/result';

import { FortunaSite } from '@/globals/enums/enums';
import {
  SITENAME_CS_CZ,
  SITENAME_SK_SK,
  SITENAME_PL_PL,
  SITENAME_RO_RO,
  SITENAME_RO_CP,
  SITENAME_HR_HR,
} from '@/globals/enums/SiteName.enums';
import { SITE } from './constants';

const mapping = {
  [FortunaSite.CZ]: SITENAME_CS_CZ,
  [FortunaSite.SK]: SITENAME_SK_SK,
  [FortunaSite.PL]: SITENAME_PL_PL,
  [FortunaSite.RO]: SITENAME_RO_RO,
  [FortunaSite.CP]: SITENAME_RO_CP,
  [FortunaSite.HR]: SITENAME_HR_HR,
};

const DEFAULT_SITENAME = `aaaa`;

export function getSiteName() {
  return result(mapping, SITE, DEFAULT_SITENAME);
}
