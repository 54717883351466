import { APP_CONF_GET_CONF_VALUE } from "@/stores/AppConfigurationStore/constants";
import Vue from 'vue';
import constants, { resolveEndpoint } from '@/config/constants';
import { escapeRegExp } from '@/globals/utils/formatters';
import { offerFiltersDefinition } from '@/globals/components/OfferFilter/Definitions';

import { getterNames as favoriteMatchesGetters } from '@/stores/favorites/FavoriteMatchesStore';
import { LiveMatchStatus, SportSortsFiltersType } from '@/globals/enums/enums';
import { getters as liveMarketStoreGetters } from '@/stores/LiveMarketStore';
import { $LINK_MATCH_TO_LEAGUE } from "@/stores/LiveLeagueStore/constants";
import { ROUTE_MATCH_DETAIL, ROUTE_OVERVIEW_BY_SPORT } from "@/config/router/routes";
import { getters as liveSportStore } from "@/stores/LiveSportStore";
import { getters as liveMarketGroupGetters } from "@/stores/LiveMarketGroupStore";
import { isEmpty } from "lodash";

// Mutations
export const $UPDATE_MATCH = `$UPDATE_MATCH`;
export const $DELETE_MATCH = `$DELETE_MATCH`;
export const $UPDATE_MATCH_MARKETS_COUNT = `$UPDATE_MATCH_MARKETS_COUNT`;
export const $ADD_MATCH = `$ADD_MATCH_LIST`;
export const $DELETE_MATCH_LIST = `$DELETE_MATCH_LIST`;

// Actions
export const ADD_MATCH_LIST = `ADD_MATCH_LIST`;

export const getters = {
  GET_MATCH_COUNT: `getMatchCount`,
  GET_MATCH_BY_ID: `getMatchById`,
  GET_IS_MATCH_RUNNING_BY_ID: `getIsMatchRunning`,
  GET_PREMATCH_STATS_BY_MATCH_ID: `getPrematchStatsByMatchId`,
  GET_MATCH_BY_MARKET_ID: `getMatchByMarketId`,
  GET_MATCHES_WITH_STREAM: `getMatchesWithStream`,
  GET_MATCH_HAS_STREAM_BY_ID: `getMatchHasStreamById`,
  GET_MATCHES_GROUP_BY_SEARCH: `getMatchesBySearch`,
  GET_FAVORITE_MATCHES: `getFavoriteMatches`,
  GET_FAVORITE_MATCHES_COUNT: `getFavoriteMatchesCount`,
  GET_RUNNING_MATCHES_COUNT: `getRunningMatchesCount`,
  GET_FILTERED_MATCHES_BY_SPORT_ID: `getFilteredMatchesBySportId`,
  FILTERED_MATCHES_COUNT: `FILTERED_MATCHES_COUNT`,
  GET_FILTERED_MATCHES_COUNT_BY_SPORT: `getFilteredMatchesCountBySport`,
  GET_TRACKERS_FROM_MATCHES: `getTrackersFromMatches`,
  GET_TRACKER_BY_MATCH_ID: `getTrackerByMatchId`,
  GET_MATCH_HAS_TRACKER_BY_MATCH_ID: `getMatchHasTrackerByMatchId`,
  GET_MATCH_ROUTE_BY_ID: `GET_MATCH_ROUTE_BY_ID`,
  GET_FILTERED_MATCHES_BY_LEAGUE: `getFilteredMatchesByLeague`,
  GET_FILTERED_MATCH_COUNT_BY_LEAGUE: `GET_FILTERED_MATCH_COUNT_BY_LEAGUE`,
  GET_PREMATCH_MIRROR_URL_BY_MATCH_ID: `GET_PREMATCH_MIRROR_URL_BY_MATCH_ID`,
  CONTAINS_COMBINABLE_MARKETS_BY_MATCH_ID: `CONTAINS_COMBINABLE_MARKETS_BY_MATCH_ID`,
  GET_MATCH_MARKET_IDS: `GET_MATCH_MARKET_IDS`,
  FAVORITE_MATCHES_BY_SPORT: `favoriteMatchesForSport`,
};

export const reduceMatchForOverview = (matchArray) => matchArray.map((
  {
    topMarkets,
    miniScoreboard,
    startDate,
    marketsCount,
    channelId,
    prematchItem,
    prematchStatsActive,
    competitionNames,
    displayOrder,
    names,
    leagueId: matchLeagueId,
    overview,
    ...match
  }) => match);

export const applyAllFilters = (matches, activeFilters) => {
  if (activeFilters.FILTER_DURATION) {
    const offerFilterButtonDefinition = offerFiltersDefinition.find(
      (value) => value.offerFilterType === activeFilters.FILTER_DURATION,
    );

    matches = matches.filter(offerFilterButtonDefinition.offerFilter(new Date()));
  }

  if (activeFilters.FILTER_HAS_STREAM) {
    matches = matches.map((match) => {
      const filteredMatch = { ...match };

      if (!filteredMatch.stream) {
        const appliedFilter = match.appliedFilter || [];

        appliedFilter.push(SportSortsFiltersType.FILTER_HAS_STREAM);
        filteredMatch.appliedFilter = appliedFilter;
      }

      return filteredMatch;
    });
  }

  return matches;
};

const liveMatchStore = {
  state: {
    matches: {},
  },
  actions: {
    [ADD_MATCH_LIST]: ({ commit }, matches) => {
      Object
        .values(matches)
        .forEach((match) => {
          commit($LINK_MATCH_TO_LEAGUE, match);
          commit($ADD_MATCH, match);
        });
    },
  },
  mutations: {
    [$UPDATE_MATCH]: (state, { match }) => {
      const { id: matchId } = match;
      const currentMatch = state.matches[matchId];

      if (!currentMatch) {
        Vue.set(state.matches, match.id, match);
        return;
      }

      // preserve fields which are sent in different stomp topics
      match.topMarkets = currentMatch.topMarkets;
      match.marketsCount = currentMatch.marketsCount;

      Object.entries(match).forEach(([key, value]) => {
        if (state.matches?.[matchId]?.[key] !== value) {
          Vue.set(state.matches[matchId], key, value);
        }
      });
    },
    [$DELETE_MATCH]: (state, { id }) => {
      Vue.delete(state.matches, id);
    },
    [$UPDATE_MATCH_MARKETS_COUNT]: (state, { matchId, count }) => {
      const match = state.matches[matchId];

      if (!match) {
        // eslint-disable-next-line no-undef
        if (NODE_ENV !== `production`) {
          // eslint-disable-next-line no-console
          console.warn(`Attempt to update markets count for non-existing match ${matchId}`);
        }
        return;
      }
      Vue.set(state.matches[matchId], `marketsCount`, count);
    },
    [$ADD_MATCH]: (state, match) => {
      Vue.set(state.matches, match.id, match);
    },
    [$DELETE_MATCH_LIST]: (state, matchIds) => {
      matchIds.forEach((matchId) => delete state.matches[matchId]);
    },
  },
  getters: {
    [getters.GET_MATCH_BY_ID](state) {
      return (matchId) => state.matches[matchId] || {};
    },
    [getters.GET_IS_MATCH_RUNNING_BY_ID](state) {
      return (matchId) => !isEmpty(state.matches[matchId]);
    },
    [getters.GET_PREMATCH_STATS_BY_MATCH_ID](state) {
      return (matchId) => {
        if (state.matches[matchId]) {
          const { prematchStatsActive, prematchItem } = state.matches[matchId];

          return { prematchStatsActive, prematchItem };
        }
        return null;
      };
    },
    [getters.FILTERED_MATCHES_COUNT](state, moduleGetters) {
      return applyAllFilters(Object.values(state.matches), moduleGetters[liveSportStore.GET_SPORT_FILTERS]).length;
    },
    [getters.GET_FILTERED_MATCHES_BY_SPORT_ID](state, moduleGetters) {
      return (sportId) => {
        let matches = Object.values(state.matches || {});

        if (sportId !== null) {
          matches = matches.filter(({ sportId: matchSportId }) => matchSportId === sportId);
        }

        return applyAllFilters(matches, moduleGetters[liveSportStore.GET_SPORT_FILTERS]);
      };
    },
    [getters.GET_FILTERED_MATCHES_COUNT_BY_SPORT](state, moduleGetters, rootState) {
      return (sportId) => {
        const pickedSport = rootState.LiveSportMenuStore.sport;
        let matchesForSport = Object.freeze(moduleGetters[getters.GET_FILTERED_MATCHES_BY_SPORT_ID](sportId));

        if (pickedSport === constants.FAVORITES_SPORT_ID) {
          matchesForSport = matchesForSport
            .filter(({ id }) => rootState.FavoriteMatchesStore.favoriteMatchesById[id]);
        }

        return matchesForSport.length;
      };
    },
    [getters.GET_FILTERED_MATCHES_BY_LEAGUE](state, moduleGetters) {
      return (leagueId) => {
        let matches = Object
          .values(state.matches || {})
          .filter(({ leagueId: matchLeagueId }) => matchLeagueId === leagueId);

        matches = applyAllFilters(matches, moduleGetters[liveSportStore.GET_SPORT_FILTERS]);

        return reduceMatchForOverview(matches);
      };
    },
    [getters.GET_FILTERED_MATCH_COUNT_BY_LEAGUE](_state, moduleGetters) {
      return (leagueId) => moduleGetters[getters.GET_FILTERED_MATCHES_BY_LEAGUE](leagueId).length;
    },
    [getters.FAVORITE_MATCHES_BY_SPORT](state, moduleGetters, rootState, rootGetters) {
      return (sportId) => {
        const favoriteMatchIds = rootGetters[`FavoriteMatchesStore/${favoriteMatchesGetters.ALL_FAVORITE_IDS}`];

        const favoriteMatches = favoriteMatchIds.reduce((matches, matchId) => {
          const match = state.matches[matchId] || {};

          if (match.sportId === sportId) matches[matchId] = match;
          return matches;
        }, {});

        const matches = Object.values(favoriteMatches);

        return applyAllFilters(matches, moduleGetters[liveSportStore.GET_SPORT_FILTERS]);
      };
    },
    [getters.GET_MATCH_COUNT](state) {
      return Object.keys(state.matches).length;
    },
    [getters.GET_MATCHES_WITH_STREAM](state) {
      return Object.values(state.matches)
        .filter((match) => !!match.stream && match.status === LiveMatchStatus.RUNNING)
        .reduce((acc, match) => {
          acc[match.id] = match;
          return acc;
        }, {});
    },
    [getters.GET_MATCH_HAS_STREAM_BY_ID](state) {
      return (matchId) => !!state.matches[matchId].stream;
    },
    [getters.GET_MATCHES_GROUP_BY_SEARCH](state) {
      return (searchString) => {
        const searchResults = {
          running: [],
          upcoming: [],
        };

        const matchStatusToSearchResults = {
          [LiveMatchStatus.RUNNING]: `running`,
          [LiveMatchStatus.PENDING]: `upcoming`,
        };

        const searchPattern = new RegExp(escapeRegExp(searchString), `i`);

        Object.keys(state.matches).forEach((matchId) => {
          const match = state.matches[matchId];

          if (searchPattern.test(match.names)) {
            searchResults[matchStatusToSearchResults[match.status]].push(match);
          }
        });
        return searchResults;
      };
    },
    [getters.GET_FAVORITE_MATCHES](state, moduleGetters, rootState, rootGetters) {
      return () => {
        const matchIds = rootGetters[`FavoriteMatchesStore/${favoriteMatchesGetters.ALL_FAVORITE_IDS}`];
        let matches = Object.values(state.matches || {}).filter((match) => matchIds.includes(match.id));

        matches = applyAllFilters(matches, moduleGetters[liveSportStore.GET_SPORT_FILTERS]);

        return matches;
      };
    },
    [getters.GET_FAVORITE_MATCHES_COUNT](state, moduleGetters) {
      const matches = moduleGetters[getters.GET_FAVORITE_MATCHES]();

      return matches.length;
    },
    [getters.GET_RUNNING_MATCHES_COUNT](state) {
      return Object.values(state.matches).reduce((count, match) => {
        if (match.status === LiveMatchStatus.RUNNING) count += 1;
        return count;
      }, 0);
    },
    [getters.GET_MATCH_BY_MARKET_ID](state, moduleGetters, rootState, rootGetters) {
      return (marketId) => {
        const matchId = rootGetters[`LiveStore/${liveMarketStoreGetters.GET_MATCH_ID_BY_MARKET_ID}`](marketId);

        return moduleGetters[getters.GET_MATCH_BY_ID](matchId) || {};
      };
    },
    [getters.CONTAINS_COMBINABLE_MARKETS_BY_MATCH_ID](state, moduleGetters, rootState, rootGetters) {
      return (matchId) => rootGetters[`LiveStore/${liveMarketStoreGetters.GET_COMBINABLE_MARKETS}`](matchId).length > 0;
    },
    [getters.GET_MATCH_MARKET_IDS](state, moduleGetters, rootState, rootGetters) {
      return (matchId) => {
        const marketGroups = rootGetters[`LiveStore/${liveMarketGroupGetters.GET_MARKET_GROUPS_BY_MATCH_ID}`](matchId);

        return marketGroups.reduce((allMarkets, group) => {
          allMarkets.push(...group.markets);
          return allMarkets;
        }, []);
      };
    },
    [getters.GET_TRACKERS_FROM_MATCHES](state) {
      return Object.values(state.matches).reduce((trackerMatchesAcc, match) => {
        if (match.matchTracker) {
          trackerMatchesAcc.push({
            ...match.matchTracker,
            text: `${match.team1Names} - ${match.team2Names}`,
            value: match.id,
            matchTrackerId: match.matchTracker.id,
            sportId: match.sportId,
          });
        }

        return trackerMatchesAcc;
      }, []);
    },
    [getters.GET_TRACKER_BY_MATCH_ID](state) {
      return (matchId) => state.matches[matchId].matchTracker;
    },
    [getters.GET_MATCH_HAS_TRACKER_BY_MATCH_ID](state, moduleGetters) {
      return (matchId) => !!moduleGetters[getters.GET_TRACKER_BY_MATCH_ID](matchId);
    },
    [getters.GET_MATCH_ROUTE_BY_ID]() {
      return (matchId, $route, sportId) => {
        const params = { matchId };
        const { name: routeName, params: routeParams } = $route;

        if (routeName === ROUTE_OVERVIEW_BY_SPORT || (routeName === ROUTE_MATCH_DETAIL && routeParams.sportId)) {
          params.sportId = routeParams.sportId;
        }

        params.sportId = sportId || params.sportId;

        return {
          name: ROUTE_MATCH_DETAIL,
          params,
        };
      };
    },
    [getters.GET_PREMATCH_MIRROR_URL_BY_MATCH_ID](state, localGetters, rootState, rootGetters) {
      return (matchId) => {
        const { mirrorSourceEventId } = state.matches[matchId];

        if (!mirrorSourceEventId) return ``;
        const desktopUrl = rootGetters[`AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`desktopUrl`);

        return `${desktopUrl}/${resolveEndpoint(`EXTERNAL_EVENT`)}/${mirrorSourceEventId}`;
      };
    },
  },
};

export default liveMatchStore;
