export class PromiseQueue {
  queue = [];
  workingOnPromise = false;

  push(func) {
    this.queue.push(func);
    this.dequeue();
  }

  dequeue() {
    if (this.workingOnPromise) {
      return false;
    }
    const func = this.queue.shift();

    if (!func) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      func()
        .finally(() => {
          this.workingOnPromise = false;
          this.dequeue();
        });
    } catch (err) {
      this.workingOnPromise = false;
      this.dequeue();
    }
    return true;
  }
}
