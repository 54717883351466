import Vue from 'vue';

import safeGet from 'lodash/get';

export const types = {
  CREATE_TAB_COMPONENT: `CREATE_TAB_COMPONENT`,
  CHANGE_ACTIVE_TAB: `CHANGE_ACTIVE_TAB`,
};

export const actions = {
  CREATE_TAB_COMPONENT: `createTabComponent`,
  CHANGE_ACTIVE_TAB: `changeActiveTab`,
};

export const getters = {
  GET_ACTIVE_TAB_BY_NAME: `getActiveTabByName`,
};

const tabMenuStore = {
  namespaced: true,
  state: {},
  mutations: {
    [types.CREATE_TAB_COMPONENT](state, { name, activeTab }) {
      Vue.set(state, name, { activeTab });
    },
    [types.CHANGE_ACTIVE_TAB](state, { name, index: activeTab }) {
      Vue.set(state, name, { activeTab });
    },
  },
  actions: {
    [actions.CREATE_TAB_COMPONENT]({ commit }, data) {
      commit(types.CREATE_TAB_COMPONENT, data);
    },
    [actions.CHANGE_ACTIVE_TAB]({ commit }, data) {
      commit(types.CHANGE_ACTIVE_TAB, data);
    },
  },
  getters: {
    [getters.GET_ACTIVE_TAB_BY_NAME]: (state) => (menuType) => safeGet(state, `${menuType}.activeTab`),
  },
};

export default tabMenuStore;
