var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live_league_header",class:{
    'live_league_header--collapsed': _vm.isCollapsed,
    short: _vm.short && !_vm.isFeatureSupported(_vm.FEATURE_CB_LIVE),
    //TODO: remove short-cb & isCombineBetslip comp. prop after CB is done
    'short-cb': _vm.short && _vm.isFeatureSupported(_vm.FEATURE_CB_LIVE),
    'live_league_header--collapsible': _vm.isCollapsible,
  },on:{"click":_vm.onClick}},[(_vm.isCollapsible)?_c('Icon',{ref:"arrow",staticClass:"live_league_header__state_arrow",class:{ 'live_league_header__state_arrow--collapsed': _vm.isCollapsed },attrs:{"size":"xxxs","name":"accordion-open"}}):_vm._e(),_c('div',{staticClass:"live_league_header__league_name"},[(_vm.showFlags)?_c('ImageErrorLogger',{staticClass:"live_league_header__flag",attrs:{"src":_vm.leagueFlagImg}}):_vm._e(),_c('div',{staticClass:"live_league_header__league_name_inner"},[_vm._v(" "+_vm._s(_vm.leagueName)+" ")]),(_vm.isCollapsed)?[_vm._v(" ("+_vm._s(_vm.leagueMatchCount)+") ")]:_vm._e()],2),(!_vm.hideTopMarkets)?_vm._l((_vm.topMarketNames),function(marketName,index){return _c('div',{key:index,staticClass:"live_league_header__top_market_name",class:{ [`live_league_header__top_market_name--${index + 1}`]: true }},[_vm._v(" "+_vm._s(marketName)+" ")])}):(_vm.showLiveBadge && _vm.isCollapsed)?_c('Badge',{staticClass:"live_league_header__live-badge",attrs:{"type":"live"}},[_vm._v(" "+_vm._s(_vm.$t(`live3.badge.live`))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }