import { AlertMessage } from '@/factories/AlertMessage';
import {
  ChangesHandlingType,
  PaymentKind,
  TICKET_AVAILABLE_MODES,
  TICKET_ITEM_SIGNATURE_BONUS,
  TICKET_ITEM_STATUS_EQUAL,
  TICKET_ITEM_STATUS_HIGHER,
  TICKET_ITEM_STATUS_INACTIVE,
  TICKET_ITEM_STATUS_LOWER,
  TICKET_MODE_NONE,
  TICKET_MODE_SYSTEM,
  TicketGroup,
} from '@/globals/enums/ticket.enums';
import safeGet from 'lodash/get';
import Vue from 'vue';
import { EMPTY_TICKET } from '@/globals/enums/kom/ErrorCode';
import { getCurrencySymbol } from '@/globals/utils/localizations';
import { toFixed, powerSet } from "@fortunaweb-fe/frontend-commons/dist/utils/math";
import { fetchBetslipBonusOverview } from '@/services/bonus/Bonus.service';
import constants from '@/config/constants/index';
import { getters as marketStoreGetters } from '../LiveMarketStore';
import * as userStoreGetters from '../UserStore/getters';

export const types = {
  OPERATION_PENDING: `TICKET_OPERATION_PENDING`,
  OPERATION_SUCCESS: `TICKET_OPERATION_SUCCESS`,
  OPERATION_ERROR: `TICKET_OPERATION_ERROR`,
  SET_CHANGES_HANDLING_TYPE: `TICKET_SET_CHANGES_HANDLING_TYPE`,
  SET_SLIDER_VALUE: `TICKET_SET_SLIDER_VALUE`,
  SUBMISSION_START: `TICKET_SUBMISSION_START`,
  SUBMISSION_SUCCESS: `TICKET_SUBMISSION_SUCCESS`,
  SUBMISSION_ERROR: `TICKET_SUBMISSION_ERROR`,
  ACCEPT_ODDS: `ACCEPT_ODDS`,
  ACCEPT_ODDS_LIST: `ACCEPT_ODDS_LIST`,
  REMOVE_ACCEPTED_ODDS: `REMOVE_ACCEPTED_ODDS`,
  TICKET_MESSAGE_DELETE_BY_ITEM_INDEX: `TICKET_MESSAGE_DELETE_BY_ITEM_INDEX`,
  TICKET_MESSAGES_REMOVE: `TICKET_MESSAGES_REMOVE`,
  SWITCH_ODD_GROUP: `SWITCH_ODD_GROUP`,
  SET_BETSLIP_BONUS_OVERVIEW: `SET_BETSLIP_BONUS_OVERVIEW`,
  SET_PAYMENT_KIND: `SET_PAYMENT_KIND`,
  SET_CHAMPIONSHIP: `SET_CHAMPIONSHIP`,
};

export const actions = {
  OPERATION_PENDING: `ticketOperationPending`,
  OPERATION_SUCCESS: `ticketOperationSuccess`,
  OPERATION_ERROR: `ticketOperationError`,
  SET_CHANGES_HANDLING_TYPE: `ticketSetChangesHandlingType`,
  SET_SLIDER_VALUE: `ticketSetSliderValue`,
  SUBMISSION_START: `ticketSubmissionStart`,
  SUBMISSION_SUCCESS: `ticketSubmissionSuccess`,
  SUBMISSION_ERROR: `ticketSubmissionError`,
  ACCEPT_ODDS: `acceptOdds`,
  ACCEPT_ODDS_LIST: `acceptOddsList`,
  REMOVE_ACCEPTED_ODDS: `removeAcceptedOdds`,
  TICKET_MESSAGE_DELETE_BY_ITEM_INDEX: `ticketMessageDeleteByItemIndex`,
  FETCH_BETSLIP_BONUS_OVERVIEW: `fetchBetslipBonusOverview`,
};

export const getters = {
  GET_TICKET: `getTicket`,
  GET_TICKET_MODE: `getTicketMode`,
  GET_TICKET_AVAILABLE_MODES: `getTicketAvailableModes`,
  GET_TICKET_ITEMS: `getTicketItems`,
  GET_TICKET_BETS: `getTicketBets`,
  GET_TICKET_ITEMS_COUNT: `getTicketItemsCount`,
  GET_TICKET_TOTAL_BET: `getTicketTotalBet`,
  GET_TICKET_TOTAL_TAKING: `getTicketTotalTaking`,
  GET_TICKET_TOTAL_PAY: `getTicketTotalPay`,
  GET_TOTAL_PAY_IN_POINTS: `getTicketTotalPayInPoints`,
  ACCEPTED_TOTAL_ODDS: `acceptedTotalOdds`,
  GET_TICKET_TOTAL_ODDS: `getTicketTotalOdds`,
  GET_TICKET_TOTAL_WIN: `getTicketTotalWin`,
  CURRENCY_CODE: `currencyCode`,
  CURRENCY_SYMBOL: `currencySymbol`,
  GET_TICKET_MESSAGES: `getTicketMessages`,
  GET_TICKET_HANDLING_CHARGE: `getTicketHandlingCharge`,
  GET_TICKET_HANDLING_CHARGE_RATIO: `getTicketHandlingChargeRatio`,
  GET_TICKET_HANDLING_CHARGE_PERCENTAGE: `getTicketHandlingChargePercentage`,
  GET_TICKET_HANDLING_CHARGE_TYPE: `getTicketHandlingChargeType`,
  GET_PAYMENT_KIND: `getPaymentKind`,
  GET_CHAMPIONSHIP: `getChampionship`,
  GET_CHAMPIONSHIP_ACTIVE: `getChampionshipActive`,
  GET_POINTS_FOR_BET: `getPointsForBet`,
  IS_EMPTY: `isTicketEmpty`,
  IS_ODDS_ON_TICKET: `isOddsOnTicket`,
  IS_MARKET_ON_TICKET: `isMarketOnTicket`,
  IS_SUBMISSION_IN_PROGRESS: `isSubmissionInProgress`,
  GET_TICKET_ITEM_FOR_MARKET: `getTicketItemForMarket`,
  GET_TICKET_ITEM_FOR_MARKET_ROW_ID: `getTicketItemForMarketRowId`,
  IS_ODDS_OUTDATED: `isOddsOutdated`,
  GET_TICKET_ODDS: `getTicketOdds`,
  TICKET_SUBMISSION_SUCCESS: `ticketSubmissionSuccess`,
  TICKET_CHANGES_MUST_BE_ACCEPTED: `ticketChangesMustBeAccepted`,
};

export const realtimePrizeCalculation = (oddsArray, totalPay, fixOddsValue = 1) => {
  const combinationsArray = powerSet(oddsArray);
  const multiplicand = totalPay / (combinationsArray.length === 0 ? 1 : combinationsArray.length);
  const product = (a, c) => a * c;
  const sum = (acc, cur) => acc + (cur.reduce(product, 1) * multiplicand * fixOddsValue);

  return combinationsArray.reduce(sum, 0);
};

const getOddsArray = (state, marketRowGetter, group) => (safeGet(state.ticket, `items`) || [])
  .filter((item) => item.status !== TICKET_ITEM_STATUS_INACTIVE)
  .filter((item) => (group ? item.group === group : true))
  .map((item) => {
    const marketRowOdds = marketRowGetter(item.marketId);

    return (marketRowOdds || []).find((marketRowOdd) => item.selectedOdds.oddsId.includes(marketRowOdd.id));
  })
  .filter((odds) => odds && odds.value)
  .map((odds) => odds.value);

const calculateOdds = (state, marketRowGetter) => getOddsArray(state, marketRowGetter)
  .reduce((acc, oddsValue) => acc * oddsValue, 1);

const getFixOddsValue = (state, marketRowGetter, group) => getOddsArray(state, marketRowGetter, group)
  .reduce((acc, cur) => acc * cur, 1);

function setItemStatusFromBettingOffer(item, marketRowGetter) {
  let odds;

  if (item.signature === TICKET_ITEM_SIGNATURE_BONUS) {
    // We do not receive Bonus as a market in the overview data
    odds = { value: item.selectedOdds.value };
  } else {
    const marketRowOdds = marketRowGetter(item.marketId);

    odds = (marketRowOdds || []).find((marketRowOdd) => item.selectedOdds.oddsId.includes(marketRowOdd.id));
  }

  let status;

  // this checks that market for the ticket item is not expired (odds === undefined)
  // or that it was disabled (odds === -1) due to too low odds
  if (odds && odds.value >= constants.MIN_LEGAL_ODDS_VALUE) {
    item.valueFromOffer = odds.value;
    // XXX: warn string vs. double, will this work?
    const difference = odds.value - item.selectedOdds.value;

    if (difference > 0) {
      status = TICKET_ITEM_STATUS_HIGHER;
    } else if (difference < 0) {
      status = TICKET_ITEM_STATUS_LOWER;
    } else {
      status = TICKET_ITEM_STATUS_EQUAL;
    }
  } else if (item.signature === TICKET_ITEM_SIGNATURE_BONUS) {
    status = TICKET_ITEM_STATUS_EQUAL;
  } else {
    status = TICKET_ITEM_STATUS_INACTIVE;
  }

  item.status = status;

  return item;
}

const ticketStore = {
  namespaced: true,
  state: {
    ticket: {},
    acceptedOddsValues: {},
    sliderValue: null,
    changesHandlingType: ChangesHandlingType.IGNORE,
    messages: [],
    isLoading: false,
    isError: false,
    operation: null,
    submission: {
      isError: false,
      inProgress: false,
    },
    oddsStates: {},
    betslipBonusOverview: [],
  },
  mutations: {
    [types.OPERATION_PENDING](state, operation) {
      state.operation = operation;
      state.isLoading = true;
      state.isError = false;
      state.messages = [];
    },
    [types.OPERATION_SUCCESS](state, { messages, ticket }) {
      state.isLoading = false;
      state.isError = false;
      state.sliderValue = null;
      state.messages = messages;
      if (ticket) {
        state.ticket = ticket;
      }
    },
    [types.OPERATION_ERROR](state, message = null) {
      state.isLoading = false;
      state.isError = true;
      state.sliderValue = null;
      if (message) {
        state.messages = [
          message,
        ];
      }
    },
    [types.SET_CHANGES_HANDLING_TYPE](state, changesHandlingType) {
      state.changesHandlingType = changesHandlingType;
    },
    [types.SET_SLIDER_VALUE](state, sliderValue) {
      state.sliderValue = sliderValue;
    },
    [types.SUBMISSION_START](state) {
      state.submission.isError = false;
      state.submission.inProgress = true;
    },
    [types.SUBMISSION_ERROR](state, message) {
      state.submission.isError = true;
      state.submission.inProgress = false;
      if (message) {
        state.messages = [
          message,
        ];
      }
    },
    [types.SUBMISSION_SUCCESS](state, message) {
      state.submission.isError = false;
      state.submission.inProgress = false;
      if (message) {
        state.messages = [
          message,
        ];
      }
    },
    [types.ACCEPT_ODDS](state, { tipId, value }) {
      Vue.set(state.acceptedOddsValues, tipId, value);
    },
    [types.ACCEPT_ODDS_LIST](state, oddsValues) {
      state.acceptedOddsValues = {
        ...state.acceptedOddsValues,
        ...oddsValues,
      };
    },
    [types.REMOVE_ACCEPTED_ODDS](state, { tipId }) {
      Vue.delete(state.acceptedOddsValues, tipId);
    },
    [types.TICKET_MESSAGE_DELETE_BY_ITEM_INDEX](state, itemIndex) {
      const { messages } = state;

      state.messages = messages.filter(({ itemIndex: storeItemIndex }) => storeItemIndex !== itemIndex);
    },
    [types.TICKET_MESSAGES_REMOVE]: (state) => {
      state.messages = [];
    },
    [types.SWITCH_ODD_GROUP]: (state, { tipId, group }) => {
      state.ticket.items.forEach((item) => {
        if (item.selectedOdds.id === tipId) {
          Vue.set(item, `group`, group);
        }
      });
    },
    [types.SET_BETSLIP_BONUS_OVERVIEW]: (state, bonusOverview) => {
      Vue.set(state, `betslipBonusOverview`, bonusOverview);
    },
    [types.SET_PAYMENT_KIND](state, paymentType) {
      Vue.set(state.ticket, `paymentType`, paymentType);
    },
    [types.SET_CHAMPIONSHIP](state, championship) {
      Vue.set(state.ticket, `championship`, championship);
    },
  },
  actions: {
    [actions.OPERATION_PENDING]({ commit }, data) {
      commit(types.OPERATION_PENDING, data);
    },
    [actions.OPERATION_SUCCESS]({ state, commit }, payload = {}) {
      const { items } = payload.ticket || {};
      const { items: stateItems } = state.ticket;

      const ticketItems = (items || []).reduce((acc, { marketId }) => {
        acc[marketId] = marketId;
        return acc;
      }, {});

      const removedMarkets = [];
      const newMarkets = Object.keys(ticketItems);

      if (items) {
        (stateItems || []).forEach(({ marketId }) => {
          if (!ticketItems[marketId]) {
            removedMarkets.push(marketId);
          }
        });
      }

      let { messages } = payload;
      let { ticket } = payload;

      messages = messages.map(AlertMessage);

      // ticket is not present in KOM => reset state on frontend
      if (messages && messages.some((m) => m.code === EMPTY_TICKET)) {
        ticket = {};
      }

      commit(types.OPERATION_SUCCESS, {
        messages,
        ticket,
      });

      return { removedMarkets, newMarkets };
    },
    [actions.OPERATION_ERROR]({ commit }, data) {
      commit(types.OPERATION_ERROR, data);
    },
    [actions.SET_CHANGES_HANDLING_TYPE]({ commit }, data) {
      commit(types.SET_CHANGES_HANDLING_TYPE, data);
    },
    [actions.SET_SLIDER_VALUE]({ commit }, data) {
      commit(types.SET_SLIDER_VALUE, data);
    },
    [actions.SUBMISSION_START]({ commit }, data) {
      commit(types.SUBMISSION_START, data);
    },
    [actions.SUBMISSION_SUCCESS]({ commit }, data) {
      commit(types.SUBMISSION_SUCCESS, data);
    },
    [actions.SUBMISSION_ERROR]({ commit }, data) {
      commit(types.SUBMISSION_ERROR, data);
    },
    [actions.ACCEPT_ODDS]({ commit }, payload) {
      commit(types.ACCEPT_ODDS, payload);
    },
    [actions.ACCEPT_ODDS_LIST]({ commit }, payload) {
      commit(types.ACCEPT_ODDS_LIST, payload);
    },
    [actions.REMOVE_ACCEPTED_ODDS]({ commit }, payload) {
      commit(types.REMOVE_ACCEPTED_ODDS, payload);
    },
    [actions.TICKET_MESSAGE_DELETE_BY_ITEM_INDEX]({ commit }, payload) {
      commit(types.TICKET_MESSAGE_DELETE_BY_ITEM_INDEX, payload);
    },
    async [actions.FETCH_BETSLIP_BONUS_OVERVIEW]({ commit, rootGetters }) {
      const customerId = rootGetters[`UserStore/${userStoreGetters.GET_USER_INFO}`].id;

      if (!customerId) return;

      const bonusOverview = await fetchBetslipBonusOverview(customerId);

      commit(types.SET_BETSLIP_BONUS_OVERVIEW, bonusOverview);
    },
  },
  getters: {
    [getters.GET_TICKET](state) {
      return state.ticket;
    },
    [getters.GET_TICKET_MODE](state) {
      return safeGet(state.ticket, `mode`) || TICKET_MODE_NONE;
    },
    [getters.GET_TICKET_BETS](state) {
      return (safeGet(state.ticket, `bets`) || []);
    },
    [getters.GET_TICKET_AVAILABLE_MODES](state) {
      return (safeGet(state.ticket, `availableMode`) || [])
        .filter((mode) => TICKET_AVAILABLE_MODES.includes(mode));
    },
    [getters.GET_TICKET_ITEMS](state, moduleGetters, rootState, rootGetters) {
      const marketRowGetter = rootGetters[`LiveStore/${marketStoreGetters.GET_ODDS_BY_MARKET_ROW_ID}`];
      const items = [...(safeGet(state.ticket, `items`) || [])];

      return items.map((item) => ({ ...setItemStatusFromBettingOffer(item, marketRowGetter) }));
    },
    [getters.GET_TICKET_ITEM_FOR_MARKET](state, moduleGetters) {
      return (marketId, oddsId) => moduleGetters[getters.GET_TICKET_ITEMS]
        .find((ticketItem) => {
          if (oddsId) {
            return ticketItem.selectedOdds.id === oddsId;
          }

          return ticketItem.marketId === marketId;
        });
    },
    [getters.IS_ODDS_OUTDATED](state, moduleGetters) {
      return moduleGetters[getters.GET_TICKET_ITEMS]
        .some((item) => item.status !== TICKET_ITEM_STATUS_EQUAL);
    },
    [getters.GET_TICKET_ITEMS_COUNT](state) {
      return (safeGet(state.ticket, `items`) || []).length;
    },
    [getters.IS_EMPTY](state) {
      return (safeGet(state.ticket, `items`) || []).length === 0;
    },
    [getters.GET_TICKET_TOTAL_BET](state) {
      const totalBet = safeGet(state.ticket, `totalBet`);

      return typeof totalBet === `number` ? totalBet : null;
    },
    [getters.GET_TICKET_TOTAL_TAKING](state) {
      const totalTaking = safeGet(state.ticket, `totalTaking`);

      return typeof totalTaking === `number` ? totalTaking : null;
    },
    [getters.GET_TICKET_TOTAL_PAY](state) {
      const totalPay = safeGet(state.ticket, `totalPay`);

      return typeof totalPay === `number` ? totalPay : undefined;
    },
    [getters.GET_TICKET_TOTAL_ODDS](state, moduleGetters, rootState, rootGetters) {
      if (moduleGetters[getters.IS_ODDS_OUTDATED]) {
        if (moduleGetters[getters.GET_TICKET_MODE] === TICKET_MODE_SYSTEM) {
          const totalPay = moduleGetters[getters.GET_TICKET_TOTAL_PAY];

          return totalPay === 0 ? 0 : moduleGetters[getters.GET_TICKET_TOTAL_WIN] / totalPay;
        }
        const marketRowGetter = rootGetters[`LiveStore/${marketStoreGetters.GET_ODDS_BY_MARKET_ROW_ID}`];

        return calculateOdds(state, marketRowGetter);
      }

      const totalOdds = safeGet(state, `ticket.totalOdds`);

      return typeof totalOdds === `number` ? totalOdds : null;
    },
    /**
     * Holds total odds value from KOM ticket. This value is unaffected
     * by odds changes which may happen in the meantime.
     *
     * @param state
     * @returns {*}
     */
    [getters.ACCEPTED_TOTAL_ODDS](state) {
      return (safeGet(state.ticket, `totalOdds`));
    },
    [getters.GET_TICKET_TOTAL_WIN](state, moduleGetters, rootState, rootGetters) {
      if (moduleGetters[getters.IS_ODDS_OUTDATED]) {
        const marketRowGetter = rootGetters[`LiveStore/${marketStoreGetters.GET_ODDS_BY_MARKET_ROW_ID}`];

        const totalBet = safeGet(state.ticket, `totalBet`);
        const totalOdds = calculateOdds(state, marketRowGetter);

        if (moduleGetters[getters.GET_TICKET_MODE] === TICKET_MODE_SYSTEM) {
          return realtimePrizeCalculation(
            getOddsArray(state, marketRowGetter, TicketGroup.A),
            getFixOddsValue(state, marketRowGetter, TicketGroup.B),
            moduleGetters[getters.GET_TICKET_TOTAL_PAY],
          );
        }
        return totalBet * toFixed(totalOdds);
      }

      const totalWin = safeGet(state.ticket, `totalWin`);

      return typeof totalWin === `number` ? totalWin : null;
    },
    [getters.CURRENCY_CODE](state) {
      return (safeGet(state.ticket, `currency`));
    },
    [getters.CURRENCY_SYMBOL](state) {
      const currencyCode = (safeGet(state.ticket, `currency`));

      if (!currencyCode) {
        return () => ``;
      }
      return (amount = 0) => getCurrencySymbol(currencyCode, amount);
    },
    [getters.GET_TICKET_MESSAGES](state) {
      return state.messages || [];
    },
    [getters.GET_TICKET_HANDLING_CHARGE](state) {
      return safeGet(state.ticket, `handlingCharge`, null);
    },
    [getters.GET_TICKET_HANDLING_CHARGE_RATIO](state) {
      return safeGet(state.ticket, `handlingChargeRatio`, 0);
    },
    [getters.GET_TICKET_HANDLING_CHARGE_PERCENTAGE](state, allGetters) {
      const ratio = allGetters[getters.GET_TICKET_HANDLING_CHARGE_RATIO];

      return 100 * ratio;
    },
    [getters.GET_TICKET_HANDLING_CHARGE_TYPE](state) {
      return safeGet(state.ticket, `handlingChargeType`) || null;
    },
    [getters.GET_PAYMENT_KIND](state) {
      return safeGet(state.ticket, `paymentType`, PaymentKind.CREDIT);
    },
    [getters.GET_CHAMPIONSHIP](state) {
      return safeGet(state.ticket, `championship`, false);
    },
    [getters.GET_CHAMPIONSHIP_ACTIVE](state) {
      return safeGet(state.ticket, `championshipActive`, false);
    },
    [getters.GET_POINTS_FOR_BET](state) {
      return safeGet(state.ticket, `pointsForBet`, null);
    },
    [getters.GET_TOTAL_PAY_IN_POINTS](state) {
      return safeGet(state.ticket, `totalPayInPoints`, null);
    },
    [getters.IS_ODDS_ON_TICKET](state) {
      return (oddsId) => {
        const items = safeGet(state.ticket, `items`) || [];

        return items.some((item) => item.selectedOdds.oddsId === oddsId);
      };
    },
    [getters.GET_TICKET_ODDS](state) {
      const items = safeGet(state.ticket, `items`) || [];

      return items.map(({ selectedOdds, marketId }) => ({ ...selectedOdds, marketId }));
    },
    [getters.IS_MARKET_ON_TICKET](state, moduleGetters, rootState, rootGetters) {
      return (marketId) => {
        const selectedMarkets = rootGetters[`LiveStore/${marketStoreGetters.GET_SELECTED_MARKETS}`];

        const market = rootGetters[`LiveStore/${marketStoreGetters.GET_MARKET_BY_ID}`](marketId);

        if (!selectedMarkets || !market) return false;
        return selectedMarkets.some((item) => market.marketRows.some((marketRowId) => item.marketId === marketRowId));
      };
    },
    [getters.GET_TICKET_ITEM_FOR_MARKET_ROW_ID](state, moduleGetters, rootState, rootGetters) {
      return (marketMappingId) => {
        const items = moduleGetters[getters.GET_TICKET_ITEMS];
        const market = rootGetters[`LiveStore/${marketStoreGetters.GET_MARKET_BY_ID}`](marketMappingId);

        if (!items || !market) return {};
        return items.find((item) => market.marketRows.find((marketRowId) => item.marketId === marketRowId));
      };
    },
    [getters.IS_SUBMISSION_IN_PROGRESS](state) {
      return state.submission.inProgress;
    },
    [getters.TICKET_SUBMISSION_SUCCESS](state) {
      const { submission, messages: [message] = [] } = state;

      return !submission.inProgress && !submission.isError && (message && message.ticketSubmitted);
    },
    [getters.TICKET_CHANGES_MUST_BE_ACCEPTED](state, moduleGetters) {
      const ticketItems = moduleGetters[getters.GET_TICKET_ITEMS];
      const ticketContainsInactiveItems = ticketItems
        .some((ticketItem) => ticketItem.status === TICKET_ITEM_STATUS_INACTIVE);

      if (ticketContainsInactiveItems) {
        // inactive items must be explicitly removed from ticket state (in FTN backend)
        // by calling "acceptChanges" endpoint
        return true;
      }

      if (state.changesHandlingType === ChangesHandlingType.NONE) {
        // user doesn't accept any change => every change must be explicitly accepted
        return moduleGetters[getters.IS_ODDS_OUTDATED];
      }

      if (state.changesHandlingType === ChangesHandlingType.IGNORE) {
        // user accepts all change => there's no need to accept changes
        return false;
      }

      if (state.changesHandlingType === ChangesHandlingType.UPWARD) {
        // user is ok if value is higher or equal => only lower odds values must
        // be explicitly accepted
        return ticketItems
          .some((ticketItem) => ticketItem.status === TICKET_ITEM_STATUS_LOWER);
      }

      return false;
    },
  },
};

export default ticketStore;
