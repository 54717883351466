import axios from 'axios';

export const img = axios.create({
  timeout: 15000,
});

export function setImgUrl(imgUrl) {
  // eslint-disable-next-line no-undef
  if (NODE_ENV !== `production`) {
    if (!imgUrl) {
      // eslint-disable-next-line no-console
      console.error(`'imgUrl' is empty. Check FE configuration.`);
    }
  }
  img.defaults.baseURL = imgUrl;
}

export default img;
