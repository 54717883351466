import {
  actionNames as favoriteMatchesStoreActions,
} from '../../stores/favorites/FavoriteMatchesStore';
import store from '../../config/store';
import * as timeStoreGetters from '../../stores/TimeStore/getters';

const LOCAL_STORAGE_KEY = `favorite_matches`;

/**
 * @typedef {string} FavoriteMatch
 * @type {number}
 */

/**
 * Saves matches to local Storage
 * @param {Object.<string, FavoriteMatch>} favoriteMatches
 */
function saveToLocalStorage(favoriteMatches = {}) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(favoriteMatches));
}

/**
 * Loads favorite matches to local storage
 * @param sanitize {Boolean}
 * @returns {Object.<string, FavoriteMatch>}
 */
function loadFromLocalStorage(sanitize = false) {
  let favoriteMatches = {};

  try {
    favoriteMatches = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || `{}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to load favorite matches`, e);
  }
  if (typeof favoriteMatches !== `object`) {
    // eslint-disable-next-line no-console
    console.warn(`Favorite matches are stored in invalid format.`);
    favoriteMatches = {};
  }
  if (sanitize) {
    const TZDate = store.getters[`TimeStore/${timeStoreGetters.TZDATE}`]();
    const thirtyDaysAgo = TZDate.getTime() - (30 * 24 * 60 * 60 * 1000);

    favoriteMatches = Object.keys(favoriteMatches)
      .reduce((favorites, matchId) => {
        const matchStart = favoriteMatches[matchId];

        if (typeof matchStart === `number` && matchStart > thirtyDaysAgo) {
          favorites[matchId] = matchStart;
        }
        return favorites;
      }, {});
    saveToLocalStorage(favoriteMatches);
  }
  return favoriteMatches;
}

export function loadFavoriteMatches(favoriteMatchesProvider = () => loadFromLocalStorage(true)) {
  const favorites = Object.keys(favoriteMatchesProvider())
    .reduce((byId, id) => {
      byId[id] = true;
      return byId;
    }, {});

  store.dispatch(`FavoriteMatchesStore/${favoriteMatchesStoreActions.SET_FAVORITE_MATCHES}`,
    favorites);
}

/**
 * Adds a match to local storage
 * @param matchId {Object}
 */

export function addMatchToFavorites(matchId) {
  store.dispatch(`FavoriteMatchesStore/${favoriteMatchesStoreActions.ADD_MATCH}`, matchId);
  const TZDate = store.getters[`TimeStore/${timeStoreGetters.TZDATE}`]();

  const newFavorites = {
    ...loadFromLocalStorage(),
    [matchId]: TZDate.valueOf(),
  };

  saveToLocalStorage(newFavorites);
}

export function removeMatchFromFavorites(matchId) {
  store.dispatch(`FavoriteMatchesStore/${favoriteMatchesStoreActions.REMOVE_MATCH}`, matchId);

  const newFavorites = loadFromLocalStorage();

  delete newFavorites[matchId];
  saveToLocalStorage(newFavorites);
}
