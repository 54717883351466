import Vue from 'vue';
import Vuex from 'vuex';
import AppConfigurationStore from '@/stores/AppConfigurationStore';
import BootstrapStore from '../stores/bootstrap/BootstrapStore';
import FavoriteMarketsStore from '../stores/favorites/FavoriteMarketsStore';
import FavoriteMatchesStore from '../stores/favorites/FavoriteMatchesStore';
import GraphicsStore from '../stores/ui/GraphicsStore';
import TabMenuStore from '../stores/ui/TabMenuStore';
import LiveSportMenuStore from '../stores/ui/LiveSportMenuStore';
import LiveStore from '../stores/LiveStore';
import ChatStore from '../stores/ChatStore';
import TicketStore from '../stores/ticket/TicketStore';
import UserStore from '../stores/UserStore';
import StreamStore from '../stores/StreamStore';
import EarlyCashoutConfigStore from '../stores/ticket/EarlyCashoutConfigStore';
import WebMessageStore from '../stores/WebMessageStore';
import TimeStore from '../stores/TimeStore';
import WebsocketStore from '../stores/WebsocketStore/index';
import StreamAuthorizationStore from '../stores/StreamAuthorizationStore';

Vue.use(Vuex);

export const modules = {
  AppConfigurationStore,
  BootstrapStore,
  FavoriteMarketsStore,
  FavoriteMatchesStore,
  GraphicsStore,
  TabMenuStore,
  LiveStore,
  ChatStore,
  TicketStore,
  EarlyCashoutConfigStore,
  UserStore,
  StreamStore,
  LiveSportMenuStore,
  WebMessageStore,
  TimeStore,
  WebsocketStore,
  StreamAuthorizationStore,
};

export default new Vuex.Store({
  modules,
  state: {},
});
