export const loggerSeverity = {
  DEBUG: `DEBUG`,
  INFO: `INFO`,
  WARNING: `WARNING`,
  ERROR: `ERROR`,
};

export const loggerEventTypes = {
  SUCCESS: `SUCCESS`,
  FAIL: `FAIL`,
  INCOMPLETE: `INCOMPLETE`,
};

export const loggerCustomEvents = {
  BETSLIP_STAKE_CHANGE: `betslipStakeChange`,
  USER_LOGIN: `userLogin`,
  USER_LOGIN_FAILED: `userLoginFailed`,
  HANDLING_TYPE_CHANGE: `handlingTypeChange`,
};

export const loggerElements = {
  BETSLIP_STAKE_SLIDER: `BETSLIP_STAKE_SLIDER`,
  BETSLIP_QUICK_VALUE: `BETSLIP_QUICK_VALUE`,
  BETSLIP_INPUT_STAKE: `BETSLIP_INPUT_STAKE`,
};

export const loggerErrors = {
  USER_NOT_LOGGED: `User is not logged`,
};
