import { parseDateStringToUtc } from '../globals/utils/date';
import store from '../config/store';
import * as timeStoreMutations from '../stores/TimeStore/mutationTypes';
import * as timeStoreGetters from '../stores/TimeStore/getters';
import * as timeStoreActions from '../stores/TimeStore/actions';
import { MINUTE_MS } from '../globals/enums/enums';

let serverClockInterval;
let sinceLastLoginInterval;

/**
 * Parse date with local time zone from ISO date string
 * @param {String} str (ISO)
 * @returns {TZDate|null}
 */
export function parseDate(str) {
  const epochMillis = parseDateStringToUtc(str);

  if (epochMillis === null) return null;
  return store.getters[`TimeStore/${timeStoreGetters.TZDATE}`](epochMillis);
}

export function setServerTime({ datetime, zoneId, offsetSeconds }) {
  store.dispatch(`TimeStore/${timeStoreActions.SERVER_TIME_ACTION}`, {
    serverTime: datetime,
    offsetMinutes: offsetSeconds / 60,
    zone: zoneId,
  });
}

export function startServerClock() {
  let timeUpdatedFromServer = true;
  let serverClock = store.getters[`TimeStore/${timeStoreGetters.TZDATE}`]();
  const MS_TO_WHOLE_MINUTE = (60 - serverClock.getSeconds()) * 1000;

  clearInterval(serverClockInterval);
  store.commit(`TimeStore/${timeStoreMutations.SET_SERVER_CLOCK}`, serverClock);

  /**
   * setTimeout to wait with inner interval loop to start updating on whole minute tick
   */
  setTimeout(() => {
    serverClock = new Date(serverClock.getTime() + MINUTE_MS);
    store.commit(`TimeStore/${timeStoreMutations.SET_SERVER_CLOCK}`, serverClock);

    serverClockInterval = setInterval(() => {
      if (!timeUpdatedFromServer) {
        serverClock = new store.getters[`TimeStore/${timeStoreGetters.TZDATE}`]();
        timeUpdatedFromServer = true;
      } else {
        timeUpdatedFromServer = false;
      }
      store.commit(`TimeStore/${timeStoreMutations.SET_SERVER_CLOCK}`, serverClock);
    }, MINUTE_MS);
  }, MS_TO_WHOLE_MINUTE);
}

export function initLastLoginTimer(lastLoginInstant) {
  const TZDate = store.getters[`TimeStore/${timeStoreGetters.TZDATE}`]();
  const now = TZDate.getTime();
  let millisecondsSinceLastLogin = Math.max(now - lastLoginInstant, 0);
  const SECOND_MS = 1000;

  clearInterval(sinceLastLoginInterval);

  function increaseTimeSinceLastLogin() {
    millisecondsSinceLastLogin += SECOND_MS;
    store.commit(`TimeStore/${timeStoreMutations.SET_TIME_SINCE_LAST_LOGIN}`, millisecondsSinceLastLogin);
  }
  sinceLastLoginInterval = setInterval(() => {
    increaseTimeSinceLastLogin();
  }, SECOND_MS);
}
