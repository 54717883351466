<template>
  <Icon
    :class="rootClasses"
    :title="isFavorite ? removeTooltip : addTooltip"
    :size="size"
    name="star"
    @click.prevent="isFavorite ? removeHandler() : addHandler()"
  />
</template>

<script>
  import Icon from '../Icon/Icon';

  export default {
    name: `FavoritesIcon`,
    components: {
      Icon,
    },
    props: {
      isFavorite: {
        type: Boolean,
        required: true,
      },
      addHandler: {
        type: Function,
        required: true,
      },
      addTooltip: {
        type: String,
        required: true,
      },
      removeHandler: {
        type: Function,
        required: true,
      },
      removeTooltip: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        required: false,
        default: `auto`,
      },
    },
    computed: {
      rootClasses() {
        return {
          favorites_icon: true,
          'favorites_icon--favorite': this.isFavorite,
        };
      },
    },
    methods: {},
  };

</script>

<style lang="scss">
  $base_class: '.favorites_icon';

  #{$base_class} {
    color: var(--color-icon);
    cursor: pointer;

    &:hover {
      transition: color .1s linear;
      @if ($theme == 'light') {
        color: var(--color-primary-text);
      } @else {
        color: var(--color-white);
      }
    }

    &--favorite {
      color: var(--color-secondary);

      &:hover {
        @if ($theme == 'light') {
          color: var(--color-background);
        } @else {
          color: var(--color-white);
        }
      }
    }
  }

</style>
