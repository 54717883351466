<template>
  <div class="sport">
    <div
      v-for="sport in favoriteSports"
      :key="sport.id"
    >
      <SportHeader
        :sportId="sport.id"
        :marketsVisible.sync="marketsVisibleOnMatchDetail"
        :isAllLeaguesOpen="true"
        :name="sport.names"
        :matchCount="1"
        :sportType="sport.sport"
        :collapseState="isCollapsed(sport)"
        :short="short"
        :extended="true"
        :hideLeagueCollapse="true"
        @toggleSport="toggleSport(sport)"
      />
      <div>
        <HeightTransitionUtil>
          <div v-if="!isCollapsed(sport)">
            <FavoriteLeague
              :sportId="sport.id"
              :sportType="sport.sport"
              :short="short"
              collapsingCategory="favorites"
            />
          </div>
        </HeightTransitionUtil>
      </div>
    </div>
    <NoMatchInViewMessage
      v-if="favoriteSports.length === 0"
      key="no-favorite-sport"
      sportId="FAVORITES"
      :removeReasonAction="() => { $router.push('/') }"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import {
    getters as liveSportStoreGetters,
    actions as liveSportActions,
  } from '@/stores/LiveSportStore';
  import { TOGGLE_LEAGUES_BY_SPORT } from '@/stores/LiveLeagueStore/constants';

  import NoMatchInViewMessage from '@/globals/components/NoMatchInViewMessage/NoMatchInViewMessage';
  import HeightTransitionUtil from '@/globals/components/HeightTransitionUtil/HeightTransitionUtil';
  import SportHeader from '../Sport/SportHeader';
  import FavoriteLeague from '../League/FavoriteLeague';

  export default {
    components: {
      HeightTransitionUtil,
      SportHeader,
      FavoriteLeague,
      NoMatchInViewMessage,
    },
    props: {
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        marketsVisibleOnMatchDetail: true,
      };
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        allSportsWithFavorites: liveSportStoreGetters.SPORTS_GET_IF_HAS_FAVORITE,
        isSportCollapsed: liveSportStoreGetters.GET_SPORT_COLLAPSE_STATE,
      }),
      favoriteSports() {
        return Object.values(this.allSportsWithFavorites);
      },
      isCollapsed() {
        return ({ id }) => this.isSportCollapsed({ id, category: `favorites` });
      },
    },
    methods: {
      ...mapActions(`LiveStore`, {
        toggleSportState: liveSportActions.SPORT_TOGGLE_OPEN_STATE,
        openLeaguesBySport: TOGGLE_LEAGUES_BY_SPORT,
      }),
      toggleSport({ id }) {
        this.toggleSportState({ id, category: `favorites` });
      },
      toggleLeagues() {
        if (this.isCollapsed) {
          this.toggleSport();
        }
        this.openLeaguesBySport(this.info);
      },
    },
  };
</script>
