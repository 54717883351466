import api from '@/config/api/live';
import { resolveEndpoint } from '@/config/constants/index';
import { getLocale } from '@/config/locale';
import { getSiteName } from '@/config/siteName';

export function fetchSeoConfig() {
  const url = window.location.href;

  return api.get(resolveEndpoint(`SEO_CONFIG`, { url }));
}

export async function getSeoUrlBy(endpointId, value) {
  try {
    const { data } = await api.get(resolveEndpoint(endpointId, { value }));

    return data.url;
  } catch (error) {
    return window.location.origin;
  }
}

export function defaultSeoConfig() {
  return {
    metaTitle: document.title,
    type: `website`,
    path: document.location.href,
    site_name: getSiteName(),
    locale: getLocale(),
  };
}
