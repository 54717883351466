<template>
  <div :class="classNames">
    <div
      :class="headerClassNames"
      @click="toggle"
    >
      <div class="collapsible_box__arrow_content">
        <slot
          :isCollapsed="isCollapsed"
          name="arrow"
        >
          <Icon
            v-once
            ref="arrow"
            size="xxxs"
            :style="arrowStyle"
            class="collapsible_box__arrow"
            name="accordion-open"
          />
        </slot>
      </div>
      <slot
        :isCollapsed="isCollapsed"
        name="header"
      />
    </div>
    <div
      ref="content"
      :class="contentClassNames"
      :style="contentStyle"
    >
      <slot
        :isCollapsed="isCollapsed"
        name="content"
      />
    </div>
  </div>
</template>

<script>
  import Velocity from 'velocity-animate';
  import Icon from '../Icon/Icon';

  const ANIMATION_DURATION_MILLIS = 150;
  const POSITION_LEFT = `left`;
  const POSITION_RIGHT = `right`;

  function arrowRotation(collapsed, position) {
    if (!collapsed) {
      return 0;
    }
    if (position === POSITION_LEFT) {
      return -90;
    }

    return 90;
  }

  export default {
    name: `CollapsibleBox`,
    components: {
      Icon,
    },
    props: {
      collapsed: {
        type: Boolean,
        required: false,
        default: false,
      },
      arrowStyle: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      arrowPosition: {
        type: String,
        required: false,
        default: POSITION_RIGHT,
      },
      fadeArrows: {
        type: Boolean,
        required: false,
        default: false,
      },
      headerClass: {
        type: String,
        required: false,
        default: ``,
      },
      contentClass: {
        type: String,
        required: false,
        default: ``,
      },
    },
    data() {
      const isCollapsed = this.collapsed;

      return {
        isCollapsed,
        contentStyle: {
          display: isCollapsed ? `none` : `block`,
        },
        isAnimating: false,
      };
    },
    computed: {
      classNames() {
        return {
          collapsible_box: true,
          'collapsible_box--collapsed': this.isCollapsed,
          'collapsible_box--arrow_right': this.arrowPosition === POSITION_RIGHT,
          'collapsible_box--arrow_left': this.arrowPosition === POSITION_LEFT,
          'collapsible_box--fade_arrows': this.fadeArrows,
        };
      },
      headerClassNames() {
        return {
          collapsible_box__header: true,
          [`${this.headerClass}`]: this.headerClass,
        };
      },
      contentClassNames() {
        return {
          collapsible_box__content: true,
          [`${this.contentClass}`]: this.contentClass,
        };
      },
    },
    methods: {
      toggle() {
        if (this.isAnimating) {
          return Promise.resolve();
        }
        this.isCollapsed = !this.isCollapsed;
        this.isAnimating = true;

        return this.animateTransition()
          .then(() => {
            this.isAnimating = false;
            this.$emit(this.isCollapsed ? `collapse` : `expand`);
          });
      },
      animateTransition() {
        const contentAnimation = Velocity(this.$refs.content,
                                          `slide${this.isCollapsed ? `Up` : `Down`}`,
                                          {
                                            duration: ANIMATION_DURATION_MILLIS,
                                          },
        );
        const arrowAnimation = Velocity(
          this.$refs.arrow,
          { rotateZ: `${arrowRotation(this.isCollapsed, this.arrowPosition)}deg` },
          { duration: ANIMATION_DURATION_MILLIS },
        );

        return Promise.all([contentAnimation, arrowAnimation]);
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.collapsible_box';

  $arrow_cell_width: 25px;

  #{$base_class} {
    &__header {
      position: relative;
      cursor: pointer;
      user-select: none;
      display: grid;
      grid-template-columns: $arrow_cell_width 1fr;
      align-items: center;
    }

    &__content {
      overflow: hidden;
    }

    &__arrow_content {
      display: flex;
      justify-content: center;
      align-items: center;

      #{$base_class}--arrow_left & {
        float: left;
      }

      #{$base_class}--arrow_right & {
        float: right;
      }
    }

    &__arrow {
      color: var(--color-primary);
      transform-origin: 50% 50%;
      line-height: 1;
      padding: 0 5px;

      #{$base_class}--collapsed & {
        transform: rotate(90deg);
      }

      #{$base_class}--collapsed#{$base_class}--arrow_left & {
        transform: rotate(-90deg);
      }
    }
  }
</style>
