import { TOP_BAR_MENU_CALENDAR, TOP_BAR_MENU_OVERVIEW } from '@/globals/enums/MenuTabs';
import { MENU_TYPE_TOP_BAR } from '@/globals/enums/MenuTypes';
import { actions as tabMenuStoreActions } from '@/stores/ui/TabMenuStore';
import Vue from 'vue';
import Router from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import { getters as sportStoreGetters } from '@/stores/LiveSportStore';
import { getters as matchStoreGetters } from '@/stores/LiveMatchStore';
import { FortunaSite as site } from '@/globals/enums/enums';
import { gtmService } from '@/services/GTMService/GTMService';
import { getSeoUrlBy } from '@/services/SeoService';
import SportList from '@/globals/components/SportList/SportList';

import NotFound from '../globals/components/NotFound/NotFound';
import {
  ROUTE_CALENDAR,
  ROUTE_ERROR_404,
  ROUTE_OVERVIEW,
  ROUTE_MATCH_DETAIL,
  ROUTE_OVERVIEW_BY_SPORT,
  ROUTE_OVERVIEW_BY_SPORT_AND_LEAGUE,
  ROUTE_SEO_URL_BY_FIXTURE_ID,
} from './router/routes';
import store from './store';
import { getters as bootstrapStoreGetters } from '../stores/bootstrap/BootstrapStore';

import constants, { SITE } from './constants';

export const SPORT_OVERVIEW = `SPORT_OVERVIEW`;
export const OVERVIEW = `OVERVIEW`;
export const MATCH_DETAIL = `MATCH_DETAIL`;
export const CALENDAR = `CALENDAR`;

export const localizedRoutes = {
  [SPORT_OVERVIEW]: {
    [site.CZ]: `sport`,
    [site.SK]: `sport`,
    [site.PL]: `sport`,
    [site.RO]: `sport`,
    [site.CP]: `sport`,
    [site.HR]: `sport`,
  }[SITE],
  [OVERVIEW]: {
    [site.CZ]: `prehled`,
    [site.SK]: `prehlad`,
    [site.PL]: `przegląd`,
    [site.RO]: `overview`,
    [site.CP]: `overview`,
    [site.HR]: `pregled`,
  }[SITE],
  [MATCH_DETAIL]: {
    [site.CZ]: `zapas`,
    [site.SK]: `zapas`,
    [site.PL]: `mecz`,
    [site.RO]: `meci`,
    [site.CP]: `meci`,
    [site.HR]: `utakmica`,
  }[SITE],
  [CALENDAR]: {
    [site.CZ]: `kalendar`,
    [site.SK]: `kalendar`,
    [site.PL]: `kalendarz`,
    [site.RO]: `calendar`,
    [site.CP]: `calendar`,
    [site.HR]: `kalendar`,
  }[SITE],
};

Vue.use(Router);

const router = new Router({
  mode: `history`,
  routes: [
    {
      path: `/`,
      name: ROUTE_OVERVIEW,
      components: {
        left: SportList,
        content: null,
      },
      props: {
        left: {
          sportId: ``,
          leagueId: ``,
        },
      },
    },
    {
      path: `/${localizedRoutes.SPORT_OVERVIEW}/:sportId/:leagueId?`,
      name: ROUTE_OVERVIEW_BY_SPORT_AND_LEAGUE,
      components: {
        left: SportList,
        content: null,
      },
      props: {
        left: (route) => ({
          sportId: route.params.sportId,
          leagueId: route.params.leagueId,
        }),
      },
    },
    {
      path: `/${localizedRoutes.MATCH_DETAIL}/:sportId?/:leagueId?/:matchId`,
      name: ROUTE_MATCH_DETAIL,
      components: {
        left: SportList,
        content: () => import(/* webpackChunkName: "MatchDetailContainer" */ `@/routes/MatchDetail/MatchDetailContainer`),
      },
      props: {
        left: (route) => ({
          sportId: route.params.sportId || ``,
          leagueId: route.params.leagueId,
          short: true,
        }),
        content: (route) => ({
          sportId: route.params.sportId,
          matchId: route.params.matchId,
        }),
      },
    },
    {
      path: `/external/event/by-fixture-id/:fixtureId`,
      name: ROUTE_SEO_URL_BY_FIXTURE_ID,
    },
    {
      path: `*`,
      name: ROUTE_ERROR_404,
      component: NotFound,
    },
  ],
});

const changeTopTab = (
  index,
  name = MENU_TYPE_TOP_BAR,
) => Vue.nextTick(() => store.dispatch(`TabMenuStore/${tabMenuStoreActions.CHANGE_ACTIVE_TAB}`, {
  index,
  name,
}));

router.afterEach((to) => {
  gtmService.pageInformation({ router });
  // eslint-disable-next-line default-case
  switch (to.name) {
    case ROUTE_OVERVIEW:
    case ROUTE_OVERVIEW_BY_SPORT:
    case ROUTE_MATCH_DETAIL:
      changeTopTab(TOP_BAR_MENU_OVERVIEW);
      break;
    case ROUTE_CALENDAR:
      changeTopTab(TOP_BAR_MENU_CALENDAR);
      break;
  }
});

router.beforeEach((to, from, next) => {
  const containsRoutes = to.matched.length > 0;
  const routeName = to.name;

  const globalQuery = isEmpty(from.query) ? `` : from.query;

  function confirmRouteOrRedirect(routeParamName, storeGetterName, redirectTo) {
    const checkParam = to.params[routeParamName];
    const checkVar = store.getters[storeGetterName](checkParam);

    if (checkVar) {
      next();
    } else {
      next({ ...redirectTo, query: globalQuery });
    }
  }

  const redirect = (redirectUrl) => {
    const url = new URL(redirectUrl);

    if (window.location.origin === url.origin) {
      router.push(url.pathname);
    } else {
      window.location.href = redirectUrl;
    }
  };

  function checkRoute() {
    if (containsRoutes) {
      switch (routeName) {
        // eslint-disable-next-line no-case-declarations
        case ROUTE_OVERVIEW_BY_SPORT:
          if (to.params.sportId === constants.FAVORITES_SPORT_ID) {
            next();
          } else {
            confirmRouteOrRedirect(
              `sportId`,
              `LiveStore/${sportStoreGetters.SPORT_CONTAINS_MATCHES}`,
              { name: ROUTE_OVERVIEW },
            );
          }
          break;
        case ROUTE_MATCH_DETAIL:
          confirmRouteOrRedirect(
            `matchId`,
            `LiveStore/${matchStoreGetters.GET_MATCH_BY_ID}`,
            { name: ROUTE_OVERVIEW },
          );
          break;
        case ROUTE_SEO_URL_BY_FIXTURE_ID:
          getSeoUrlBy(`SEO_URL_BY_FIXTURE_ID`, to.params.fixtureId)
            .then((url) => redirect(url));
          break;
        default:
          next();
          break;
      }
    }
  }

  const bootstrapRunning = store.getters[`BootstrapStore/${bootstrapStoreGetters.GET_IS_RUNNING}`];

  if (bootstrapRunning()) {
    const unwatch = store.watch(bootstrapRunning, () => {
      unwatch();
      if (!store.getters[`BootstrapStore/${bootstrapStoreGetters.GET_IS_ERROR}`]()) {
        checkRoute();
      }
    });
  } else {
    checkRoute();
  }
});

export default router;
