import { APP_BOOTSTRAP_DONE } from '@/globals/enums/eventBus/App.enums';
import Lifecycle from '@/services/LifecycleHooks';

export const types = {
  INIT: `BOOTSTRAP_INIT`,
  DONE: `BOOTSTRAP_DONE`,
  ERROR: `BOOTSTRAP_ERROR`,
};

export const actions = {
  INIT: `bootstrapInit`,
  DONE: `bootstrapDone`,
  ERROR: `bootstrapError`,
};

export const getters = {
  GET_IS_RUNNING: `bootstrapGetIsRunning`,
  GET_IS_ERROR: `bootstrapGetIsError`,
};

const bootstrapStore = {
  namespaced: true,
  state: {
    isRunning: true,
    isError: false,
    error: null,
  },
  mutations: {
    [types.INIT](state) {
      state.isRunning = true;
      state.isError = false;
      state.error = false;
    },
    [types.DONE](state) {
      state.isRunning = false;
      state.isError = false;
      state.error = null;
      Lifecycle.$emit(APP_BOOTSTRAP_DONE);
    },
    [types.ERROR](state, error) {
      state.isRunning = false;
      state.isError = true;
      state.error = error;
    },
  },
  actions: {
    [actions.INIT]({ commit }) {
      commit(types.INIT);
    },
    [actions.DONE]({ commit }) {
      commit(types.DONE);
    },
    [actions.ERROR]({ commit }, data) {
      commit(types.ERROR, data);
    },
  },
  getters: {
    [getters.GET_IS_RUNNING](state) {
      return () => state.isRunning;
    },
    [getters.GET_IS_ERROR](state) {
      return () => state.isError;
    },
  },
};

export default bootstrapStore;
