import store from '@/config/store';
import { IS_FEATURE_SUPPORTED } from '@/stores/AppConfigurationStore/constants';
import { FEATURE_CB_FULL_SOLUTION, FEATURE_CB_FULL_SOLUTION_EMERGENCY_STOP } from '@/config/constants/features';
import { getCookieByName } from '@/globals/utils/cookies.utils';
import { COOKIE_CB_SHOW } from '@/config/constants/cookies';
import { getters as ticketStoreGetters } from "@/stores/ticket/TicketStore";
import api from "@/config/api/live";
import { resolveLiveTicketEndpoint } from "@/config/constants";
import Cookies from "js-cookie";

const wasCookieEnabledAtStartup = !!getCookieByName(COOKIE_CB_SHOW);

const cbKillSwitch = () => store.getters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](FEATURE_CB_FULL_SOLUTION_EMERGENCY_STOP);

export const isCbEnabled = () => {
  const isToggleEnabled = store.getters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](FEATURE_CB_FULL_SOLUTION);

  return !cbKillSwitch() && (wasCookieEnabledAtStartup || isToggleEnabled || store.state.UserStore.cbEnabled);
};

export const refreshCbEnabled = () => {
  const cbShouldBeEnabled = !cbKillSwitch() && store.state.UserStore.cbEnabled;

  if (!wasCookieEnabledAtStartup && cbShouldBeEnabled) {
    const { items } = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET}`];

    if (items?.length > 0) {
      // old Kom ticket is not empty - try to migrate it to P4 betslip
      api.post(resolveLiveTicketEndpoint(`TICKET_SAVE`))
        .then(({ data }) => {
          const cookieValue = {
            betslipId: data.betslipId,
            identifier: data.identifier,
            source: `BETSLIP_TEMPLATE`,
            kind: `LIVE`,
            domain: document.domain,
          };

          Cookies.set(`prepared_betslip`, cookieValue, { domain: cookieValue.domain, path: `/` });
        })
        .finally(() => window.location.reload());
    } else {
      // Cookie has been already set (by /api/user/info endpoint). We need to just reload the page.
      window.location.reload();
    }
  }
};
