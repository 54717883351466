import { BY_DISPLAY_ORDER } from '@/globals/sportdata/comparators';
import Vue from 'vue';
import { SportSortsFiltersType } from '@/globals/enums/enums';
import { OFFER_FILTER_TYPE_ALL } from '@/globals/components/OfferFilter/Definitions';
import constants from '../config/constants';

export const $DELETE_SPORT = `$DELETE_SPORT`;
export const $LINK_LEAGUES_TO_SPORT = `$LINK_LEAGUES_TO_SPORT`;
export const $UNLINK_LEAGUE_FROM_SPORT = `$UNLINK_LEAGUE_FROM_SPORT`;
export const $ADD_SPORT_LIST = `$ADD_SPORT_LIST`;
export const $UPDATE_SPORT_FILTER = `$UPDATE_SPORT_FILTER`;
export const $TOGGLE_COLLAPSE_STATE = `$TOGGLE_COLLAPSE_STATE`;
export const $SET_FAVORITE_COLLAPSE_STATE = `$SET_FAVORITE_COLLAPSE_STATE`;

export const actions = {
  ADD_SPORT_LIST: `addSportList`,
  RESET_SPORT_FILTERS: `resetSportFilters`,
  SPORT_TOGGLE_OPEN_STATE: `sportToggleOpenState`,
  LOAD_USER_SETTINGS: `loadUseSettings`,
};

export const getters = {
  GET_SPORT_BY_ID: `getSportById`,
  GET_FILTERED_SPORT_BY_ID: `getFilteredSportById`,
  GET_MATCH_COUNT_FOR_SPORT: `getMatchCountForSport`,
  SPORT_CONTAINS_MATCHES: `sportContainsMatches`,
  GET_SPORTS: `getSports`,
  GET_SPORT_FILTERS: `getSportFilters`,
  GET_SPORT_FILTER: `getSportFilter`,
  GET_SPORT_COLLAPSE_STATE: `getSportCollapseState`,
  GET_SPORT_FAVORITE_COLLAPSE_STATE: `getSportFavoriteCollapseState`,
  SPORTS_GET_ALL: `sportsGetAll`,
  SPORTS_GET_ALL_FILTERED: `sportsGetAllFiltered`,
  SPORTS_GET_IF_HAS_FAVORITE: `SPORTS_GET_IF_HAS_FAVORITE`,
  GET_SPORT_NAMES_BY_ID: `GET_SPORT_NAMES_BY_ID`,
};

const liveSportsStore = {
  state: {
    sports: {},
    filtersMap: {
      [SportSortsFiltersType.FILTER_HAS_STREAM]: false,
      [SportSortsFiltersType.FILTER_DURATION]: OFFER_FILTER_TYPE_ALL,
    },
    collapsed: {
      generic: {},
      favorites: {},
      touched: {},
    },
  },
  mutations: {
    [$DELETE_SPORT]: (state, { id }) => {
      Vue.delete(state.sports, id);
    },
    [$LINK_LEAGUES_TO_SPORT]: (state, leagues) => {
      Object
        .entries(leagues)
        .forEach(([leagueId, { sportId, displayOrder }]) => {
          const sport = state.sports[sportId];
          const linkedAlready = sport?.leagues?.includes(leagueId);

          if (linkedAlready === false) {
            sport.leagues.splice(displayOrder, 0, leagueId);
          }
        });
    },
    [$UNLINK_LEAGUE_FROM_SPORT]: (state, { sportId, leagueId }) => {
      const sport = state.sports[sportId];

      sport.leagues = sport.leagues.filter((id) => id !== leagueId);
    },
    [$ADD_SPORT_LIST]: (state, sports) => {
      Object
        .values(sports)
        .map((sport) => Vue.set(state.sports, sport.id, sport));
    },
    [$UPDATE_SPORT_FILTER]: (state, { filterType, value }) => {
      Vue.set(state.filtersMap, filterType, value);
    },
    [$TOGGLE_COLLAPSE_STATE]: (state, { id, category, toggleState = undefined }) => {
      let value;

      if (toggleState !== undefined) {
        value = toggleState;
      } else {
        value = !(state.collapsed[category][id] ?? true);
      }

      Vue.set(state.collapsed, category, { ...state.collapsed[category], [id]: value });
    },
  },
  actions: {
    [actions.ADD_SPORT_LIST]({ commit }, sports) {
      commit($ADD_SPORT_LIST, sports);
    },
    [actions.RESET_SPORT_FILTERS]({ commit }) {
      commit($UPDATE_SPORT_FILTER, {
        filterType: SportSortsFiltersType.FILTER_HAS_STREAM,
        value: false,
      });
      commit($UPDATE_SPORT_FILTER, {
        filterType: [SportSortsFiltersType.FILTER_DURATION],
        value: OFFER_FILTER_TYPE_ALL,
      });
    },
    [actions.SPORT_TOGGLE_OPEN_STATE]({ commit }, payload) {
      commit($TOGGLE_COLLAPSE_STATE, { ...payload, save: true });
    },
  },
  getters: {
    [getters.SPORTS_GET_IF_HAS_FAVORITE](state, moduleGetters) {
      const favoriteMatches = moduleGetters.getFavoriteMatches();

      return favoriteMatches.reduce((sports, match) => {
        if (match.sportId && !sports[match.sportId]) sports[match.sportId] = state.sports[match.sportId];
        return sports;
      }, {});
    },
    [getters.SPORTS_GET_ALL](state) {
      return Object
        .values(state.sports)
        .sort(BY_DISPLAY_ORDER);
    },
    [getters.SPORTS_GET_ALL_FILTERED](state, moduleGetters) {
      const filteredSports = Object
        .values(state.sports)
        .filter((sport) => moduleGetters.getFilteredMatchesBySportId(sport.id).length > 0)
        .sort(BY_DISPLAY_ORDER);

      return filteredSports;
    },
    [getters.GET_SPORT_BY_ID](state) {
      return (sportId) => state.sports[sportId] || null;
    },
    [getters.GET_MATCH_COUNT_FOR_SPORT](state, moduleGetters, store, rootGetters) {
      return (sportId) => {
        switch (sportId) {
          case constants.ALL_SPORT_ID:
            return moduleGetters.getFilteredMatchesBySportId(null).length;
          case constants.FAVORITES_SPORT_ID:
            return rootGetters[`LiveStore/getFavoriteMatchesCount`];
          default:
            return moduleGetters.getFilteredMatchesBySportId(sportId).length;
        }
      };
    },
    [getters.SPORT_CONTAINS_MATCHES](state, moduleGetters) {
      return (sportId) => moduleGetters[getters.GET_MATCH_COUNT_FOR_SPORT](sportId) > 0;
    },
    [getters.GET_SPORT_FILTERS](state) {
      return state.filtersMap || null;
    },
    [getters.GET_SPORT_FILTER](state, moduleGetters) {
      return (filterType) => moduleGetters[getters.GET_SPORT_FILTERS][filterType];
    },
    [getters.GET_SPORT_COLLAPSE_STATE]: ({ collapsed }) => ({ id, category }) => !!(collapsed[category][id] ?? true),
    [getters.GET_SPORT_FAVORITE_COLLAPSE_STATE]: ({ collapsed }) => (sportId) => !!collapsed.favorites[sportId],
    [getters.GET_SPORT_NAMES_BY_ID]: ({ sports }) => (sportId) => sports[sportId].names,
  },
};

export default liveSportsStore;
