import Vue from 'vue';

export const mutationNames = {
  ADD_MATCH: `ADD_MATCH`,
  REMOVE_MATCH: `REMOVE_MATCH`,
};

export const actionNames = {
  ADD_MATCH: `ADD_MATCH`,
  SET_FAVORITE_MATCHES: `SET_FAVORITE_MATCHES`,
  REMOVE_MATCH: `REMOVE_MATCH`,
};

export const getterNames = {
  IS_FAVORITE_MATCH: `isFavoriteMatch`,
  ALL_FAVORITE_IDS: `allFavoriteIds`,
};

const favoriteMatchesStore = {
  namespaced: true,
  state: {
    // [matchId] -> true
    favoriteMatchesById: {},
  },
  mutations: {
    [mutationNames.ADD_MATCH](state, id) {
      Vue.set(state.favoriteMatchesById, id, true);
    },
    [mutationNames.REMOVE_MATCH](state, id) {
      Vue.delete(state.favoriteMatchesById, id);
    },
  },
  actions: {
    [actionNames.ADD_MATCH]({ commit }, payload) {
      commit(mutationNames.ADD_MATCH, payload);
    },
    [actionNames.SET_FAVORITE_MATCHES]({ dispatch }, payload) {
      Object
        .keys(payload)
        .forEach((id) => dispatch(actionNames.ADD_MATCH, id));
    },
    [actionNames.REMOVE_MATCH]({ commit }, payload) {
      commit(mutationNames.REMOVE_MATCH, payload);
    },
  },
  getters: {
    [getterNames.IS_FAVORITE_MATCH](state) {
      return (matchId) => (state.favoriteMatchesById[matchId] === true);
    },
    [getterNames.ALL_FAVORITE_IDS](state) {
      return Object.keys(state.favoriteMatchesById);
    },
  },
};

export default favoriteMatchesStore;
