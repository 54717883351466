<template>
  <FavoritesIcon
    :class="{
      active: isFavorite,
      'switch-grid': !hasStreamIconVisible || !showGameTime,
    }"
    :isFavorite="isFavorite"
    :addHandler="addToFavorites"
    :addTooltip="$t('live3.favorite.matches.add')"
    :removeHandler="removeFromFavorites"
    :removeTooltip="$t('live3.favorite.matches.remove')"
    class="info-action--favourite"
  />
</template>

<script>
  import { mapGetters } from 'vuex';
  import {
    addMatchToFavorites,
    removeMatchFromFavorites,
  } from '@/services/favorites/FavoriteMatchesService';

  import { getterNames as favoriteMatchesStoreGetters } from '@/stores/favorites/FavoriteMatchesStore';

  import FavoritesIcon from '@/globals/components/favorites/FavoritesIcon';
  import { StreamType } from '@/globals/enums/enums';

  export default {
    name: `FavoritesButton`,
    components: {
      FavoritesIcon,
    },
    props: {
      stream: {
        type: Object,
        required: false,
        default: () => {},
      },
      matchId: {
        type: String,
        required: true,
      },
      matchTrackerType: {
        type: String,
        required: false,
        default: null,
      },
      showGameTime: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      ...mapGetters(`FavoriteMatchesStore`, {
        isFavoriteMatch: favoriteMatchesStoreGetters.IS_FAVORITE_MATCH,
      }),
      isFavorite() {
        return this.isFavoriteMatch(this.matchId);
      },
      hasStream() {
        return !!this.stream?.provider;
      },
      hasStreamIconVisible() {
        return this.hasStream || this.matchTrackerType === StreamType.IMG;
      },
    },
    methods: {
      addToFavorites() {
        addMatchToFavorites(this.matchId);
      },
      removeFromFavorites() {
        removeMatchFromFavorites(this.matchId);
      },
    },
  };

</script>

<style lang="scss">
  .info-action--favourite {
    opacity: 0;
    transition: opacity 0.15s;
    justify-self: center;
    grid-area: favorite;
    margin: 0 3px;

    @at-root .live-match:hover .info-action--favourite {
      opacity: 1;
    }
    &.active {
      color: var(--color-primary);
      opacity: 1;
    }

    &.switch-grid {
      grid-area: stream-tracker;
    }
  }
</style>
