import constants from '@/config/constants';
import { BY_DISPLAY_ORDER } from '@/globals/sportdata/comparators';
import { IS_FEATURE_SUPPORTED } from "@/stores/AppConfigurationStore/constants";
import { FEATURE_CB_LIVE } from '@/config/constants/features';

export const types = {
  SET_PICKED_SPORT_TYPE: `SET_PICKED_SPORT_TYPE`,
  SET_PICKED_LEAGUE: `SET_PICKED_LEAGUE`,
};

export const actions = {
  SET_PICKED_SPORT_TYPE: `setPickedSportType`,
};

export const getters = {
  IS_PICKED_SPORT_FAVORITES: `isPickedSportFavorites`,
  GET_MENU_DATA: `getMenuData`,
  GET_SUBMENU_DATA: `getSubmenuData`,
};

const liveSportMenuStore = {
  namespaced: true,
  state: {
    sportId: null,
    leagueId: null,
  },
  mutations: {
    [types.SET_PICKED_SPORT_TYPE](state, sportId) {
      state.sportId = sportId;
    },
    [types.SET_PICKED_LEAGUE](state, leagueId) {
      state.leagueId = leagueId;
    },
  },
  actions: {
    [actions.SET_PICKED_SPORT_TYPE]({ commit }, sportId) {
      commit(types.SET_PICKED_SPORT_TYPE, sportId);
    },
  },
  getters: {
    [getters.IS_PICKED_SPORT_FAVORITES](state) {
      return state.sportId === constants.FAVORITES_SPORT_ID;
    },
    [getters.GET_MENU_DATA](_state, _moduleGetters, rootState, rootGetters) {
      return Object
        .values(rootState.LiveStore.LiveSportStore.sports)
        .sort(BY_DISPLAY_ORDER)
        .map(({
          id,
          names,
          sport,
        }) => ({
          id,
          name: names,
          icon: sport,
          subItemsCount: rootGetters[`LiveStore/getFilteredMatchesBySportId`](id).length,
        }));
    },
    [getters.GET_SUBMENU_DATA](_state, _moduleGetters, rootState, rootGetters) {
      const getLeagueMatches = rootGetters[`LiveStore/getFilteredMatchesByLeague`];
      const cbLiveEnabled = rootGetters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](FEATURE_CB_LIVE);
      const hasLiveMatch = (matches) => matches
        .some((matchID) => !rootGetters[`LiveStore/getMatchById`](matchID).mirrorReflex);

      return (sportId) => Object
        .values(rootState.LiveStore.LiveLeagueStore.leagues)
        .filter((league) => league.sportId === sportId)
        .map((league) => ({
          id: league.id,
          live: cbLiveEnabled && hasLiveMatch(league.matches),
          name: league.names,
          isLive: cbLiveEnabled && hasLiveMatch(league.matches),
          matchesCount: getLeagueMatches(league.id, sportId).length,
        }))
        .filter(({ matchesCount }) => matchesCount);
    },
  },
};

export default liveSportMenuStore;
