import Vue from 'vue';

export const types = {
  CREATE_MINI_SCOREBOARD: `CREATE_MINI_SCOREBOARD`,
  UPDATE_MINI_SCOREBOARD: `UPDATE_MINI_SCOREBOARD`,
  REMOVE_MINI_SCOREBOARD: `REMOVE_MINI_SCOREBOARD`,
};

export const actions = {
  CREATE_MINI_SCOREBOARD: `createMiniScoreboard`,
  CREATE_MINI_SCOREBOARD_LIST: `createMiniScoreboardList`,
  UPDATE_MINI_SCOREBOARD: `updateMiniScoreboard`,
  REMOVE_MINI_SCOREBOARD: `removeMiniScoreboard`,
};
export const getters = {
  GET_MINI_SCOREBOARD_BY_MATCH_ID: `getMiniScoreboardByMatchId`,
};

const liveMiniScoreboardStore = {
  state: {
    miniScoreboards: {},
  },
  mutations: {
    [types.CREATE_MINI_SCOREBOARD](state, { scoreboard, id }) {
      Vue.set(state.miniScoreboards, id, scoreboard);
    },
    [types.UPDATE_MINI_SCOREBOARD](state, { scoreboard, id }) {
      Vue.set(state.miniScoreboards, id, scoreboard);
    },
    [types.REMOVE_MINI_SCOREBOARD](state, id) {
      Vue.delete(state.miniScoreboards, id);
    },
  },
  actions: {
    [actions.CREATE_MINI_SCOREBOARD]({ commit }, data) {
      commit(types.CREATE_MINI_SCOREBOARD, data);
    },
    [actions.CREATE_MINI_SCOREBOARD_LIST]({ commit }, data) {
      Object
        .entries(data)
        .forEach(([id, scoreboard]) => commit(types.CREATE_MINI_SCOREBOARD, { id, scoreboard }));
    },
    [actions.UPDATE_MINI_SCOREBOARD]({ commit }, data) {
      commit(types.UPDATE_MINI_SCOREBOARD, data);
    },
    [actions.REMOVE_MINI_SCOREBOARD]({ commit }, data) {
      commit(types.REMOVE_MINI_SCOREBOARD, data);
    },
  },
  getters: {
    [getters.GET_MINI_SCOREBOARD_BY_MATCH_ID](state) {
      return (matchId) => state.miniScoreboards[matchId] || {};
    },
  },
};

export default liveMiniScoreboardStore;
