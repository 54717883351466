import '@babel/polyfill';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import { supportsPassiveEvents } from 'detect-passive-events';
import sanitizeHTML from 'sanitize-html';
import EventBus from '@/services/EventBus';
import * as FEATURE_LIST from '@/config/constants/features';
import resolveConfig from '@/config/resolver';
import api from '@/config/api/live';
import FortunaSubscription from '@/config/stomp';
import router from '@/config/router';
import '@/globals/polyfills/DocumentVisibility';
import asyncPolyfills from '@/globals/polyfills/asyncPolyfillGenerator';
import { GaIDs, CB_ENVIRONMENT_NAME, resolveEndpoint } from '@/config/constants';
import Lifecycle from '@/services/LifecycleHooks';
import { APP_CONFIG_READY } from '@/globals/enums/eventBus/App.enums';
import translation from '@/plugins/translation';
import translationPlugin from '@fortunaweb-fe/frontend-commons/dist/filters/v2/translation';
import { LIVE } from '@fortunaweb-fe/frontend-commons/dist/config/constants/platforms';
import '@fortunaweb-fe/mooncake/dist/skins/_themes.scss';
import { IS_FEATURE_SUPPORTED } from '@/stores/AppConfigurationStore/constants';
import { getRequestLocale } from "@/config/locale";
import { gtmService } from './services/GTMService/GTMService';

require(`@fortunaweb-fe/mooncake/dist/skins/${SKIN}/colors.scss`);

// eslint-disable-next-line no-underscore-dangle
window.__FORTUNA_PASSIVE_EVENTS__ = supportsPassiveEvents;
window.PLATFORM = LIVE;
document.documentElement.setAttribute(`lang`, getRequestLocale());
Vue.use(translationPlugin, translation);

Promise
  .all([resolveConfig(), ...asyncPolyfills])
  .then(([config]) => {
    Lifecycle.$emit(APP_CONFIG_READY);
    gtmService.init({ router });

    // eslint-disable-next-line max-len
    const { STOMP_URL,
      APIGW_URL,
      APIGW_LIVE_API_PREFIX,
      EXCHANGE_RATE,
      SECONDARY_CURRENCY,
      IS_DIVIDE_ER,
      BEHAVIOUR, ALLSESSION,
      CHAT_PROVIDER,
      YELLOWAI_HOST,
      YELLOWAI_BOT,
      IN_APP_STOMP_URL,
    } = (config.data || config[IS_CB ? CB_ENVIRONMENT_NAME : SITE_ENV]);
    const { ENV } = LOCAL_ENV ? config : config.data;

    if (!APIGW_URL || !STOMP_URL) {
      // eslint-disable-next-line max-len
      throw new Error(`${!APIGW_URL ? `APIGW` : ``}${!STOMP_URL
        ? `STOMP`
        : ``} is not setup in config file for current environment: ${LOCAL_ENV}`);
    }

    window.APIGW_URL = APIGW_URL;
    window.EXCHANGE_RATE = EXCHANGE_RATE;
    window.SECONDARY_CURRENCY = SECONDARY_CURRENCY;
    window.IS_DIVIDE_ER = IS_DIVIDE_ER;
    window.BEHAVIOUR = BEHAVIOUR;
    window.ALLSESSION = ALLSESSION;
    window.CHAT_PROVIDER = (CHAT_PROVIDER || `Playtech`).toUpperCase();
    window.YELLOWAI_HOST = YELLOWAI_HOST;
    window.YELLOWAI_BOT = YELLOWAI_BOT;
    window.IN_APP_STOMP_URL = IN_APP_STOMP_URL.substring(0, IN_APP_STOMP_URL.lastIndexOf(`/`));

    api.defaults.baseURL = APIGW_URL;
    if (!api.options.data) api.options.data = {};

    api.options.data.apiLivePrefix = APIGW_LIVE_API_PREFIX;
    window.APIGW_LIVE_API_PREFIX = APIGW_LIVE_API_PREFIX;

    if (!MOCK) {
      FortunaSubscription.setConnectionUrl(STOMP_URL).connect();
    }

    Vue.use(VueAnalytics, {
      id: GaIDs,
      autoTracking: {
        // It autotracks additional user actions that arent yet specified
        screenview: true,
      },
      debug: {
        sendHitTask: process.env.NODE_ENV === `production`,
        enabled: false, // Switch off to not console log tracking events
      },
    });

    function injectBulkGlobalVueProperty(keyValuePairArray) {
      Object.entries(keyValuePairArray).forEach((keyValuePair) => {
        const key = keyValuePair[0];
        const value = keyValuePair[1];

        if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
          Vue.prototype[key] = value;
        }
      });
    }

    if (ENV !== `PRD`) {
      // eslint-disable-next-line no-console
      console.info(`===== Live3 =====`);
      // eslint-disable-next-line no-console
      console.info(`site: ${SITE_ENV}`);
      // eslint-disable-next-line no-console
      console.info(`env: ${ENV}`);
      // eslint-disable-next-line no-console
      console.info(`APIGW_URL: ${APIGW_URL}`);
      api.get(resolveEndpoint(`VERSION`))
        .then(({ data: appVersion }) => {
          // eslint-disable-next-line no-console
          console.info(`===== Live3 API =====`);
          // eslint-disable-next-line no-console
          console.info(`app version: ${appVersion}`);
        });
      api.get(resolveEndpoint(`FEATURES`))
        .then(({ data }) => {
          // eslint-disable-next-line no-console
          console.info(`===== LIVE3 API FEATURES  =====`);
          // eslint-disable-next-line no-console
          console.table(data);
        });
    }

    const AppPromises = [];

    AppPromises.push(import(/* webpackChunkName: "app" */ `./App.vue`));
    AppPromises.push(import(/* webpackChunkName: "v-tooltip" */ `v-tooltip`));
    AppPromises.push(import(/* webpackChunkName: "vue-date-filter" */ `vue-date-filter`));
    AppPromises.push(import(/* webpackChunkName: "vuex-router-sync" */ `vuex-router-sync`));
    AppPromises.push(import(/* webpackChunkName: "store" */ `./config/store`));

    Promise
      .all(AppPromises)
      .then(([
        { default: App },
        { default: VTooltip },
        { default: date },
        { sync },
        { default: store },
      ]) => {
        Vue.config.productionTip = false;

        if (PERFORMANCE) {
          Vue.config.devtools = true;
          Vue.config.performance = true;
        }

        // vue global variables
        Vue.prototype.ENV = ENV;
        Vue.prototype.SITE_ENV = SITE_ENV;
        Vue.prototype.SKIN = SKIN;
        Vue.prototype.THEME = THEME;
        Vue.prototype.$eventBus = EventBus;
        const defaultAllowedTags = sanitizeHTML.defaults.allowedTags;
        const defaultAllowedAttributes = sanitizeHTML.defaults.allowedAttributes;

        Vue.prototype.$sanitize = (dirty) => sanitizeHTML(dirty, {
          allowedTags: [...defaultAllowedTags, `img`],
          allowedAttributes: {
            ...defaultAllowedAttributes,
            img: [...defaultAllowedAttributes.img, `alt`],
          },
        });

        Vue.prototype.isFeatureSupported = (featureName) => store.getters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](featureName);

        injectBulkGlobalVueProperty(FEATURE_LIST);

        Vue.use(date);
        Vue.use(VTooltip);

        sync(store, router, { moduleName: `RouterStore` });

        new Vue({
          router,
          store,
          // eslint-disable-next-line vue/no-reserved-component-names
          name: `Main`,
          render: (h) => h(App),
        }).$mount(`#app`);
      });
  }).catch((err) => {
  // eslint-disable-next-line
  console.error(err);
  });
