import Vue from 'vue';
import safeGet from 'lodash/get';

export const types = {
  CREATE_SCOREBOARD: `CREATE_SCOREBOARD`,
  UPDATE_SCOREBOARD: `UPDATE_SCOREBOARD`,
  DELETE_SCOREBOARD: `DELETE_SCOREBOARD`,
  UPDATE_SCOREBOARD_DATA: `UPDATE_SCOREBOARD_DATA`,
  UPDATE_SCOREBOARD_OVERVIEW: `UPDATE_SCOREBOARD_OVERVIEW`,
  ADD_SCOREBOARD_LIST: `ADD_SCOREBOARD_LIST`,
};

export const actions = {
  CREATE_SCOREBOARD: `createScoreboard`,
  UPDATE_SCOREBOARD: `updateScoreboard`,
  DELETE_SCOREBOARD: `deleteScoreboard`,
  UPDATE_SCOREBOARD_DATA: `updateScoreboardData`,
  UPDATE_SCOREBOARD_OVERVIEW: `updateScoreboardOverview`,
  ADD_SCOREBOARD_LIST: `addScoreboardList`,
};

export const getters = {
  GET_SCOREBOARD: `getScoreboard`,
  MATCH_ID_HAS_SCOREBOARD: `matchHasScoreboard`,
  GET_SCOREBOARD_MATCH_STATE: `getScoreboardMatchState`,
};

const scoreboardStore = {
  state: {
    scoreboards: {},
  },
  mutations: {
    [types.CREATE_SCOREBOARD](state, scoreboard) {
      Vue.set(state.scoreboards, scoreboard.matchId, scoreboard);
    },
    [types.UPDATE_SCOREBOARD](state, scoreboard) {
      Vue.set(state.scoreboards, scoreboard.matchId, scoreboard);
    },
    [types.DELETE_SCOREBOARD]: (state, id) => {
      Vue.delete(state.scoreboards, id);
    },
    [types.UPDATE_SCOREBOARD_DATA]: (state, { matchId, data }) => {
      const scoreboard = state.scoreboards[matchId];

      if (!scoreboard) {
        // eslint-disable-next-line no-undef
        if (NODE_ENV !== `production`) {
          // eslint-disable-next-line no-console
          console.warn(`Attempt to update data for non-existing scoreboard ${matchId}`);
        }
        return;
      }
      scoreboard.data = data;
    },
    [types.UPDATE_SCOREBOARD_OVERVIEW]: (state, { matchId, overview }) => {
      const scoreboard = state.scoreboards[matchId];

      if (!scoreboard) {
        // eslint-disable-next-line no-undef
        if (NODE_ENV !== `production`) {
          // eslint-disable-next-line no-console
          console.warn(`Attempt to update data for non-existing scoreboard ${matchId}`);
        }
        return;
      }
      scoreboard.overview = overview;
    },
    [types.ADD_SCOREBOARD_LIST]: (state, scoreboards) => {
      Object
        .values(scoreboards)
        .map((scoreboard) => Vue.set(state.scoreboards, scoreboard.matchId, scoreboard));
    },
  },
  actions: {
    [actions.CREATE_SCOREBOARD]({ commit }, data) {
      commit(types.CREATE_SCOREBOARD, data);
    },
    [actions.UPDATE_SCOREBOARD]({ commit }, data) {
      commit(types.UPDATE_SCOREBOARD, data);
    },
    [actions.DELETE_SCOREBOARD]({ commit }, data) {
      commit(types.DELETE_SCOREBOARD, data);
    },
    [actions.UPDATE_SCOREBOARD_DATA]({ commit }, data) {
      commit(types.UPDATE_SCOREBOARD_DATA, data);
    },
    [actions.UPDATE_SCOREBOARD_OVERVIEW]({ commit }, data) {
      commit(types.UPDATE_SCOREBOARD_OVERVIEW, data);
    },
    [actions.ADD_SCOREBOARD_LIST]({ commit }, data) {
      commit(types.ADD_SCOREBOARD_LIST, data);
    },
  },
  getters: {
    [getters.GET_SCOREBOARD](state) {
      return (matchId) => state.scoreboards[matchId] || null;
    },
    [getters.MATCH_ID_HAS_SCOREBOARD](state) {
      return (matchId) => state.scoreboards[matchId] !== null;
    },
    [getters.GET_SCOREBOARD_MATCH_STATE](state, moduleGetters) {
      return (matchId) => safeGet(moduleGetters[getters.GET_SCOREBOARD](matchId), `data.state`, null);
    },
  },
};

export default scoreboardStore;
