<template>
  <div
    :ref="matchRefId"
    :class="extraClasses"
    class="live-match"
  >
    <LiveMatchInfo
      :matchOverview="matchOverview"
      :matchDetailRoute="matchDetailRoute"
      :matchMiniScoreboard="matchMiniScoreboard"
      :matchTracker="match.matchTracker"
      :sportType="sportType"
      :team1Names="match.team1Names"
      :team2Names="match.team2Names"
      :stream="match.stream"
      :matchId="matchId"
      :sportId="match.sportId"
      :matchDetailStyle="matchDetailStyle"
      :isLiveMatch="isLiveMatch"
    />
    <template v-if="topMarket1.marketId && !scoreboardMatchStateEnd && matchDetailStyle">
      <div
        ref="marketsContainer"
        :style="initialSingleMarketVisibility"
        class="live-match__single-market"
      >
        <MarketRow
          v-if="topMarket1.marketId"
          :key="topMarket1.id"
          :tooltip="{ content: topMarket1Name, trigger: marketPopoverTriggers }"
          :matchId="matchId"
          :marketId="topMarket1.marketId"
          :showMarketLabel="false"
          :marketLabel="topMarket1.label"
          :oddsMode="oddsStyleType"
          :oddsNameType="oddsNameType"
        />
      </div>
    </template>
    <template v-if="!liveMatchMarketsEmpty && !isRaceSportType && !matchDetailStyle">
      <div class="live-match__markets">
        <div class="live-match__top-market live-match__top-market--1">
          <MarketRow
            v-if="topMarket1.marketId"
            :key="topMarket1.id"
            :matchId="matchId"
            :marketId="topMarket1.marketId"
            :showMarketLabel="true"
            :marketLabel="topMarket1.label"
            :isTopMarket="true"
            :tooltip="{ content: topMarket1Name, trigger: marketPopoverTriggers }"
          />
          <router-link
            v-else
            v-translate="'live3.topmarkets.empty'"
            :to="matchDetailRoute"
            class="live-match__top-market--empty"
          />
        </div>
        <div class="live-match__top-market live-match__top-market--2">
          <MarketRow
            v-if="topMarket2.marketId"
            :key="topMarket2.id"
            :tooltip="{ content: topMarket2Name, trigger: marketPopoverTriggers }"
            :matchId="matchId"
            :marketId="topMarket2.marketId"
            :showMarketLabel="true"
            :marketLabel="topMarket2.label"
            :isTopMarket="true"
          />
          <router-link
            v-else
            v-translate="'live3.topmarkets.empty'"
            :to="matchDetailRoute"
            class="live-match__top-market--empty"
          />
        </div>
        <div class="live-match__top-market live-match__top-market--3">
          <MarketRow
            v-if="topMarket3.marketId"
            :key="topMarket3.id"
            :tooltip="{ content: topMarket3Name, trigger: marketPopoverTriggers }"
            :matchId="matchId"
            :marketId="topMarket3.marketId"
            :showMarketLabel="true"
            :marketLabel="topMarket3.label"
            :isTopMarket="true"
          />
          <router-link
            v-else
            v-translate="'live3.topmarkets.empty'"
            :to="matchDetailRoute"
            class="live-match__top-market--empty"
          />
        </div>
      </div>
    </template>

    <router-link
      v-if="liveMatchMarketsEmpty && !matchDetailStyle"
      v-translate="'live3.topmarkets.empty'"
      :to="matchDetailRoute"
      class="live-match__top-market live-match__top-market--empty"
    />

    <router-link
      v-if="isRaceSportType && !matchDetailStyle"
      v-translate="`live3.topmarkets.matchTypeRace.${sportType.toLowerCase()}`"
      :to="matchDetailRoute"
      class="live-match__type-race-notice"
    />

    <div
      v-if="isRunning && !matchDetailStyle"
      class="live-match__additional_markets"
    >
      <AdditionalMarketsButton
        :matchId="matchId"
        :count="otherMarketsCount"
      />
    </div>
  </div>
</template>

<script>
  import Velocity from 'velocity-animate';
  import { mapGetters, mapState } from 'vuex';
  import constants from '@/config/constants';

  import AdditionalMarketsButton from '@/globals/components/AdditionalMarketsButton/AdditionalMarketsButton';
  import MarketRow from '@/globals/components/MarketRow/MarketRow';

  import {
    ODDS_BUTTON_NAME_TYPE_SHORT,
    ODDS_BUTTON_STYLE_TYPE_HORIZONTAL,
  } from '@/globals/enums/Odds.enums';
  import { SEARCH_MATCH_ID, SPORT_MENU_SCROLL_TO } from "@/globals/enums/eventBus/App.enums";
  import { LiveMatchStatus } from '@/globals/enums/enums';
  import { RACE_SPORT_TYPES } from '@/globals/enums/RaceTypeSports.enums';
  import { MATCH_STATE_FINISHED } from '@/globals/enums/MatchState.enums';

  import { hasStickySupport } from '@/globals/utils/dom_utils';

  import { FEATURE_CB_LIVE } from '@/config/constants/features';
  import { getters as liveMatchOverviewGetters } from '@/stores/LiveMatchOverviewStore';
  import { getters as matchStoreGetters } from '@/stores/LiveMatchStore';
  import { getters as liveMiniScoreboardsGetters } from '@/stores/LiveMiniScoreboardStore';
  import { getterNames as liveTopMarketStoreGetters } from '@/stores/LiveTopMarketStore';

  import { gtmService } from '@/services/GTMService/GTMService';
  import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
  import LiveMatchInfo from "@/globals/components/LiveMatch/LiveMatchInfo";

  const ANIMATION_DURATION_MILLIS = 400;

  export default {
    name: `LiveMatch`,
    components: {
      LiveMatchInfo,
      MarketRow,
      AdditionalMarketsButton,
    },
    props: {
      sportType: {
        type: String,
        required: true,
      },
      matchDetailStyle: {
        type: Boolean,
        required: false,
        default: false,
      },
      marketsVisible: {
        type: Boolean,
        required: false,
        default: true,
      },
      matchId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        oddsStyleType: ODDS_BUTTON_STYLE_TYPE_HORIZONTAL,
        oddsNameType: ODDS_BUTTON_NAME_TYPE_SHORT,
        initialSingleMarketVisibility: {
          display: `block`,
        },
      };
    },
    computed: {
      matchRefId() {
        return `match-${this.matchId}`;
      },
      extraClasses() {
        return {
          'live-match--short': this.matchDetailStyle,
          'live-match--active': this.matchIsSelected,
        };
      },
      ...mapState(`GraphicsStore`, {
        windowSize: `browserWindow`,
      }),
      ...mapGetters(`LiveStore`, {
        getMatchById: matchStoreGetters.GET_MATCH_BY_ID,
        getOverviewByMatch: liveMatchOverviewGetters.GET_OVERVIEW_BY_MATCH_ID,
        getMiniScoreBoardByMatch: liveMiniScoreboardsGetters.GET_MINI_SCOREBOARD_BY_MATCH_ID,
        getTopMarketById: liveTopMarketStoreGetters.GET_TOP_MARKET_BY_ID,
        getMatchRouteById: matchStoreGetters.GET_MATCH_ROUTE_BY_ID,
      }),
      ...mapGetters(`AppConfigurationStore`, {
        getConfigurationValue: APP_CONF_GET_CONF_VALUE,
      }),
      match() {
        return this.getMatchById(this.matchId);
      },
      matchIsSelected() {
        return this.$route.params.matchId === this.matchId;
      },
      isRaceSportType() {
        return RACE_SPORT_TYPES.indexOf(this.sportType) > -1;
      },
      matchMiniScoreboard() {
        return this.getMiniScoreBoardByMatch(this.matchId);
      },
      matchOverview() {
        return this.getOverviewByMatch(this.matchId);
      },
      // TODO
      matchDetailRoute() {
        return this.getMatchRouteById(this.matchId, this.$route, this.$route.params.sportId);
      },
      liveMatchMarketsEmpty() {
        return !this.isRaceSportType && (!this.hasTopMarkets || this.scoreboardMatchStateEnd);
      },
      scoreboardMatchStateEnd() {
        return this.matchMiniScoreboard.state === MATCH_STATE_FINISHED;
      },
      topMarket1() {
        return this.getTopMarketById(`${this.matchId}_1`);
      },
      topMarket2() {
        return this.getTopMarketById(`${this.matchId}_2`);
      },
      topMarket3() {
        return this.getTopMarketById(`${this.matchId}_3`);
      },
      hasTopMarkets() {
        const { topMarket1, topMarket2, topMarket3 } = this;

        return (topMarket1.id && topMarket1.marketId)
          || (topMarket2.id && topMarket2.marketId)
          || (topMarket3.id && topMarket3.marketId);
      },
      isRunning() {
        return this.match.status === LiveMatchStatus.RUNNING;
      },
      otherMarketsCount() {
        let marketCount = this.match.marketsCount;

        if (Object.keys(this.topMarket1).length > 0) {
          marketCount -= 1;
        }
        if (Object.keys(this.topMarket2).length > 0) {
          marketCount -= 1;
        }
        if (
          Object.keys(this.topMarket3).length > 0
          && this.windowSize.width >= constants.TOP_MARKET_3_VISIBILITY_BREAKPOINT
        ) {
          marketCount -= 1;
        }
        return marketCount;
      },
      topMarket1Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.1`);
      },
      topMarket2Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.2`);
      },
      topMarket3Name() {
        return this.$t(`live3.topmarkets.${this.sportType}.3`);
      },
      marketPopoverTriggers() {
        return hasStickySupport ? `` : `hover`;
      },
      isLiveMatch() {
        return !this.match.mirrorReflex && this.isFeatureSupported(FEATURE_CB_LIVE);
      },
    },
    watch: {
      marketsVisible() {
        if (this.topMarket1.marketId && !this.scoreboardMatchStateEnd) this.toggleTopMarkets();
      },
      matchId: {
        immediate: true,
        handler(newId) {
          this.$nextTick(() => {
            if (this.getConfigurationValue(`impressionMatchTracking`)) {
              const matchElement = this.$refs[this.matchRefId];

              gtmService.matchImpression.observe(matchElement, newId);
            }
          });
        },
      },
    },
    created() {
      this.initialSingleMarketVisibility = {
        display: this.marketsVisible ? `block` : `none`,
      };

      this.$eventBus.$on(SEARCH_MATCH_ID, async (matchId) => {
        if (!matchId || matchId !== this.matchId) return;

        this.$nextTick(() => {
          const matchRef = this.$refs[this.matchRefId];

          if (matchRef) {
            this.$eventBus.$emit(SPORT_MENU_SCROLL_TO, matchRef);
          }
        });
      });
    },
    methods: {
      toggleTopMarkets() {
        if (this.isAnimating) {
          return;
        }

        this.isAnimating = true;
        this
          .animateTopMarkets()
          .then(() => (this.isAnimating = false)); // eslint-disable-line no-return-assign
      },
      animateTopMarkets() {
        const { marketsContainer } = this.$refs;

        if (marketsContainer) {
          Velocity(this.$refs.marketsContainer, `finish`);
          return Velocity(
            this.$refs.marketsContainer,
            `slide${this.marketsVisible ? `Down` : `Up`}`,
            { duration: ANIMATION_DURATION_MILLIS },
          );
        }
        return Promise.resolve();
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.live-match';

  #{$base_class} {
    @if ($theme == 'light') {
      background-color: var(--color-background--darkest);
    } @else {
      background-color: var(--color-background--darker);
    }
    height: $match_row_height;
    display: flex;
    transform: translate3d(0, 0, 0);
    position: relative;
    flex: 0 1 auto;
    flex-direction: row;
    width: 100%;

    & + & {
      // intentionally handpicked elements, since border cannot be on whole row
      // (light border would be visible between top markets)
      .live-match-info,
      .live-match__top-market,
      .live-match__additional_markets,
      .live-match__additional_markets {
        @if ($theme == 'light') {
          border-top: 1px solid var(--color-background--darker);
        } @else {
          border-top: 1px solid var(--color-background);
        }
      }
    }

    > * + * {
      border-left: 1px solid var(--color-background);
    }

    &--short {
      height: auto;
      min-height: $match_row_height;
      flex-direction: column;

      #{$base_class}__header {
        width: 100%;
        min-width: auto;
        height: 45px;
      }

      > * + * {
        border-left: none;
      }

    }

    &--active {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        top: 0;
        height: 100%;
        width: 2px;
        background: var(--color-primary);
        z-index: z_index_for_layer($CONTENT_LAYER_1, 2);
      }
    }

    &__markets {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;

      @if ($theme == 'dark') {
        background-color: var(--color-background--lighter);
      }
      @if ($theme == 'light') {
        background-color: var(--color-background);
      }
    }

    &__type-race-notice {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      text-decoration: none;
      background: var(--color-background--darkest);
      color: var(--color-primary);

      &:hover {
        background: var(--color-background--darker);
      }
    }

    &__top-market {
      width: 50%;
      max-height: $match_row_height;
      overflow: hidden;

      & + & {
        margin-left: $top_market_spacing_left;
      }
      .market_row__odds {
        height: 100%;
      }
      .odds_button {
        height: 100%;
      }

      &--3 {
        display: none;
      }

      @media (min-width: $top_market_3_visibility_breakpoint) {
        width: calc(100% / 3);

        &--3 {
          display: initial;
        }
      }

      &--empty {
        display: flex;
        flex: 0 1 auto;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        color: var(--color-background--lightest);
        font-size: 12px;
        text-decoration: none;
        background-color: var(--color-background--darker);

        @if ($theme == 'light') {
          background-color: var(--color-background--darkest);
        }
      }
    }

    &__additional_markets {
      width: $match_controls_width;
      min-width: $match_controls_width;
      display: flex;
      flex: 0 1 auto;
      justify-content: center;
      align-items: center;
    }
  }

</style>
