<template>
  <div class="legal_disclaimer">
    <CollapsibleBox
      :collapsed="isFeatureSupported(FEATURE_COLLAPSED_LEGAL_BOX)"
      arrowPosition="left"
    >
      <div
        slot="header"
        class="legal_disclaimer__header"
      >
        {{ $t('live3.legal.disclaimer.title') }}
      </div>
      <div slot="content">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="$t('live3.legal.disclaimer.text')" />
        <div
          class="legal_disclaimer__copyright"
          v-html="$t('live3.legal.disclaimer.copyright')"
        />
        <!-- eslint-enable -->
        <LangSwitcher v-if="isFeatureSupported(FEATURE_LANG_SWITCHER)" />
        <div v-if="isFeatureSupported(FEATURE_LEGAL_ICONS)">
          <LegalIcons />
        </div>
      </div>
    </CollapsibleBox>
  </div>
</template>

<script>
  import CollapsibleBox from '../CollapsibleBox/CollapsibleBox';

  export default {
    name: `LegalDisclaimer`,
    components: {
      CollapsibleBox,
      LangSwitcher: () => import(/* webpackChunkName: "LangSwitcher" */ `./LangSwitcher.vue`),
      LegalIcons: () => import(/* webpackChunkName: "LegalIcons" */ `./LegalIcons.vue`),
    },
  };

</script>

<style lang="scss">
  $base_class: '.legal_disclaimer';

  #{$base_class} {
    @if ($theme == 'light') {
      background: var(--color-background--darker);
    } @else {
      background: var(--color-background--darkest);
    }

    &__header {
      color: var(--color-primary-text);
      font-size: 11px;
      font-weight: bold;
      padding: 10px 0 8px;
    }

    &__copyright {
      margin: 5px 0;
    }

    .collapsible_box__content {
      color: var(--color-secondary-text);
      font-size: 11px;
      padding: 10px;
    }

    @include ie11Only {
      margin: 20px 5px;
    }

    @include firefoxOnly {
      margin: 20px 5px;
    }

  }
</style>
