import { BROWSERS } from "@/globals/enums/enums";

export function getChromeVersion() {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
}

export function getClientBrowser() {
  const { userAgent } = navigator;

  if (userAgent.match(/chrome|chromium|crios/i)) return BROWSERS.CHROME;
  if (userAgent.match(/firefox|fxios/i)) return BROWSERS.FIREFOX;
  if (userAgent.match(/safari/i)) return BROWSERS.SAFARI;
  if (userAgent.match(/opr\//i)) return BROWSERS.OPERA;
  if (userAgent.match(/edg/i)) return BROWSERS.EDGE;

  // In case browser was not identified
  return `unknown`;
}
