<template>
  <div
    class="no-match-in-view"
    :class="{ favorites: isFavorites }"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-if="!isFavorites"
      class="no-match-in-view__reason"
      v-html="$t('live3.noFilteredMatches.description')"
    />
    <p
      v-else
      class="no-match-in-view__reason"
    >
      {{ $t('live3.noFilteredMatches.favoritesNotice') }}
      <Icon name="star" />
    </p>
    <FortunaButton
      :block="false"
      :size="PROPS_T_SHIRT_SIZES.S"
      class="no-match-in-view__button"
      rounded
      :color="PROPS_BUTTON_COLORS.NEUTRAL"
      @click="removeReasonAction"
    >
      {{ buttonText }}
    </FortunaButton>
  </div>
</template>

<script>
  import FortunaButton from '@fortunaweb-fe/mooncake/dist/FortunaButton';
  import Icon from '@/globals/components/Icon/Icon';
  import constants from '@/config/constants';
  import { PROPS_T_SHIRT_SIZES, PROPS_BUTTON_COLORS } from '@fortunaweb-fe/mooncake/dist/propsEnum';

  export default {
    name: `NoMatchInViewMessage`,
    components: { FortunaButton, Icon },
    props: {
      sportId: {
        type: String,
        required: true,
      },
      streamFilterActive: {
        type: Boolean,
        required: false,
        default: false,
      },
      removeReasonAction: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        PROPS_T_SHIRT_SIZES,
        PROPS_BUTTON_COLORS,
      };
    },
    computed: {
      isFavorites() {
        return !this.streamFilterActive && this.sportId === constants.FAVORITES_SPORT_ID;
      },
      description() {
        return this.isFavorites
          ? this.$t(`live3.noFilteredMatches.description`)
          : this.$t(`live3.noFilteredMatches.favoritesNotice`);
      },
      buttonText() {
        return this.isFavorites
          ? this.$t(`live3.noFilteredMatches.noFavorites`)
          : this.$t(`live3.noFilteredMatches.turnOffButton`);
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.no-match-in-view';
  #{$base_class} {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--color-secondary-text--darker);
    background-color: var(--color-background--darker);
    padding: 10px 0;
    font-size: 14px;

    &__reason {
      padding: 10px;
      width: 400px;
      max-width: 100%;
    }

    &.favorites {
      flex-direction: column;

      #{$base_class}__reason {
        text-align: center;
      }

      #{$base_class}__button {
        margin-top: 15px;
      }
    }
  }

</style>
