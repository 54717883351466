<template>
  <div
    class="sport-show-more"
    @click="routeToSport"
  >
    {{ $t('live3.sport_sorts_filters.show_more') }} {{ sportName }} ({{ matchCount }})
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ROUTE_OVERVIEW_BY_SPORT } from '@/config/router/routes';
  import { getters as liveSportStoreGetters } from '@/stores/LiveSportStore';

  export default {
    name: `SportShowMore`,
    props: {
      sportId: {
        type: String,
        required: true,
      },
      matchCount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getSportNames: liveSportStoreGetters.GET_SPORT_NAMES_BY_ID,
      }),
      sportName() {
        return this.getSportNames(this.sportId);
      },
    },
    methods: {
      routeToSport() {
        this.$router.push({
          name: ROUTE_OVERVIEW_BY_SPORT,
          query: { ...this.$route.query },
          params: { sportId: this.sportId },
        });
      },
    },
  };
</script>

<style lang="scss">
  .sport-show-more {
    padding: 10px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;

    &:hover {
      background: var(--color-background--darker);
      opacity: 1;
    }
  }
</style>
