<template>
  <div>
    <FavoriteLeague
      v-if="!selectedLeagueId"
      :sportId="sportId"
      :sportType="sportType"
      :short="short"
      :showTopMarket="showTopMarket"
      :collapsingCategory="collapsingCategory"
    />
    <League
      v-for="(league) in displayedLeagues"
      :key="league"
      :leagueId="league"
      :sportId="sportId"
      :sportType="sportType"
      :short="short"
      :showTopMarket="showTopMarket"
      :selectedLeagueId="selectedLeagueId"
      :collapsingCategory="collapsingCategory"
    />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';

  import League from '@/globals/components/League/League';
  import FavoriteLeague from '@/globals/components/League/FavoriteLeague';
  import {
    GET_FILTERED_LEAGUE_IDS_BY_SPORT,
    GET_LIMITED_FILTERED_LEAGUE_IDS_BY_SPORT,
  } from '@/stores/LiveLeagueStore/constants';

  export default {
    components: {
      League,
      FavoriteLeague,
    },
    props: {
      sportId: {
        type: String,
        required: true,
      },
      sportType: {
        type: String,
        required: true,
      },
      selectedLeagueId: {
        type: String,
        required: true,
      },
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
      showTopMarket: {
        type: Boolean,
        required: false,
        default: false,
      },
      limitMatches: {
        type: Boolean,
        required: true,
      },
      collapsingCategory: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getFilteredLeagueIdsBySportId: GET_FILTERED_LEAGUE_IDS_BY_SPORT,
        getLimitedFilteredLeagueIdsBySportId: GET_LIMITED_FILTERED_LEAGUE_IDS_BY_SPORT,
      }),
      leagues() {
        return this.getFilteredLeagueIdsBySportId(this.sportId);
      },
      limitedLeagues() {
        return this.getLimitedFilteredLeagueIdsBySportId(this.sportId);
      },
      displayedLeagues() {
        if (this.limitMatches) {
          return this.limitedLeagues;
        }
        return this.selectedLeagueId
          ? this.leagues.filter((league) => league === this.selectedLeagueId)
          : this.leagues;
      },
    },
  };
</script>
