import { toFixed } from "@fortunaweb-fe/frontend-commons/dist/utils/math";

function addGroupSeparators(numberString) {
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ` `);
}

export function formatInteger(value, placeholderValue = value) {
  if (typeof value !== `number`) {
    // eslint-disable-next-line no-undef
    if (NODE_ENV !== `production`) {
      // eslint-disable-next-line no-console
      console.warn(`Attempt to format non-numeric value '${value}'`);
    }
    return placeholderValue;
  }
  return addGroupSeparators(toFixed(value, 0));
}

export function formatWithFraction(value, placeholderValue = ``) {
  if (typeof value !== `number`) {
    // eslint-disable-next-line no-undef
    if (NODE_ENV !== `production`) {
      // eslint-disable-next-line no-console
      console.warn(`Attempt to format non-numeric value '${value}'`);
    }
    return placeholderValue;
  }
  return addGroupSeparators(toFixed(value, 2));
}

export function formatWithFractionIfNeeded(value, placeholderValue = ``) {
  if (typeof value !== `number`) {
    // eslint-disable-next-line no-undef
    if (NODE_ENV !== `production`) {
      // eslint-disable-next-line no-console
      console.warn(`Attempt to format non-numeric value '${value}'`);
    }
    return placeholderValue;
  }
  const isInteger = value % 1 === 0;

  if (isInteger) {
    return formatInteger(value);
  }

  return formatWithFraction(value);
}

export function formatWithFractionIfNotOver999(value, placeholderValue = ``) {
  if (typeof value !== `number`) {
    // eslint-disable-next-line no-undef
    if (NODE_ENV !== `production`) {
      // eslint-disable-next-line no-console
      console.warn(`Attempt to format non-numeric value '${value}'`);
    }
    return placeholderValue;
  }

  if (value >= 1000) {
    return formatInteger(value);
  }

  return formatWithFraction(value);
}

export const formatPayValue = formatWithFractionIfNeeded;
export const formatWinValue = formatWithFraction;
export const formatOddsValue = formatWithFractionIfNotOver999;

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, `\\$&`); // $& means the whole matched string
}

export function parsePixelValue(value) {
  const match = value.match(/\d+/g) || [0];

  return parseInt(match[0], 10);
}
