import Vue from 'vue';
import { SPORT_HOCKEY } from '@fortunaweb-fe/frontend-commons/dist/config/constants';
import { getHockeyOverviewWithFakeGameTime } from '@fortunaweb-fe/frontend-commons/dist/utils/live-match';

import { getters as matchStoreGetters } from './LiveMatchStore';
import { getters as sportStoreGetters } from './LiveSportStore';

export const types = {
  CREATE_OR_UPDATE_OVERVIEW: `CREATE_OR_UPDATE_OVERVIEW`,
  REMOVE_OVERVIEW: `REMOVE_OVERVIEW`,
};
export const actions = {
  CREATE_OR_UPDATE_OVERVIEW: `createOrUpdateOverview`,
  CREATE_OVERVIEW_LIST: `createOverviewList`,
  REMOVE_OVERVIEW: `removeOverview`,
};
export const getters = {
  GET_OVERVIEW_BY_MATCH_ID: `getOverviewByMatchId`,
  GET_SPORT_NAME_BY_MATCH_ID: `getSportNameByMatchId`,
};

const liveMatchOverviewStore = {
  state: {
    overviews: {},
  },
  mutations: {
    [types.CREATE_OR_UPDATE_OVERVIEW](state, { data, sport, startDate }) {
      let { overview } = data;

      if (sport === SPORT_HOCKEY) {
        overview = getHockeyOverviewWithFakeGameTime(overview, startDate);
      }

      Vue.set(state.overviews, data.id, overview);
    },
    [types.REMOVE_OVERVIEW](state, matchId) {
      clearInterval(state.overviews?.gameTimeInterval);
      Vue.delete(state.overviews, matchId);
    },
  },
  actions: {
    [actions.CREATE_OR_UPDATE_OVERVIEW]({ commit, getters: moduleGetters, rootGetters }, data) {
      const sport = moduleGetters[getters.GET_SPORT_NAME_BY_MATCH_ID](data.id);
      const { startDate } = rootGetters[`LiveStore/${matchStoreGetters.GET_MATCH_BY_ID}`](data.id);

      commit(types.CREATE_OR_UPDATE_OVERVIEW, { data, sport, startDate });
    },
    [actions.CREATE_OVERVIEW_LIST]({ dispatch }, data) {
      Object
        .entries(data)
        .forEach(([id, overview]) => {
          dispatch(actions.CREATE_OR_UPDATE_OVERVIEW, { overview, id });
        });
    },
    [actions.REMOVE_OVERVIEW]({ commit }, data) {
      commit(types.REMOVE_OVERVIEW, data);
    },
  },
  getters: {
    [getters.GET_OVERVIEW_BY_MATCH_ID](state) {
      return (matchId) => state.overviews[matchId] || {};
    },
    [getters.GET_SPORT_NAME_BY_MATCH_ID](state, moduleGetters, stores, rootGetters) {
      return (matchId) => {
        const match = rootGetters[`LiveStore/${matchStoreGetters.GET_MATCH_BY_ID}`](matchId);
        const sport = rootGetters[`LiveStore/${sportStoreGetters.GET_SPORT_BY_ID}`](match?.sportId);

        return sport ? sport.sport : null;
      };
    },
  },
};

export default liveMatchOverviewStore;
