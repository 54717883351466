import { LOGIN_STATUS_LOGGED_OUT } from '@/globals/enums/enums';
import { USER_FORCE_LOGOUT_CHECK_USER_STATUS } from '@/globals/enums/eventBus/UserEvents.enums';
import EventBus from '@/services/EventBus';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getRequestLocale } from "@/config/locale";

const api = axios.create({
  timeout: 15000,
  withCredentials: true,
  headers: { 'Accept-Language': getRequestLocale() },
});

// don't use retry while testing
if (NODE_ENV !== `test`) {
  axiosRetry(api, {
    retries: 5,
    retryDelay: (retryCount) => retryCount * 1000,
    // The only valid retry code is 429 - KOM COLLISION
    retryCondition: ({ response }) => response && response.status === 429,
  });
}

if (MOCK) {
  (async () => {
    const {
      enableMock,
      initMockUrls,
      getMockResponse,
      getMockError,
    } = await import(/* webpackChunkName: "mockApi" */ `../../../mock/mockApi.service`);

    enableMock();
    initMockUrls(MOCK_SIZE || 180);
    api.interceptors.response.use((response) => response, getMockResponse);
    api.interceptors.request.use(getMockError);
  })();
} else {
  api.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status === 401) {
      EventBus.$emit(USER_FORCE_LOGOUT_CHECK_USER_STATUS, LOGIN_STATUS_LOGGED_OUT);
    }

    return Promise.reject(error);
  });
}

export default api;
