import api from '@/config/api/live';
import { actions as ticketStoreActions } from '@/stores/ticket/TicketStore';
import { resolveLiveTicketEndpoint } from '@/config/constants';
import store from '@/config/store';
import { getCommunicationErrorMessage } from '@/globals/utils/localizations';

export function tryMirrorTargets() {
  store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_PENDING}`, `TRY_MIRROR_TARGETS`);

  return api.post(resolveLiveTicketEndpoint(`TICKET_TRY_MIRROR_TARGETS`), {})
    .then((response) => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_SUCCESS}`, response.data);
    }, () => {
      store.dispatch(`TicketStore/${ticketStoreActions.OPERATION_ERROR}`,
        getCommunicationErrorMessage(`try_mirror_targets`));
    });
}
