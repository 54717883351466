export const gtmEvents = {
  GTM_USER_NOT_LOGGED: `anonymous`,
  GTM_ADD_BET: `addToBetslip`,
  GTM_REMOVE_BET: `removeFromBetslip`,
  GTM_BETSLIP_PLACEMENT: `betslipPlacement`,
  GTM_BETSLIP_ACCEPTANCE: `betslipAcceptance`,
  GTM_LOGIN: `login`,
  GTM_PAGE_INFORMATION: `pageInformation`,
  GTM_IMPRESSION: `impression`,
  GTM_APP_LOADED: `liveAppLoaded`,
  GTM_DEFAULT_CONSENT: `default_consent`,
};

export const gtmIDs = {
  CZ: `GTM-KGGX2B`,
  SK: `GTM-5LDMPBF`,
  PL: `GTM-T9G6GW6`,
  RO: `GTM-5HNP7DH`,
  CP: `GTM-MKLP4D`,
  HR: `GTM-PRRWBB4`,
};

export const pageTypes = {
  EVENT: `event`,
  TYPE: `category`,
  CATEGORY: `sportsbook-live`,
  LOBBY: `lobby`,
  FAVOURITES: `favourites`,
  ERROR: `Cannot get page category`,
};

export const EMPTY_FIELD = ``;

export const GTM_LIVE_TICKET_CHANNEL = `live`;

export const GTM_SUCCESS = `success`;
export const GTM_FAILURE = `failure`;

export const GTM_CONSOLE_ERROR = `GTM error:`;
export const GTM_BETSLIP_ID_NOT_AVAILABLE = `Error getting betslip ID`;
export const GTM_CODE_NOT_AVAILABLE = `Code not available`;
export const GTM_MESSAGE_NOT_AVAILABLE = `Message not available`;
export const GTM_TICKET_NOT_AUTHORIZED = `Ticket not authorized`;
