<template>
  <div
    v-if="isVisible"
    :class="classModifier"
    class="market_row"
  >
    <template v-if="oddsByMarketId.length > 0">
      <div
        v-if="canShowMarketLabel"
        class="market_row_label"
      >
        <div class="market_row_label__text">
          {{ marketLabel }}
        </div>
      </div>
      <div
        :class="{ 'market_row__odds--label_visible': canShowMarketLabel }"
        class="market_row__odds"
      >
        <OddsButton
          v-for="id in oddsByMarketId"
          :id="id"
          :key="id"
          :mode="oddsMode"
          :nameType="oddsNameType"
          :isSupportiveToSelected="isSupportive"
          :isSubmissionInProgress="isSubmissionInProgress"
          :marketId="marketId"
        />
      </div>
    </template>
    <div
      v-else
      class="live-match__top-market--empty"
    >
      {{ $t('live3.topmarkets.empty') }}
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { getters as liveOddsGetters } from '@/stores/LiveOddsStore';
  import { getters as liveMarketGetters } from '@/stores/LiveMarketStore';
  import { getters as liveMarketGroupGetters } from '@/stores/LiveMarketGroupStore';
  import { getters as ticketStoreGetters } from '@/stores/ticket/TicketStore';

  import OddsButton from '@/globals/components/OddsButton/OddsButton';

  import {
    ODDS_BUTTON_NAME_TYPE_SHORT,
    ODDS_BUTTON_STYLE_TYPE_VERTICAL,
  } from '@/globals/enums/Odds.enums';
  import { MARKET_FILTER_BUTTONS } from '@/globals/enums/enums';

  export default {
    name: `MarketRow`,
    components: {
      OddsButton,
    },
    props: {
      isTopMarket: {
        type: Boolean,
        required: false,
        default: false,
      },
      matchId: {
        type: String,
        required: true,
      },
      marketId: {
        type: String,
        required: true,
      },
      showMarketLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
      marketLabel: {
        type: String,
        required: false,
        default: ``,
      },
      oddsMode: {
        type: String,
        required: false,
        default: ODDS_BUTTON_STYLE_TYPE_VERTICAL,
      },
      oddsNameType: {
        type: String,
        required: false,
        default: ODDS_BUTTON_NAME_TYPE_SHORT,
      },
      classModifier: {
        type: String,
        required: false,
        default: ``,
      },
      tooltip: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters(`TicketStore`, {
        isSubmissionInProgress: ticketStoreGetters.IS_SUBMISSION_IN_PROGRESS,
      }),
      ...mapGetters(`LiveStore`, {
        isMarketInMatch: liveMarketGroupGetters.IS_MARKET_IN_MATCH,
        getMarketById: liveMarketGetters.GET_MARKET_BY_MARKET_ROW_ID,
        getOddsIdsByMarketId: liveOddsGetters.GET_ODDS_IDS_BY_MARKET_ID,
        isMarketSupportiveToSelected: liveMarketGetters.IS_MARKET_SUPPORTIVE_TO_SELECTED,
      }),
      ...mapState(`LiveStore`, {
        marketGroupIdFiltered: (state) => state.LiveMarketGroupStore.marketGroupIdFiltered,
      }),
      market() {
        return this.getMarketById(this.marketId);
      },
      oddsByMarketId() {
        return this.isTopMarket
          ? this.getOddsIdsByMarketId(this.marketId).splice(0, 3)
          : this.getOddsIdsByMarketId(this.marketId);
      },
      canShowMarketLabel() {
        return !!this.marketLabel && this.showMarketLabel;
      },
      isSupportive() {
        return this.isMarketSupportiveToSelected(this.marketId);
      },
      combinabilityFilterSelected() {
        return this.marketGroupIdFiltered === MARKET_FILTER_BUTTONS.COMBINABLE;
      },
      isVisible() {
        if (this.combinabilityFilterSelected) {
          return !this.isSupportive;
        }
        return true;
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.market_row';

  #{$base_class} {
    @include clearfix();
    height: 100%;

    & + & {
      @if ($theme == 'light') {
        border-top: 1px solid var(--color-background--darker);
      } @else {
        border-top: 1px solid var(--color-background);
      }
    }

    &__odds {
      @include clearfix();

      .odds_button {
        float: left;
        width: percentage(calc(1 / 3));

        &:first-child:last-child {
          width: 100%;
        }

        &:nth-child(3n+2):nth-last-child(1),
        &:nth-child(3n+1):nth-last-child(2),
        &:nth-child(3n+4):nth-last-child(1),
        &:nth-child(3n):nth-last-child(2),
        &:nth-child(3n+2):nth-last-child(3),
        &:nth-child(3n+1):nth-last-child(4) {
          width: 50%;
        }

        &,
          // last button of last row of 2, 2
        &:nth-child(3n+4):last-child {
          // set to odds_button because 1px border bug on overview
          @if ($theme == 'light') {
            border-left: 1px solid var(--color-background--darker);
          } @else {
            border-left: 1px solid var(--color-background);
          }
        }

        // every first button of a row of 3 odds (1st, 4th, 7th, 10th, ...)
        &:nth-child(3n+1),
          // first button of last row of 2 odds (rows: 3, 3, ..., 3, 2, 2)
        &:nth-child(3n+3):nth-last-child(2) {
          border-left: none;
        }

        &:nth-child(n+4),
        &:nth-child(3):nth-last-child(2) {
          @if ($theme == 'light') {
            border-top: 1px solid var(--color-background--darker);
          } @else {
            border-top: 1px solid var(--color-background);
          }
        }
      }
    }

    &__odds--label_visible {
      float: right;
      width: 66.6666%;
    }

    &_label {
      float: left;
      width: 33.3333%;
      height: 100%;
      display: table;
      @if ($theme == 'light') {
        background-color: var(--color-background--darkest);
      } @else {
        background-color: var(--color-background--darker);
      }
      cursor: default;
      padding: 8px;
      overflow: hidden;
      table-layout: fixed;

      &__text {
        @if ($theme == 'light') {
          color: var(--color-secondary-text);
        } @else {
          color: var(--color-secondary-text--darker);
        }
        font-size: 12px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
    }

  }
</style>
