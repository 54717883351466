/**
 * Empty ticket
 * @type {string}
 */
export const TICKET_MODE_NONE = `NONE`;
/**
 * Ticket with exactly one item
 * @type {string}
 */
export const TICKET_MODE_SOLO = `SOLO`;
/**
 * Ticket with 2 or more ticket items
 * @type {string}
 */
export const TICKET_MODE_AKO = `AKO`;
/**
 * Ticket with 2 or more ticket items for mode SYSTEM
 * @type {string}
 */
export const TICKET_MODE_SYSTEM = `SYSTEM`;
export const LEG_COMBI_MODE_SYSTEM = `LEG_COMBI`;
export const GROUP_COMBI_MODE_SYSTEM = `GROUP_COMBI`;
export const COMBI_MODE_SYSTEM = `COMBI`;
export const FALC_MODE_SYSTEM = `FALC`;

export const TICKET_AVAILABLE_MODES = [TICKET_MODE_NONE, TICKET_MODE_SOLO, TICKET_MODE_AKO, TICKET_MODE_SYSTEM];

/**
 * Shortened betslip type names for easier fit
 * in Combined Betslip full solution ticket preview
 */
export const BETSLIP_TYPE_TO_TICKET_TYPE_MAPPER = {
  [TICKET_MODE_NONE]: TICKET_MODE_NONE,
  [TICKET_MODE_SOLO]: TICKET_MODE_SOLO,
  [TICKET_MODE_AKO]: TICKET_MODE_AKO,
  [TICKET_MODE_SYSTEM]: TICKET_MODE_SYSTEM,
  [LEG_COMBI_MODE_SYSTEM]: TICKET_MODE_SYSTEM,
  [GROUP_COMBI_MODE_SYSTEM]: COMBI_MODE_SYSTEM,
  [FALC_MODE_SYSTEM]: FALC_MODE_SYSTEM,
};

// // internal enum used inside application
export const TICKET_ITEM_STATUS_HIGHER = `HIGHER`;
export const TICKET_ITEM_STATUS_LOWER = `LOWER`;
export const TICKET_ITEM_STATUS_EQUAL = `EQUAL`;
export const TICKET_ITEM_STATUS_INACTIVE = `INACTIVE`;

export const ChangesHandlingType = {
  NONE: `NONE`,
  IGNORE: `IGNORE`,
  UPWARD: `UPWARD`,
  TOLERATE: `TOLERATE`,
};

export const OperationState = {
  RUNNING: `RUNNING`,
  STOPPED: `STOPPED`,
  FINISHED: `FINISHED`,
};

export const TICKET_OPERATION_PHASE_ACCEPTED = `ACCEPTED`;
export const TICKET_OPERATION_PHASE_REJECTED = `REJECTED`;
export const TICKET_OPERATION_PHASE_TIMED_OUT = `TIMED_OUT`;
export const TICKET_OPERATION_PHASE_UNRESOLVED = `UNRESOLVED`;
export const TICKET_OPERATION_PHASE_INVALID = `INVALID`;
export const TICKET_OPERATION_PHASE_VALID = `VALID`;

export const PaymentKind = {
  CREDIT: `CREDIT`,
  POINTS: `POINTS`,
};

export const TicketStatus = {
  EARLY_CASHOUT: `EARLY_CASHOUT`,
  EARLY_CASHED: `EARLY_CASHED`,
  EARLY_WINNING: `EARLY_WINNING`,
  NON_WINNING: `NON_WINNING`,
  WINNING: `WINNING`,
  CASHED: `CASHED`,
  CASHOUT: `CASHOUT`,
  ACCEPTED: `ACCEPTED`,
};

export const TipStatus = {
  NON_WINNING: `NON_WINNING`,
  WINNING: `WINNING`,
  UNRESOLVED: `UNRESOLVED`,
  RETURNED: `RETURNED`,
};

export const TICKET_HISTORY_ACTION_SHARE_FB = `TICKET_HISTORY_ACTION_SHARE_FB`;
export const TICKET_HISTORY_ACTION_SHARE_CHAT = `TICKET_HISTORY_ACTION_SHARE_CHAT`;
export const TICKET_HISTORY_ACTION_OPEN_IN_NEW_WINDOW = `TICKET_HISTORY_ACTION_OPEN_IN_NEW_WINDOW`;

export const TICKET_ITEM_SIGNATURE_BONUS = `BONUS`;
export const TICKET_ITEM_SIGNATURE_GAME = `GAME`;
export const TICKET_ITEM_SIGNATURE_LOTTERY = `LOTTERY`;

export const TICKET_DEPOSIT_LINK_CLICKED = `TICKET_DEPOSIT_LINK_CLICKED`;

export const TicketGroup = {
  A: `A`,
  B: `B`,
};
