import Vue from 'vue';

export const types = {
  MBM_STAT_ADD: `MBM_STAT_ADD`,
  MBM_STAT_UPDATE: `MBM_STAT_UPDATE`,
  MBM_STAT_DELETE: `MBM_STAT_DELETE`,
};

export const actions = {
  MBM_STAT_ADD: `mbmStatCreate`,
  MBM_STAT_UPDATE: `mbmStatUpdate`,
  MBM_STAT_DELETE: `mbmStatDelete`,
};

export const getters = {
  MBM_STAT_GET_ALL: `mbmStatGetAll`,
  MBM_STAT_GET_ALL_BY_MATCH_ID: `mbmStatGetAllByMatchId`,
  MBM_STAT_HAS_ANY: `mbmStatHasAny`,
};

const LiveMinuteByMinuteStatsStore = {
  state: {
    stats: {},
  },
  mutations: {
    [types.MBM_STAT_ADD](state, { matchId, stat }) {
      Vue.set(state.stats, matchId, stat);
    },
    [types.MBM_STAT_UPDATE](state, { matchId, stat }) {
      Vue.set(state.stats, matchId, stat);
    },
    [types.MBM_STAT_DELETE](state, matchId) {
      Vue.delete(state.stats, matchId);
    },
  },
  actions: {
    [actions.MBM_STAT_ADD]({ commit }, data) {
      data.stat.forEach((mbmStat) => {
        mbmStat.icon = mbmStat.icon || `ico_nic`;
      });
      commit(types.MBM_STAT_ADD, data);
    },
    [actions.MBM_STAT_UPDATE]({ commit }, data) {
      commit(types.MBM_STAT_UPDATE, data);
    },
    [actions.MBM_STAT_DELETE]({ commit }, data) {
      commit(types.MBM_STAT_DELETE, data);
    },
  },
  getters: {
    [getters.MBM_STAT_GET_ALL](state) {
      return state.stats;
    },
    [getters.MBM_STAT_GET_ALL_BY_MATCH_ID](state) {
      return (matchId) => Object.values(state.stats[matchId] || {}) || [];
    },
    [getters.MBM_STAT_HAS_ANY](state) {
      return (matchId) => Object.values(state.stats[matchId] || {}).length > 0;
    },
  },
};

export default LiveMinuteByMinuteStatsStore;
