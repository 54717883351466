import Cookies from "js-cookie";
import { debounce } from "lodash";
import { AUTH_TOKEN_EXISTS } from '@/globals/enums/cookies.enums';
import EventBus from '@/services/EventBus';
import {
  LOGIN_STATUS_LOGGED_IN,
} from '@/globals/enums/enums';
import {
  USER_FORCE_LOGOUT_CHECK_USER_STATUS,
} from '@/globals/enums/eventBus/UserEvents.enums';

const REQUEST_PERIOD = 300000; // keep alive iapi request period in ms
const REAL_MODE = 1;
const COOKIE_NAME = `keep-alive`;
const API_GW_ENDPOINT = `/check`;
let imsLogin = true;
let timeoutId;
const user = {
  username: null,
  lastModifiedDate: null,
  loggedIn: false,
};

function getTimeStamp() {
  const date = new Date();

  return date.getTime();
}

function getData() {
  return user;
}

function getCookieValue(cname) {
  const name = `${cname }=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(`;`);

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) === ` `) {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return ``;
}

function getKeepAliveUrl(timeFromLastAction) {
  return `${window.APIGW_URL
      + API_GW_ENDPOINT }?${
      new URLSearchParams({
        millisFromLastAction: timeFromLastAction,
      })}`;
}

const renewCookie = debounce(() => {
  Cookies.set(COOKIE_NAME, getTimeStamp());
}, 250);

window.getLoggedInUserData = getData;

function setInitialValues(userName) {
  user.username = userName;
  user.lastModifiedDate = new Date();
  user.loggedIn = true;
}

function initializeListeners() {
  document.addEventListener(`click`, debounce(renewCookie, 50));
  document.addEventListener(`input`, debounce(renewCookie, 50));
}

function updateUser(username, loggedIn) {
  user.username = username;
  user.lastModifiedDate = new Date();
  user.loggedIn = loggedIn;
}

function sendCustomEvent(status) {
  EventBus.$emit(USER_FORCE_LOGOUT_CHECK_USER_STATUS, status);
}

export function cancelApigw() {
  if (timeoutId) {
    imsLogin = false;
    clearTimeout(timeoutId);
    updateUser(null, false);
  }
}

function catchError() {
  imsLogin = false;
  cancelApigw();
}

function apigwKeepAliveCall(timeFromLastAction) {
  fetch(getKeepAliveUrl(timeFromLastAction), {
    credentials: `include`,
    method: `GET`,
  }).then((response) => {
    let username = null;

    if (!response.ok) {
      catchError();
    } else {
      username = user.username;
    }
    updateUser(username, true);
    window.getLoggedInUserData = getData;
  }).catch(catchError);
}

function keepAliveTimer(timer) {
  timeoutId = setTimeout(() => {
    const timeFromLastAction = getTimeStamp() - getCookieValue(COOKIE_NAME);

    if (window.APIGW_URL && imsLogin) {
      sendCustomEvent(LOGIN_STATUS_LOGGED_IN);
      apigwKeepAliveCall(timeFromLastAction);
    }
    window.iapiKeepAlive(REAL_MODE, timeFromLastAction);
    keepAliveTimer(REQUEST_PERIOD);
  }, timer);
}

export function keepAliveApigwCall(username) {
  if (window.APIGW_URL && (`${Cookies.get(AUTH_TOKEN_EXISTS)}` === `true`)) {
    Cookies.set(COOKIE_NAME, getTimeStamp());
    initializeListeners();
    setInitialValues(username);
    keepAliveTimer(REQUEST_PERIOD);
  }
}

export function postLoggedInUserMessage(username) {
  window.postMessage({ type: `FTNUserLoggedIn`, payload: { username } }, `*`);
}
