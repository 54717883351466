import result from 'lodash/result';
import Cookies from 'js-cookie';

import { FortunaSite } from '@/globals/enums/enums';
import {
  LOCALE_CS_CZ,
  LOCALE_EN_US,
  LOCALE_PL_PL,
  LOCALE_RO_RO,
  LOCALE_SK_SK,
  LOCALE_HR_HR,
} from '@/globals/enums/Locale.enums';
import { SITE } from './constants';

const cookieLocale = Cookies.get(`locale`);

const mapping = {
  [FortunaSite.CZ]: LOCALE_CS_CZ,
  [FortunaSite.SK]: LOCALE_SK_SK,
  [FortunaSite.PL]: LOCALE_PL_PL,
  // If te cookie is set different locale is set, if is undefined RO is active
  [FortunaSite.RO]: cookieLocale ? cookieLocale.replace(`-`, `_`) : LOCALE_RO_RO,
  [FortunaSite.CP]: cookieLocale ? cookieLocale.replace(`-`, `_`) : LOCALE_RO_RO,
  [FortunaSite.HR]: cookieLocale ? cookieLocale.replace(`-`, `_`) : LOCALE_HR_HR,
};

const DEFAULT_LOCALE = LOCALE_EN_US;

export function getLocale() {
  return result(mapping, SITE, DEFAULT_LOCALE);
}

export function getLanguage() {
  return getLocale().split(`_`)[0];
}

export function getRequestLocale() {
  return getLocale().replace(`_`, `-`);
}

export function getDefaultLocale() {
  // extension point for logic for default locale resolution
  // different locale could be used for different site
  return DEFAULT_LOCALE;
}
