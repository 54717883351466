/* Bonus naming correlates with /public/icons/bonus/*.svg file names */

export const BONUS_TYPE_RISK_FREE_BET = `risk_free_bet`;
export const BONUS_TYPE_BONUS_MONEY = `bonus_money`;
export const BONUS_TYPE_DEPOSIT = `deposit_bonus`;
export const BONUS_TYPE_DIRECT_MONEY = `direct_money`;
export const BONUS_TYPE_ODDS_MULTIPLIER = `odds_multiplier`;
export const BONUS_TYPE_RETAIL = `retail_bonus`;
export const BONUS_DEFAULT_IMG = `/icons/bonus/bonus.svg`;

export const BONUS_ICON_SUCCESS = `success`;
export const BONUS_ICON_FAILURE = `failure`;

export const REFRESH_BONUS_OVERVIEW = `refreshBonusOverview`;
export const BONUS_MESSAGE_SHOW_PROCESSING = `disableBonusButtons`;
export const BONUS_MESSAGE_HIDE_PROCESSING = `enableBonusButtons`;

export const BONUS_AKO_TYPE = `bonus ako`;

export const BONUS_NAME_TO_TYPE_MAP = {
  'deposit bonus': BONUS_TYPE_DEPOSIT,
  'rfb bonus': BONUS_TYPE_RISK_FREE_BET,
  'odds multiplier bonus': BONUS_TYPE_ODDS_MULTIPLIER,
  'bonus money': BONUS_TYPE_BONUS_MONEY,
  'direct money': BONUS_TYPE_DIRECT_MONEY,
  'direct cash': BONUS_TYPE_DIRECT_MONEY,
  'retail bonus': BONUS_TYPE_RETAIL,
  [BONUS_AKO_TYPE]: BONUS_TYPE_ODDS_MULTIPLIER,
  active: BONUS_ICON_SUCCESS,
  offered: BONUS_ICON_FAILURE,
};

export const BONUS_STATUS_ACTIVE = `ACTIVE`;
export const BONUS_STATUS_OFFERED = `OFFERED`;
