<template>
  <div class="empty-message">
    <span>{{ message }}</span>
    <FortunaButton
      class="empty-message-reset"
      @click="resetTimeFilter"
    >
      {{ $t(`live3.offerFilter.duration.reset`) }}
    </FortunaButton>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import FortunaButton from "@fortunaweb-fe/mooncake/dist/FortunaButton";
  import { $UPDATE_SPORT_FILTER } from '@/stores/LiveSportStore';
  import { SportSortsFiltersType } from '@/globals/enums/enums';
  import { OFFER_FILTER_TYPE_ALL } from "@/globals/components/OfferFilter/Definitions";

  export default {
    name: `EmptyMessage`,
    components: {
      FortunaButton,
    },
    props: {
      message: {
        type: String,
        required: true,
      },
    },
    methods: {
      ...mapMutations(`LiveStore`, {
        updateSportFilter: $UPDATE_SPORT_FILTER,
      }),
      resetTimeFilter() {
        this.updateSportFilter({
          filterType: SportSortsFiltersType.FILTER_DURATION,
          value: OFFER_FILTER_TYPE_ALL,
        });
      },
    },
  };
</script>

<style lang="scss">
.empty-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  background: var(--color-background--darker);
  padding: 20px;

  &-reset {
    margin-top: 10px;
  }
}
</style>
