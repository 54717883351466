import padStart from 'lodash/padStart';
import { getNonNull } from '../../../globals/utils/getters';

export const EMPTY_VALUE_PLACEHOLDER = `-`;

/**
 * Check actual sport period and return Scoreboard column highlighting class
 * @param {Number} columnPeriod
 * @param  {Number} actualPeriod
 * @returns {String}
 */
export function getColumnClass(columnPeriod, actualPeriod) {
  return columnPeriod === actualPeriod ? `bg_highlight_inverse` : `def`;
}

/**
 * Check if property exist and not null with Scoreboard default value
 * @param {Object, Array} target
 * @param {String} path
 * @param {*} defaultValue
 * @returns {*}
 */
export function getScoreboardValue(target, path, defaultValue = EMPTY_VALUE_PLACEHOLDER) {
  return getNonNull(target, path, defaultValue);
}

/**
 * Check if Scoreboard column is active
 * @param {Number} columnPeriod
 * @param {Number} actualPeriod
 * @param {*} condition
 * @returns {*|boolean}
 */
export function getColumnState(columnPeriod, actualPeriod, condition = null) {
  return condition || columnPeriod <= actualPeriod;
}

/**
 * Check active period for adding partial data
 * @param {Number} partPeriodNum
 * @param {Number} actualPeriod
 * @param {Array} array
 * @param {Object} data
 */
export function addPartialByPeriod(partPeriodNum, actualPeriod, array, data) {
  if (partPeriodNum <= actualPeriod) {
    array.push(data);
  }
}

/**
 * Format date into Scoreboard format
 * @param {Object} dateObj
 * @returns {String}
 */
export function formatScoreboardDate(dateObj, shortFormat) {
  if (!dateObj) return EMPTY_VALUE_PLACEHOLDER;

  const date = dateObj.getDate();
  const month = (dateObj.getMonth() + 1);
  const year = dateObj.getFullYear();
  const hours = padStart(dateObj.getHours(), 2, `0`);
  const minutes = padStart(dateObj.getMinutes(), 2, `0`);

  if (shortFormat) {
    return `${date}.${month} ${hours}:${minutes}`;
  }

  return `${date}.${month}.${year} - ${hours}:${minutes}`;
}
