export const ODDS_BUTTON_NAME_TYPE_SHORT = `short`;
export const ODDS_BUTTON_NAME_TYPE_LONG = `long`;
export const ODDS_BUTTON_STYLE_TYPE_VERTICAL = `vertical`;
export const ODDS_BUTTON_STYLE_TYPE_HORIZONTAL = `horizontal`;
export const ODDS_BUTTON_LOCKED = `LOCKED`;

export const LOCKED_ODDS_DISPLAY_TYPES = {
  [ODDS_BUTTON_LOCKED]: `live3.odd.SSE.locked`,
  WINNING: `live3.odd.SSE.winning`,
  LOSING: `live3.odd.SSE.losing`,
  VOIDED: `live3.odd.SSE.voided`,
};
