<template>
  <FortunaIcon
    :iconName="sport"
    class="sport-icon"
    :size="size"
  />
</template>

<script>
  import FortunaIcon from "@fortunaweb-fe/mooncake/dist/FortunaIcon";

  export default {
    name: `SportIcon`,
    components: {
      FortunaIcon,
    },
    props: {
      sport: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        required: false,
        default: `auto`, // check this
      },
    },
  };

</script>

<style lang="scss">
  $baseClass: '.sport-icon';

  #{$baseClass} {
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    vertical-align: text-bottom;
  }
</style>
