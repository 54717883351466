export const LOGIN_STATUS_LOGGED_IN = `LOGGED_IN`;
export const LOGIN_STATUS_LOGGED_OUT = `LOGGED_OUT`;
export const LOGIN_FINISHED = `LIVE_LOGIN`;

export const FortunaSite = {
  CZ: `CZ`,
  SK: `SK`,
  PL: `PL`,
  RO: `RO`,
  CP: `CP`,
  HR: `HR`,
};

export const CurrencyCode = {
  CZK: `CZK`,
  EUR: `EUR`,
  PLN: `PLN`,
  RON: `RON`,
  HRK: `HRK`,
};

export const FortunaEnvironment = {
  MOCK: `MOCK`,
  DEV: `DEV`,
  TST: `TST`,
  PROD: `PROD`,
};

export const MINUTE_MS = 60000;
export const POST_MESSAGE_EVENT = `message`;

export const StreamType = {
  BETRADAR: `BETRADAR`,
  BETGENIUS: `BETGENIUS`,
  BETRADAR_AV: `BETRADAR_AV`,
  SPORTSMAN: `SPORTSMAN`,
  LIVEBOX: `LIVEBOX`,
  TWITCH: `TWITCH`,
  YOUTUBE: `YOUTUBE`,
  MIXER: `MIXER`,
  BETBAZAR: `BETBAZAR`,
  IMG: `IMG`,
  PERFORM: `PERFORM`,
  CT_SPORT: `CT_SPORT`,
  PROMO: `PROMO`,
  FALLBACK: `FALLBACK`,
  SPORT_RADIO: `SPORT_RADIO`,
};

export const TrackerType = {
  BETRADAR: `BETRADAR`,
  IMG: `IMG`,
};

export const LiveMatchStatus = {
  PENDING: `PENDING`,
  RUNNING: `RUNNING`,
  CLOSED: `CLOSED`,
  ARCHIVED: `ARCHIVED`,
};

export const SportSortsFiltersType = {
  FILTER_HAS_STREAM: `FILTER_HAS_STREAM`,
  FILTER_DURATION: `FILTER_DURATION`,
};

export const CalendarDates = {
  TODAY: `TODAY`,
  TOMORROW: `TOMORROW`,
};

export const MARKET_FILTER_BUTTONS = {
  ALL: `all`,
  COMBINABLE: `combinable`,
};

export const TicketHistoryFetchTypes = {
  UNRESOLVED: `unresolved`,
  RESOLVED: `resolved`,
};

export const DefaultMaximumVisibleMatches = 20;
export const DefaultMaximumVisibleLeagues = 20;
export const DefaultMinimumVisibleLeagues = 10;

export const STREAM_TYPE_DASH = `DASH`;
export const STREAM_TYPE_HLS = `HLS`;

export const STREAM_CONTENT_TYPE_HLS = `application/x-mpegURL`;
export const STREAM_CONTENT_TYPE_DASH = `application/dash+xml`;

export const BROWSERS = {
  SAFARI: `safari`,
  CHROME: `chrome`,
  OPERA: `opera`,
  FIREFOX: `firefox`,
  IE: `ie`,
  EDGE: `edge`,
};

export const CHAT_PROVIDERS = {
  PLAYTECH: `ChatBox`,
  YELLOWAI: `YellowAIChat`,
  NONE: `None`,
};
