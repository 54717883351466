<template>
  <transition
    enterActiveClass="expand-enter-active"
    leaveActiveClass="expand-leave-active"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<script>
  export default {
    methods: {
      beforeEnter(element) {
        requestAnimationFrame(() => {
          if (!element.style.height) {
            element.style.height = `0px`;
          }

          element.style.display = null;
        });
      },
      enter(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = `${element.scrollHeight}px`;
          });
        });
      },
      afterEnter(element) {
        element.style.height = null;
      },
      beforeLeave(element) {
        requestAnimationFrame(() => {
          if (!element.style.height) {
            element.style.height = `${element.offsetHeight}px`;
          }
        });
      },
      leave(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = `0px`;
          });
        });
      },
      afterLeave(element) {
        element.style.height = null;
      },
    },
  };
</script>

<style lang="scss">
  $animationSpeed: 300ms;
  $animationPerformanceBuffer: 100ms;

  .expand-enter-active,
  .expand-leave-active {
    overflow: hidden;
    transition: height $animationSpeed ease-out;

    .live_league_header {
      top: 0;
    }
  }

  :export {
    animationSpeed: $animationSpeed;
    animationPerformanceBuffer: $animationPerformanceBuffer;
  }
</style>
