import safeGet from 'lodash/get';
import { FortunaSite } from '@/globals/enums/enums';
import { getDefaultLocale, getLocale } from '@/config/locale';
import translation from '@/plugins/translation';
import { AlertMessage } from '@/factories/AlertMessage';
import { SEVERITY_DANGER } from '@/globals/enums/Severity.enums';
import { pluralize } from './pluralizations';

function constructPath(path, locale) {
  return path
    ? `${path}.${locale}`
    : locale;
}

export function getLocalized(object, path, defaultValue = ``, useFallback = true) {
  if (!object) {
    return null;
  }
  if (arguments.length === 2) {
    defaultValue = path;
    path = null;
  }

  let localization = safeGet(object, constructPath(path, getLocale()));

  // eslint-disable-next-line max-len
  if (!localization && useFallback) {
    localization = safeGet(object, constructPath(path, getDefaultLocale()));
  }

  return localization || defaultValue;
}

/**
 * Gets the currency symbol for given code and pluralizes it for acceptable cases
 * @param currencyCode {string} Currency code to get symbol for
 * @param number {number|string} Total amount of currency to correctly pluralize
 * @returns {string} Currency Symbol
 */
export function getCurrencySymbol(currencyCode, number = 0) {
  if (SITE_ENV === FortunaSite.RO) {
    if (typeof number !== `number` && typeof number === `string`) number = parseInt(number, 10);
    return pluralize(number, translation.getTranslatedMessage(`common.core.currency.RON`));
  }

  return translation.getTranslatedMessage(`common.core.currency.${currencyCode}`);
}

export function getTicketModeName(ticketMode) {
  return translation.getTranslatedMessage(`live3.ticket.mode.${ticketMode}`);
}

export function createErrorMessage(key, options = {}) {
  const errorMessage = AlertMessage({ key, severity: SEVERITY_DANGER });

  return {
    closable: false,
    ...errorMessage,
    ...options,
  };
}

export function getCommunicationErrorMessage(operation, opts = {}) {
  return createErrorMessage(`live3.ticket.errors.${operation}`, opts);
}
