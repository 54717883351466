<template>
  <div
    ref="scrollRoot"
    :class="extraClasses"
    class="sport-list scrollable"
  >
    <SportFavorites
      v-if="sportId === 'FAVORITES'"
      :short="short"
    />
    <SingleSport
      v-else-if="sportId"
      :sportId="sportId"
      :short="short"
      :leagueId="leagueId"
      :collapsible="false"
    />
    <template v-else>
      <SingleSport
        v-for="sport in allFilteredSports"
        :key="sport.id"
        :sportId="sport.id"
        :short="short"
        :leagueId="leagueId"
        :showMoreMatchesVisible="true"
      />
      <EmptyMessage
        v-if="allFilteredSports.length === 0"
        :message="$t(`live3.offerFilter.duration.sport.empty`)"
      />
    </template>
    <LegalDisclaimer
      v-if="!short"
    />
    <ScrollToTop
      v-if="!short"
      :viewIsScrollable="true"
      @click="() => scrollToElement()"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Velocity from 'velocity-animate';

  import constants from '@/config/constants';
  import { FEATURE_CB_LIVE } from '@/config/constants/features';
  import { SPORT_MENU_SCROLL_TO } from "@/globals/enums/eventBus/App.enums";
  import { getCSSVariableValue } from "@/globals/utils/style.utils";

  import EmptyMessage from '@/globals/components/EmptyMessage/EmptyMessage';
  import ScrollToTop from '@/globals/components/ScrollToTop/ScrollToTop';
  import LegalDisclaimer from '@/globals/components/LegalDisclaimer/LegalDisclaimer';

  import { getters as liveSportStoreGetters } from '@/stores/LiveSportStore';
  import { SPORT_GENERIC } from '@fortunaweb-fe/frontend-commons/dist/config/constants';
  import SportFavorites from './SportFavorites';
  import SingleSport from './SingleSport';

  export default {
    name: `SportList`,
    components: {
      ScrollToTop,
      EmptyMessage,
      LegalDisclaimer,
      SingleSport,
      SportFavorites,
    },
    props: {
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
      leagueId: {
        type: String,
        required: false,
        default: ``,
      },
      sportId: {
        type: String,
        required: false,
        default: SPORT_GENERIC,
      },
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        allFilteredSports: liveSportStoreGetters.SPORTS_GET_ALL_FILTERED,
      }),
      isSingleSport() {
        return this.sportId !== `FAVORITES` && this.sportId !== SPORT_GENERIC;
      },
      extraClasses() {
        return {
          'sport-list--short': this.short && !this.isFeatureSupported(FEATURE_CB_LIVE),
          'sport-list--short-cb': this.short && this.isFeatureSupported(FEATURE_CB_LIVE),
          'sport-list--single-sport': this.isSingleSport,
        };
      },
    },
    created() {
      this.FAVORITES_SPORT_ID = constants.FAVORITES_SPORT_ID;
      this.$eventBus.$on(SPORT_MENU_SCROLL_TO, (matchRef) => {
        const sportHeaderHeight = getCSSVariableValue(`--sport-header-height`);
        const leagueHeaderHeight = getCSSVariableValue(`--league-header-height`);

        this.scrollToElement(matchRef, -1 * (sportHeaderHeight + leagueHeaderHeight));
      });
    },
    methods: {
      scrollToElement($element = this.$refs.scrollRoot.firstElementChild, offset = 0) {
        // offset is the value of market_group margin top
        return Velocity(
          $element,
          `scroll`,
          {
            container: this.$refs.scrollRoot,
            offset,
          },
        );
      },
    },
  };

</script>

<style lang="scss">
.sport-list {
  width: 100%;
  overflow-y: auto;

  &--short {
    width: 100%;
    max-width: 240px;
    overflow-x: hidden;

    &-cb {
      max-width: 280px;
    }
  }

  &__sports {
    &-move {
      transition: transform .3s;
    }
  }

  &:not(.sport-list--single-sport) .sport--empty {
    display: none;
  }
}
</style>
