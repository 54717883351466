import {
  USER_FORCE_LOGOUT_CHECK_USER_STATUS,
  USER_FORCE_LOGOUT_USER_LOGGED_OFF,
} from '@/globals/enums/eventBus/UserEvents.enums';
import {
  USER_CONSENT_STATUS_POSTPONED,
} from '@/globals/enums/UserConsent.enums';
import api from '@/config/api/live';
import {
  resolveEndpoint,
  custom03Field,
} from '@/config/constants';
import store from '@/config/store';
import * as timeStoreMutations from '@/stores/TimeStore/mutationTypes';
import * as userStoreActions from '@/stores/UserStore/actions';
import * as userStoreGetters from '@/stores/UserStore/getters';
import EventBus from '@/services/EventBus';
import { initLastLoginTimer, parseDate } from '@/services/TimeService';
import { LOGIN_STATUS_LOGGED_OUT } from '@/globals/enums/enums';
import Cookies from 'js-cookie';
import { AUTH_TOKEN_EXISTS } from '@/globals/enums/cookies.enums';
import axios from 'axios';
import { keepAliveApigwCall, postLoggedInUserMessage } from '../KeepAliveService';

export function sessionCheck() {
  return api.post(resolveEndpoint(`SESSION_CHECK`));
}

export function getNotificationToken() {
  return axios.create({
    timeout: 10000,
    withCredentials: true,
    headers: { Pragma: `no-cache` },
    baseURL: api.defaults.baseURL,
  }).get(resolveEndpoint(`NOTIFICATION_TOKEN_APIGW`)).then((response) => {
    store.dispatch(`UserStore/${userStoreActions.AUTH_NOTIFICATION_TOKEN_SUCCESS}`, response.data?.notification_token);
  });
}
/**
 * User login
 * @param {Object} loginData
 * @param {String} loginData.username - Username to login with
 * @param {String} loginData.ims_temporary_token - TempToken to login with
 * @returns {Promise<{data?: *}>}
 */
export function login(loginData) {
  store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_PENDING}`);
  console.log(`Sending login request to api for user ${loginData.username} `);
  return api
    .get(resolveEndpoint(`LOGIN_APIGW`), { params: loginData })
    .then((response) => {
      store.dispatch(`UserStore/${userStoreActions.AUTH_NOTIFICATION_TOKEN_SUCCESS}`, response.data?.notificationToken);
      if (LOCAL_ENV === `local`) {
        console.log(`Local development mode. Adding access_token to authorization header`);
        api.defaults.headers.Authorization = response.headers.authorization;
      }
      Cookies.set(AUTH_TOKEN_EXISTS, `true`);
      keepAliveApigwCall(loginData.username);
      postLoggedInUserMessage(loginData.username);
      // eslint-disable-next-line no-use-before-define
      return getUserInfo();
    })
    .catch((err) => {
      console.log(`ERROR: ${err} `);
      store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_ERROR}`, err);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ beforeLoginMessages: err.response?.data?.messages });
    });
}

export function getUserInfo(refreshValue = false) {
  console.log(`Sending getUserInfo request to api for login.`);
  return api
    .get(resolveEndpoint(`USER_INFO`), { params: { refresh: refreshValue } })
    .then(({ data }) => {
      console.log(`Login status: ${data.status}`);
      EventBus.$emit(USER_FORCE_LOGOUT_CHECK_USER_STATUS, data.status);
      if (data && data.userInfo && data.userInfo.lastLogin) {
        initLastLoginTimer(parseDate(data.userInfo.lastLogin).getTime());
      }

      return store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_SUCCESS}`, data);
    })
    .catch((err) => {
      console.log(`ERROR: ${err} `);
      EventBus.$emit(USER_FORCE_LOGOUT_CHECK_USER_STATUS, LOGIN_STATUS_LOGGED_OUT);
      store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_ERROR}`, err);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ beforeLoginMessages: err.response?.data?.messages });
    });
}

export function getLightweightClientData() {
  return api
    .get(resolveEndpoint(`LIGHWEIGHT_OVERVIEW`))
    .then(({ data }) => data)
    .catch((err) => {
      console.log(`ERROR: ${err} `);
      store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_ERROR}`, err);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ beforeLoginMessages: err.response?.data?.messages });
    });
}

export async function verifySession(payload = { validateSessionTokens: [{ }] }) {
  try {
    return await api.post(resolveEndpoint(`VALIDATE_SESSION`), payload);
  } catch (error) {
    if (error.response.status === 409) {
      return null;
    }
    return error;
  }
}

export function fetchUserInfo(refreshBalance = false) {
  store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_PENDING}`);
  return api
    .get(resolveEndpoint(`USER_INFO`), { params: { refresh: refreshBalance } })
    // eslint-disable-next-line consistent-return
    .then(async ({ data }) => {
      const result = await verifySession();

      if (result) {
        EventBus.$emit(USER_FORCE_LOGOUT_CHECK_USER_STATUS, data.status);
        return store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_SUCCESS}`, data);
      }
    })
    .catch((err) => {
      store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_ERROR}`, err);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ afterLoginMessages: err.response?.data?.messages });
    });
}

export function checkTokenValidity() {
  return axios.create({
    timeout: 10000,
    withCredentials: true,
    headers: { Pragma: `no-cache` },
    baseURL: api.defaults.baseURL,
  }).get(resolveEndpoint(`CHECK_TOKEN`));
}

export function resolveLogout(allSessions) {
  if (store.getters[`UserStore/${userStoreGetters.IS_DOING_LOGOUT}`]) {
    return Promise.resolve();
  }

  console.log(`Sending Logout request to api.`);
  store.dispatch(`UserStore/${userStoreActions.DOING_LOGOUT}`);
  store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_PENDING}`);

  const endpoint = allSessions ? resolveEndpoint(`LOGOUT_WITH_BEHAVIOUR`, { behavior: `all` }) : resolveEndpoint(`LOGOUT`);

  return api.get(endpoint)
    .then(({ data }) => {
      if (LOCAL_ENV === `local`) {
        console.log(`Local development mode. Removing access_token from authorization header`);
        delete api.defaults.headers.Authorization;
      }
      Cookies.remove(AUTH_TOKEN_EXISTS);
      EventBus.$emit(USER_FORCE_LOGOUT_USER_LOGGED_OFF);
      store.commit(`TimeStore/${timeStoreMutations.SET_TIME_SINCE_LAST_LOGIN}`, 0);
      store.dispatch(`UserStore/${userStoreActions.AUTH_NOTIFICATION_TOKEN_SUCCESS}`, null);
      const userInfo = store.getters[`UserStore/${userStoreGetters.GET_USER_INFO}`].id;

      window.postMessage({ type: `FTNUserLoggedOut`, payload: { userInfo } }, `*`);
      return store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_SUCCESS}`, data);
    })
    .catch((err) => {
      store.dispatch(`UserStore/${userStoreActions.AUTH_OPERATION_ERROR}`, err);
    })
    .finally(() => {
      store.dispatch(`UserStore/${userStoreActions.DOING_LOGOUT_FINISHED}`);
    });
}

export function logoutAll() {
  return resolveLogout(true);
}

export function logout() {
  return resolveLogout(false);
}

export function getUserReminderTimeout() {
  return api.get(resolveEndpoint(`USER_REMINDER`));
}

/**
 * Gets the user consents
 * @returns {array}
 */
export function getUserConsents() {
  return api
    .get(resolveEndpoint(`CONSENTS`))
    .then(({ data }) => data.reduce((acc, { show, ...rest }) => {
      acc.push(rest);
      return acc;
    }, []));
}

/**
 *
 * @param {number} type
 * @param {string} status
 * @returns {Promise}
 */
export function setUserConsentStatus(type, status = USER_CONSENT_STATUS_POSTPONED) {
  return api.post(resolveEndpoint(`SET_CONSENT`), { type, status });
}

export function verifyPhoneNumber(code, token) {
  return api.post(resolveEndpoint(`USER_PHONE_NUMBER_VERIFY`), { code, token });
}

export function fetchResponsibleGamingMessage() {
  return api.get(resolveEndpoint(`RESPONSIBLE_GAMING`));
}

export function setHideBalance(currentHideBalanceStatus) {
  return api.post(resolveEndpoint(`USER_HIDE_BALANCE`), { isHidden: currentHideBalanceStatus });
}

export function getHideBalance() {
  return api.get(resolveEndpoint(`USER_HIDE_BALANCE`));
}

export function getPlayerTags() {
  return api.get(resolveEndpoint(`TRACKING_USER_TAGS`));
}

function filterDataByCustom03(data) {
  const custom03Item = data.find((item) => item.name === custom03Field.CUSTOM03);

  return custom03Item ? custom03Item.value : null;
}

export function getCustom03() {
  return getPlayerTags()
    .then((playerTags) => {
      const filteredData = filterDataByCustom03(playerTags.data);

      return filteredData;
    });
}
