<template>
  <transition name="scroll_to_top">
    <button
      v-show="!isScrollAtTop"
      ref="root"
      :class="extraClasses"
      class="scroll_to_top"
      @click="$emit('click')"
    >
      <Icon name="up-open" />
    </button>
  </transition>
</template>

<script>

  import throttle from 'lodash/throttle';

  import Icon from '../Icon/Icon';

  export default {
    components: {
      Icon,
    },
    props: {
      viewIsScrollable: {
        type: Boolean,
        required: true,
      },
      scrollParent: {
        type: [Element, String],
        required: false,
        default: `scrollable`,
      },
      threshold: {
        type: Number,
        required: false,
        default: 50,
      },
      hideWhenAtTop: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        isScrollAtTop: true,
        topTimeout: null,
      };
    },
    computed: {
      scrollContainer() {
        if (this.hideWhenAtTop) {
          let { scrollParent } = this;

          if (typeof scrollParent === `string`) {
            const { root } = this.$refs;
            const parentClass = scrollParent;
            let parent = root.parentElement;

            while (parent && parent.className.indexOf(parentClass) === -1) {
              parent = parent.parentElement;
            }

            scrollParent = parent;
          }
          return scrollParent;
        }
        return null;
      },
      extraClasses() {
        return {
          'scroll_to_top--show': this.viewIsScrollable,
          'scroll_to_top--hide': !this.viewIsScrollable,
        };
      },
    },
    mounted() {
      if (this.hideWhenAtTop) {
        this.scrollContainer.addEventListener(
          `scroll`,
          throttle(this.hideIfAtTop, 100, { trailing: true }),
          // eslint-disable-next-line no-underscore-dangle
          window.__FORTUNA_PASSIVE_EVENTS__ ? { passive: true } : false,
        );
      }
    },
    methods: {
      hideIfAtTop({ target }) {
        const { threshold } = this;
        const currentScrollPosition = target.scrollTop;

        clearTimeout(this.topTimeout);

        if (threshold > currentScrollPosition) {
          // eslint-disable-next-line no-return-assign
          this.topTimeout = setTimeout(() => (this.isScrollAtTop = true), 500);
        } else {
          this.isScrollAtTop = false;
        }
      },
    },
  };

</script>

<style lang="scss">
  $base_class: '.scroll_to_top';
  $size: 35px;

  #{$base_class} {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    width: $size;
    height: $size;

    bottom: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: var(--color-primary);
    color: var(--color-primary-contrast-text);
    opacity: 0.2;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
    transition: opacity .15s linear, box-shadow .15s linear;
    z-index: z_index_for_layer($CONTROLS_LAYER_2);

    &:hover {
      opacity: 1;
      box-shadow: 0 0 10px var(--color-light-shadow);
    }

    &--hide {
      display: none;
    }

    &--show {
      display: block;
    }

    &-enter-active {
      animation: fade_scroll_to_top .3s linear forwards;
    }

    &-leave-active {
      animation: fade_scroll_to_top .3s linear reverse;
    }
  }

  @keyframes fade_scroll_to_top {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.2;
    }
  }

</style>
