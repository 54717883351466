export const LOCALE_CS_CZ = `cs_CZ`;
export const LOCALE_SK_SK = `sk_SK`;
export const LOCALE_PL_PL = `pl_PL`;
export const LOCALE_RO_RO = `ro_RO`;
export const LOCALE_EN_US = `en_US`;
export const LOCALE_HR_HR = `hr_HR`;

export const COOKIE_LOCALE_RO_RO = `ro-RO`;
export const COOKIE_LOCALE_EN_US = `en-US`;
export const COOKIE_LOCALE_HR_HR = `hr-HR`;
