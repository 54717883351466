<template>
  <router-link
    v-if="count > 0"
    :to="matchDetailRoute"
    class="additional_markets_button"
  >
    +{{ count }}
  </router-link>
</template>

<script>
  import { ROUTE_MATCH_DETAIL } from '../../../config/router/routes';

  export default {
    name: `AdditionalMarketsButton`,
    props: {
      matchId: {
        type: String,
        required: true,
      },
      count: {
        type: Number,
        required: true,
      },
    },
    computed: {
      matchDetailRoute() {
        return {
          name: ROUTE_MATCH_DETAIL,
          params: {
            matchId: this.matchId,
          },
        };
      },
    },
  };

</script>

<style lang="scss">
  .additional_markets_button {
    text-align: center;
    text-decoration: none;
    display: block;
    line-height: $match_row_height;
    font-size: 12px;
    vertical-align: middle;
    transition: background-color .3s linear;
    @if ($theme == 'light') {
      color: var(--color-secondary-text);
    } @else {
      color: var(--color-secondary-text--lighter);
    }
    background-color: var(--color-background--darkest);
    font-weight: 500;
    width: 100%;

    &:focus,
    &:hover {
      @if ($theme == 'light') {
        color: var(--color-secondary-text);
        background-color: var(--color-background-lightest-2);
      } @else {
        color: var(--color-primary-text);
        background-color: var(--color-background--darkest);
      }
    }
  }
</style>
