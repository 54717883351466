import { tryMirrorTargets } from '@/services/http/CombinedBetslipHttpHandler';
import { IS_FEATURE_SUPPORTED } from '@/stores/AppConfigurationStore/constants';
import store from '@/config/store';
import { actions as marketGroupStoreActions } from '@/stores/LiveMarketGroupStore';
import {
  actions as marketStoreActions,
  getters as marketStoreGetters,
} from '@/stores/LiveMarketStore';
import { getters as ticketStoreGetters } from '@/stores/ticket/TicketStore';
import { actions as liveOddsActions } from '@/stores/LiveOddsStore';
import { FEATURE_CB_LIVE } from '@/config/constants/features';

function allowTryMirrorTargets(market) {
  const ticketItemForMarket = store.getters[`TicketStore/${ticketStoreGetters.GET_TICKET_ITEM_FOR_MARKET_ROW_ID}`](market.id);

  return ticketItemForMarket && ticketItemForMarket.reflex;
}

export function deleteMarketRowById(marketRowId) {
  const market = store.getters[`LiveStore/${marketStoreGetters.GET_MARKET_BY_MARKET_ROW_ID}`](marketRowId);

  if (store.getters[`AppConfigurationStore/${IS_FEATURE_SUPPORTED}`](FEATURE_CB_LIVE)
    && market && allowTryMirrorTargets(market)) {
    tryMirrorTargets();
  }

  store.dispatch(`LiveStore/${marketStoreActions.DELETE_MARKET_ROW}`, { marketRowId });

  // 2018-07-14 ~jbe
  // DELETE_MARKET_ROW is async - do we have a guarantee
  // that market data has already changed in store
  // or should we rather delete following stuff inside `then`?
  if (market && market.marketRows.length === 0) {
    store.dispatch(`LiveStore/${liveOddsActions.ODDS_REMOVE}`, market.id);
    store.dispatch(`LiveStore/${marketGroupStoreActions.UNLINK_MARKET_FROM_MARKET_GROUP}`, market);
    store.dispatch(`LiveStore/${marketStoreActions.DELETE_MARKET}`, { id: market.id });
  }
}
