import Vue from 'vue';
import { getters as liveMarketStoreGetters } from '@/stores/LiveMarketStore';
import { MARKET_FILTER_BUTTONS } from "@/globals/enums/enums";
import { BY_DISPLAY_ORDER } from "@/globals/sportdata/comparators";
import { getterNames as liveTopMarketStoreGetters } from './LiveTopMarketStore';
import { getters as ticketStoreGetters } from './ticket/TicketStore';

export const types = {
  CREATE_MARKET_GROUP: `CREATE_MARKET_GROUP`,
  DELETE_MARKET_GROUP: `DELETE_MARKET_GROUP`,
  ADD_MARKET_GROUP_LIST: `ADD_MARKET_GROUP_LIST`,
  LINK_MARKET_TO_MARKET_GROUP: `LINK_MARKET_TO_MARKET_GROUP`,
  UNLINK_MARKET_FROM_MARKET_GROUP: `UNLINK_MARKET_FROM_MARKET_GROUP`,
  SET_FAVORITE_MARKETS_FOR_MATCH: `SET_FAVORITE_MARKETS_FOR_MATCH`,
  MOVE_MARKET_TO_FAVORITES_MARKET_GROUP: `MOVE_MARKET_TO_FAVORITES_MARKET_GROUP`,
  MOVE_MARKET_TO_ORIGINAL_MARKET_GROUP: `MOVE_MARKET_TO_ORIGINAL_MARKET_GROUP`,
  LINK_MARKET_TO_FAVORITES_MARKET_GROUP: `LINK_MARKET_TO_FAVORITES_MARKET_GROUP`,
  UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP: `UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP`,
  FILTER_BY_MARKET_ID: `FILTER_BY_MARKET_ID`,
  DELETE_MATCH_MARKET_GROUPS: `DELETE_MATCH_MARKET_GROUPS`,
};

export const actions = {
  CREATE_MARKET_GROUP: `createMarketGroup`,
  DELETE_MARKET_GROUP: `deleteMarketGroup`,
  ADD_MARKET_GROUP_LIST: `addMarketGroupList`,
  LINK_MARKET_TO_MARKET_GROUP: `linkMarketListToMarketGroup`,
  UNLINK_MARKET_FROM_MARKET_GROUP: `unlinkMarketListFromMarketGroup`,
  SET_FAVORITE_MARKETS_FOR_MATCH: `setFavoriteMarketsForMatch`,
  MOVE_MARKET_TO_FAVORITES_MARKET_GROUP: `moveMarketToFavoritesMarketGroup`,
  MOVE_MARKET_TO_ORIGINAL_MARKET_GROUP: `moveMarketToOriginalMarketGroup`,
  UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP: `unlinkMarketFromFavoritesMarketGroup`,
  DELETE_MATCH_MARKET_GROUPS: `deleteMatchMarketGroups`,
};

export const getters = {
  GET_MARKET_GROUP_BY_ID: `GET_MARKET_GROUP_BY_ID`,
  GET_MARKET_GROUPS_BY_MATCH_ID: `GET_MARKET_GROUPS_BY_MATCH_ID`,
  GET_MARKET_GROUP_FILTERS_BY_MATCH_ID: `GET_MARKET_GROUP_FILTERS_BY_MATCH_ID`,
  GET_MARKET_GROUPS_COUNT_BY_MATCH_ID: `GET_MARKET_GROUPS_COUNT_BY_MATCH_ID`,
  GET_FILTERED_MARKET_GROUPS_BY_MATCH_ID: `GET_FILTERED_MARKET_GROUPS_BY_MATCH_ID`,
  GET_FILTERED_MARKET_GROUP_IDS_BY_MATCH_ID: `GET_FILTERED_MARKET_GROUP_IDS_BY_MATCH_ID`,
  IS_MARKET_IN_MATCH: `IS_MARKET_IN_MATCH`,
  MARKET_GROUPS_BY_MATCH_ID: `MARKET_GROUPS_BY_MATCH_ID`,
  GET_FILTERED_MARKET_GROUP_ID: `GET_FILTERED_MARKET_GROUP_ID`,
};

const changeMarketGroupIdFilter = (state, marketGroupId) => {
  Vue.set(state, `marketGroupIdFiltered`, marketGroupId);
};

const liveMatchStore = {
  state: {
    // marketGroupId -> marketGroup
    marketGroups: {},
    // [matchId] -> [marketGroupId1, marketGroupId2, ...]
    groupsByMatch: {},
    // [matchId] -> [marketId1, marketId2 ]
    favoriteMarketsByMatch: {},
    marketGroupIdFiltered: MARKET_FILTER_BUTTONS.ALL,
  },
  mutations: {
    [types.CREATE_MARKET_GROUP]: (state, { matchId, marketGroup }) => {
      Vue.set(state.marketGroups, marketGroup.id, marketGroup);
      if (!state.groupsByMatch[matchId]) {
        // make it reactive
        Vue.set(state.groupsByMatch, matchId, []);
      }
      const { id: marketGroupId } = marketGroup;

      state.groupsByMatch[matchId] = [
        ...state.groupsByMatch[matchId].filter((id) => id !== marketGroupId),
        marketGroupId,
      ];
    },
    [types.DELETE_MARKET_GROUP]: (state, { matchId, marketGroupId }) => {
      const marketGroup = state.marketGroups[marketGroupId];
      const matchGroupIds = state.groupsByMatch[matchId];

      Vue.delete(state.marketGroups, marketGroupId);

      if (state.marketGroupIdFiltered === marketGroupId) {
        changeMarketGroupIdFilter(state, MARKET_FILTER_BUTTONS.ALL);
      }

      if (matchGroupIds) {
        const index = matchGroupIds.indexOf(marketGroup.id);

        matchGroupIds.splice(index, 1);
        if (matchGroupIds.length === 0) {
          Vue.delete(state.groupsByMatch, matchId);
        }
      }
    },
    [types.DELETE_MATCH_MARKET_GROUPS]: (state, matchId) => {
      const matchGroups = state.groupsByMatch[matchId];

      if (matchGroups?.length) {
        matchGroups.forEach((groupId) => Vue.delete(state.marketGroups, groupId));
        Vue.delete(state.groupsByMatch, matchId);
      }
    },
    [types.ADD_MARKET_GROUP_LIST]: (state, { matchId, marketGroups }) => {
      if (!state.groupsByMatch[matchId]) {
        // make it reactive
        Vue.set(state.groupsByMatch, matchId, []);
      }
      const newMarketGroupIdList = new Set(state.groupsByMatch[matchId]);

      Object.keys(marketGroups).forEach((id) => {
        newMarketGroupIdList.add(id);
      });
      state.groupsByMatch[matchId] = [...newMarketGroupIdList];
      state.marketGroups = {
        ...state.marketGroups,
        ...marketGroups,
      };
    },
    [types.LINK_MARKET_TO_MARKET_GROUP]: (state, market) => {
      const { id, marketGroupIds } = market;
      const marketGroups = marketGroupIds.map((marketGroupId) => state.marketGroups[marketGroupId]);

      marketGroups.forEach((marketGroup) => {
        marketGroup.markets = [
          ...(marketGroup.markets || []).filter((marketId) => marketId !== id),
          id,
        ];
      });
    },
    [types.UNLINK_MARKET_FROM_MARKET_GROUP]: (state, market) => {
      const marketGroups = market.marketGroupIds.map((marketGroupId) => state.marketGroups[marketGroupId]);

      if (!marketGroups.length) {
        if (NODE_ENV !== `production`) {
          // eslint-disable-next-line no-console
          console.warn(`Attempt to unlink market from non-existing market group (ids: ${market.marketGroupIds}`);
        }
        return;
      }
      marketGroups.forEach((marketGroup) => {
        marketGroup.markets = marketGroup.markets.filter((id) => id !== market.id);
      });
    },
    [types.SET_FAVORITE_MARKETS_FOR_MATCH](state, { matchId, markets }) {
      Vue.set(state.favoriteMarketsByMatch, matchId, markets);
    },
    [types.LINK_MARKET_TO_FAVORITES_MARKET_GROUP](state, { matchId, market }) {
      const {
        id: marketId,
      } = market;
      const favoriteMarkets = [
        ...(state.favoriteMarketsByMatch[matchId] || []).filter((id) => id !== marketId),
        marketId,
      ];

      Vue.set(state.favoriteMarketsByMatch, matchId, favoriteMarkets);
    },
    [types.UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP](state, { matchId, market }) {
      const {
        id: marketId,
      } = market;
      const favoriteMarkets = (state.favoriteMarketsByMatch[matchId] || [])
        .filter((id) => id !== marketId);

      Vue.set(state.favoriteMarketsByMatch, matchId, favoriteMarkets);
    },
    [types.FILTER_BY_MARKET_ID](state, marketGroupId) {
      changeMarketGroupIdFilter(state, marketGroupId);
    },
  },
  actions: {
    [actions.CREATE_MARKET_GROUP]({ commit }, data) {
      commit(types.CREATE_MARKET_GROUP, data);
    },
    [actions.ADD_MARKET_GROUP_LIST]({ commit }, data) {
      commit(types.ADD_MARKET_GROUP_LIST, data);
    },
    [actions.DELETE_MARKET_GROUP]({ commit }, data) {
      commit(types.DELETE_MARKET_GROUP, data);
    },
    /**
     * Deletes market groups from the store by matchId,
     *
     * @returns array of markets, which are safe to delete
     * (excluding top markets and markets on the ticket)
     */
    [actions.DELETE_MATCH_MARKET_GROUPS]({ commit, state, rootGetters }, matchId) {
      const isMarketOnTicket = rootGetters[`TicketStore/${ticketStoreGetters.IS_MARKET_ON_TICKET}`];
      // eslint-disable-next-line max-len
      const matchTopMarketIds = rootGetters[`LiveStore/${liveTopMarketStoreGetters.GET_TOP_MARKETS_BY_MATCH_ID}`](matchId);
      const getMarketById = (id) => rootGetters[`LiveStore/${liveMarketStoreGetters.GET_MARKET_BY_ID}`](id) || {};

      const groupMarketIds = (state.groupsByMatch[matchId] || [])
        .reduce((acc, id) => {
          const { markets } = state.marketGroups[id];
          const filteredMarkets = markets
            .filter((marketId) => !isMarketOnTicket(marketId))
            .filter((marketId) => {
              const marketIds = Object.values(getMarketById(marketId).marketRows || {});

              return !marketIds.map((mId) => matchTopMarketIds.includes(mId)).includes(true);
            });

          return [...acc, ...filteredMarkets];
        }, []);

      commit(types.DELETE_MATCH_MARKET_GROUPS, matchId);
      return groupMarketIds;
    },
    [actions.LINK_MARKET_TO_MARKET_GROUP]({ commit }, data) {
      commit(types.LINK_MARKET_TO_MARKET_GROUP, data);
    },
    [actions.UNLINK_MARKET_FROM_MARKET_GROUP]({ commit }, data) {
      commit(types.UNLINK_MARKET_FROM_MARKET_GROUP, data);
    },
    [actions.UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP]({ commit }, payload) {
      commit(types.UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP, payload);
    },
    [actions.SET_FAVORITE_MARKETS_FOR_MATCH]({ commit }, payload) {
      commit(types.SET_FAVORITE_MARKETS_FOR_MATCH, payload);
    },
    [actions.MOVE_MARKET_TO_FAVORITES_MARKET_GROUP]({ commit }, payload) {
      const { market } = payload;

      commit(types.UNLINK_MARKET_FROM_MARKET_GROUP, market);
      commit(types.LINK_MARKET_TO_FAVORITES_MARKET_GROUP, payload);
    },
    [actions.MOVE_MARKET_TO_ORIGINAL_MARKET_GROUP]({ commit }, payload) {
      const { market } = payload;

      commit(types.UNLINK_MARKET_FROM_FAVORITES_MARKET_GROUP, payload);
      commit(types.LINK_MARKET_TO_MARKET_GROUP, market);
    },
  },
  getters: {
    [getters.GET_FILTERED_MARKET_GROUP_ID](state) {
      return state.marketGroupIdFiltered;
    },
    [getters.IS_MARKET_IN_MATCH](state) {
      return (matchId, marketGroupId) => (state.groupsByMatch[matchId] || [])
        .indexOf(marketGroupId) > -1;
    },
    [getters.GET_MARKET_GROUP_BY_ID](state) {
      return (marketGroupId) => state.marketGroups[marketGroupId] || null;
    },
    /**
     * Return marketGroups filtered based on selected AKO filters
     * In case of COMBINABLE ako filter selected function returns only
     * groups with markets which are combinable together.
     * In case some markets are on ticket - function returns only markets combinable
     * with those
     */
    [getters.GET_FILTERED_MARKET_GROUPS_BY_MATCH_ID](state, moduleGetters, rootState, rootGetters) {
      const { marketsMapping } = rootState.LiveStore.LiveMarketStore;

      return (matchId) => {
        const marketGroups = moduleGetters[getters.GET_MARKET_GROUPS_BY_MATCH_ID](matchId);

        if (state.marketGroupIdFiltered === MARKET_FILTER_BUTTONS.COMBINABLE) {
          const allMarketIds = rootGetters[`LiveStore/GET_MATCH_MARKET_IDS`](matchId);
          const selectedOdds = rootGetters[`LiveStore/SELECTED_ODDS`];

          const selectedMarketRowIds = Object.keys(selectedOdds)
            .filter((selectedRowId) => allMarketIds
              .includes(marketsMapping[selectedRowId]));
          const someMarketIsSelected = selectedMarketRowIds.length !== 0;

          const combinableMarkets = rootGetters[`LiveStore/${liveMarketStoreGetters.GET_COMBINABLE_MARKETS}`](matchId);

          let combinableMarketIds = combinableMarkets.map((market) => market.id);

          if (someMarketIsSelected) {
            const combinableMarketRowIds = combinableMarkets.reduce((acc, market) => {
              acc.push(...market.marketRows);
              return acc;
            }, []);

            const isSelectedMarketIdCombinable = combinableMarketRowIds
              .some((combinableMarketRowId) => selectedMarketRowIds.includes(combinableMarketRowId));

            if (!isSelectedMarketIdCombinable) return [];

            const combinableMarketRowIdsToSelected = combinableMarketRowIds.filter(
              (marketRowId) => !rootGetters[`LiveStore/${liveMarketStoreGetters.IS_MARKET_SUPPORTIVE_TO_SELECTED}`](marketRowId),
            );

            combinableMarketIds = combinableMarketRowIdsToSelected
              .map((marketRowId) => marketsMapping[marketRowId])
              .filter((marketId) => marketId);

            const selectedMarketIds = Object.keys(selectedMarketRowIds.reduce((acc, rowId) => ({
              ...acc,
              [marketsMapping[rowId]]: rowId,
            }), {}));

            if (combinableMarketIds.length === 1 && selectedMarketIds.length === 1) return [];
          }

          return marketGroups
            .map((group) => ({
              ...group,
              markets: group.markets.filter((marketId) => combinableMarketIds.includes(marketId)),
            }))
            .filter((group) => group.markets.length > 0)
            .sort(BY_DISPLAY_ORDER);
        }
        const isDefaultFilterSelected = Object.values(MARKET_FILTER_BUTTONS).includes(state.marketGroupIdFiltered);

        return marketGroups
          .filter(({ id: groupMarketId } = {}) => isDefaultFilterSelected || groupMarketId === state.marketGroupIdFiltered)
          .sort(BY_DISPLAY_ORDER);
      };
    },
    [getters.GET_FILTERED_MARKET_GROUP_IDS_BY_MATCH_ID](state, moduleGetters) {
      return (matchId) => moduleGetters[getters.GET_FILTERED_MARKET_GROUPS_BY_MATCH_ID](matchId).map((marketGroup) => marketGroup.id);
    },
    [getters.GET_MARKET_GROUPS_BY_MATCH_ID](state) {
      return (matchId) => {
        const marketGroupIds = state.groupsByMatch[matchId] || [];

        return marketGroupIds
          .map((marketGroupId) => state.marketGroups[marketGroupId])
          .filter(({ markets = [] } = {}) => markets.length > 0);
      };
    },
    [getters.GET_MARKET_GROUP_FILTERS_BY_MATCH_ID](state, moduleGetters) {
      return (matchId) => moduleGetters[getters.GET_MARKET_GROUPS_BY_MATCH_ID](matchId)
        .sort(BY_DISPLAY_ORDER)
        .map((marketGroup) => {
          const filter = {
            names: marketGroup.names,
            id: marketGroup.id,
            displayWithPriority: marketGroup.displayWithPriority,
          };

          return filter;
        });
    },
    [getters.GET_MARKET_GROUPS_COUNT_BY_MATCH_ID](state) {
      return (matchId) => state.groupsByMatch[matchId]
        ?.map((marketGroupId) => state.marketGroups[marketGroupId])
        .filter(({ markets = [] } = {}) => markets.length > 0)
        .length || 0;
    },
    [getters.MARKET_GROUPS_BY_MATCH_ID](state) {
      return (matchId) => state.groupsByMatch[matchId] || [];
    },
  },
};

export default liveMatchStore;
