<template>
  <div
    v-if="isLiveMatch"
    class="info-action--live"
  >
    <Badge
      type="live"
    >
      {{ $t(`live3.badge.live.short`) }}
    </Badge>
  </div>
</template>

<script>
  import Badge from "@fortunaweb-fe/mooncake/dist/Badge";

  export default {
    name: `LiveBadge`,
    components: {
      Badge,
    },
    props: {
      isLiveMatch: {
        type: Boolean,
        required: true,
      },
    },
  };

</script>

<style lang="scss">
  .info-action--live {
    grid-area: live-badge;
    justify-self: end;
    span.badge {
      margin-right: 6px;
    }
  }
</style>
