<template>
  <div
    :class="[`sport--${sport.sport}`, matchCount === 0 && `sport--empty`]"
  >
    <SportHeader
      v-if="matchCount > 0"
      :marketsVisible.sync="marketsVisibleOnMatchDetail"
      :isAllLeaguesOpen="isAllLeaguesOpen"
      :name="sport.names"
      :matchCount="matchCount"
      :sportType="sport.sport"
      :collapseState="isCollapsed"
      :short="short"
      :extended="collapsible"
      :sportId="sportId"
      @toggleLeagues="toggleLeagues"
      @toggleSport="toggleSport"
    />
    <EmptyMessage
      v-else
      :message="$t(`live3.offerFilter.duration.sport.empty`)"
    />
    <HeightTransitionUtil>
      <div v-if="!isCollapsed">
        <LeagueList
          :key="sportId"
          :sportId="sportId"
          :sportType="sport.sport"
          :selectedLeagueId="leagueId"
          :collapsingCategory="info.category"
          :short="short"
          :showTopMarket="marketsVisibleOnMatchDetail"
          :limitMatches="limitMatches"
        />
        <SportShowMore
          v-if="limitMatches"
          :sportId="sportId"
          :matchCount="matchCount"
        />
      </div>
    </HeightTransitionUtil>
  </div>
</template>
<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import {
    getters as liveSportStoreGetters,
    actions as liveSportActions,
    $SET_FAVORITE_COLLAPSE_STATE,
  } from '@/stores/LiveSportStore';
  import { getters as liveMatchGetters } from '@/stores/LiveMatchStore';
  import {
    IS_ALL_LEAGUES_OPEN,
    TOGGLE_LEAGUES_BY_SPORT,
  } from "@/stores/LiveLeagueStore/constants";
  import { FEATURE_LIMITED_OVERVIEW } from '@/config/constants/features';
  import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';

  import LeagueList from '@/globals/components/LeagueList/LeagueList';
  import EmptyMessage from '@/globals/components/EmptyMessage/EmptyMessage';
  import SportHeader from '@/globals/components/Sport/SportHeader';
  import HeightTransitionUtil from '@/globals/components/HeightTransitionUtil/HeightTransitionUtil';
  import SportShowMore from '@/globals/components/Sport/SportShowMore';

  export default {
    components: {
      LeagueList,
      EmptyMessage,
      SportHeader,
      HeightTransitionUtil,
      SportShowMore,
    },
    props: {
      sportId: {
        type: String,
        required: true,
      },
      leagueId: {
        type: String,
        required: true,
      },
      short: {
        type: Boolean,
        required: false,
        default: false,
      },
      showMoreMatchesVisible: {
        type: Boolean,
        default: false,
      },
      collapsible: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        marketsVisibleOnMatchDetail: true,
        initialLoad: true,
      };
    },
    computed: {
      ...mapGetters(`LiveStore`, {
        getSingleSport: liveSportStoreGetters.GET_SPORT_BY_ID,
        getFilteredMatchCountBySport: liveMatchGetters.GET_FILTERED_MATCHES_COUNT_BY_SPORT,
        isSportCollapsed: liveSportStoreGetters.GET_SPORT_COLLAPSE_STATE,
        isAllLeaguesOpenBySport: IS_ALL_LEAGUES_OPEN,
      }),
      ...mapGetters(`AppConfigurationStore`, {
        getConfigurationValue: APP_CONF_GET_CONF_VALUE,
      }),
      info() {
        return {
          id: this.sportId,
          category: this.collapsible ? `generic` : `single`,
        };
      },
      sport() {
        return this.getSingleSport(this.sportId);
      },
      matchCount() {
        return this.getFilteredMatchCountBySport(this.sportId);
      },
      isAllLeaguesOpen() {
        return this.isAllLeaguesOpenBySport(this.info);
      },
      limitMatches() {
        return this.showMoreMatchesVisible
          && this.isFeatureSupported(FEATURE_LIMITED_OVERVIEW)
          && this.getConfigurationValue(`overviewMatchLimitPerSport`) < this.matchCount;
      },
      isCollapsed() {
        return this.collapsible && this.isSportCollapsed(this.info);
      },
    },
    methods: {
      ...mapActions(`LiveStore`, {
        toggleSportState: liveSportActions.SPORT_TOGGLE_OPEN_STATE,
        openLeaguesBySport: TOGGLE_LEAGUES_BY_SPORT,
      }),
      ...mapMutations(`LiveStore`, {
        setFavoriteCollapseState: $SET_FAVORITE_COLLAPSE_STATE,
      }),
      toggleSport() {
        this.toggleSportState(this.info);
      },
      toggleLeagues() {
        if (this.isCollapsed) {
          this.toggleSport();
        }
        this.openLeaguesBySport(this.info);
      },
    },
  };
</script>
