import { APP_CONF_GET_CONF_VALUE } from '@/stores/AppConfigurationStore/constants';
import Vue from 'vue';
import { getters as marketStoreGetters } from './LiveMarketStore';
import { getters as ticketStoreGetters } from './ticket/TicketStore';

export const types = {
  ODD_SELECT: `ODD_SELECT`,
  ODD_DESELECT: `ODD_DESELECT`,
  ODDS_ADD: `ODDS_ADD`,
  ODDS_REMOVE: `ODDS_REMOVE`,
  CLEAR_SELECTED_ODDS: `CLEAR_SELECTED_ODDS`,
  ODDS_UPDATE_ODD_BY_MARKET_ID: `ODDS_UPDATE_ODD_BY_MARKET_ID`,
  ODDS_UPDATE_BY_ID_AND_DISPLAY: `ODDS_UPDATE_BY_ID_AND_DISPLAY`,
  ODDS_ADD_TO_ALL_TICKET_ODDS: `ODDS_ADD_TO_ALL_TICKET_ODDS`,
  ODDS_REMOVE_FROM_ALL_TICKET_ODDS: `ODDS_REMOVE_FROM_ALL_TICKET_ODDS`,
  ODDS_CHANGE_ACTIVE_TICKET_SLOT: `ODDS_CHANGE_ACTIVE_TICKET_SLOT`,
};

export const actions = {
  ODDS_ADD: `ODDS_ADD`,
  ODDS_ADD_LIST: `ODDS_ADD_LIST`,
  ODDS_REMOVE: `ODDS_REMOVE`,
  TOGGLE_ODDS_SELECTION: `TOGGLE_ODDS_SELECTION`,
  RESTORE_TICKET_ODDS_SELECTION: `RESTORE_TICKET_ODDS_SELECTION`,
  ODDS_CHANGE_BY_KOM_RESPONSE: `ODDS_CHANGE_BY_KOM_RESPONSE`,
  ODDS_ADD_BY_MARKET_ID: `ODDS_ADD_BY_MARKET_ID`,
  ODDS_UPDATE_BY_MARKET_ID_AND_DISPLAY_NUMBER: `ODDS_UPDATE_BY_MARKET_ID_AND_DISPLAY_NUMBER`,
  ODDS_CHANGE_ACTIVE_TICKET_SLOT: `ODDS_CHANGE_ACTIVE_TICKET_SLOT`,
};

export const getters = {
  ODDS_GET_BY_MARKET_ID: `ODDS_GET_BY_MARKET_ID`,
  SELECTED_ODDS: `SELECTED_ODDS`,
  GET_BET_VARIANT_FROM_MARKET_BY_TIP_ID: `GET_BET_VARIANT_FROM_MARKET_BY_TIP_ID`,
  GET_ODDS_IDS_BY_MARKET_ID: `GET_ODDS_IDS_BY_MARKET_ID`,
  GET_ODDS_IDS_BY_MARKET_ID_ORDERED_BY_VALUE: `GET_ODDS_IDS_BY_MARKET_ID_ORDERED_BY_VALUE`,
  GET_ALL_TICKET_ODDS: `GET_ALL_TICKET_ODDS`,
  GET_ACTIVE_TICKET_SLOT: `GET_ACTIVE_TICKET_SLOT`,
};

const LiveOddsStore = {
  state: {
    odds: {},
    selectedOdds: {},
    activeTicketSlot: 0,
    allTicketsOdds: {
      0: {},
      1: {},
      2: {},
      3: {},
    },
  },
  mutations: {
    [types.ODDS_ADD_TO_ALL_TICKET_ODDS](state, { oddsId, marketId, unrelatedContingencyTips, slotId = 0 }) {
      let selectedMarketOdds = state.allTicketsOdds[slotId][marketId] || [];

      // unrelatedContingencyTips means there can be multiple odds from single market on the ticket - e.g. goad scorers
      if (unrelatedContingencyTips) {
        selectedMarketOdds.push(oddsId);
      } else {
        selectedMarketOdds = [oddsId];
      }

      Vue.set(state.allTicketsOdds[slotId], marketId, selectedMarketOdds);
    },
    [types.ODDS_REMOVE_FROM_ALL_TICKET_ODDS](state, { oddsId, marketId, slotId }) {
      let selectedMarketOdds = state.allTicketsOdds[slotId][marketId] || [];

      selectedMarketOdds = selectedMarketOdds.filter((odd) => odd !== oddsId);
      if (selectedMarketOdds.length > 0) {
        Vue.set(state.allTicketsOdds[slotId], marketId, selectedMarketOdds);
      } else {
        Vue.delete(state.allTicketsOdds[slotId], marketId);
      }
    },
    [types.ODDS_CHANGE_ACTIVE_TICKET_SLOT](state, slotId) {
      state.activeTicketSlot = slotId;
    },
    [types.ODDS_ADD](state, [marketId, oddsList]) {
      const newOddsAdded = Object.values(oddsList).some((odd) => state.odds?.[marketId]?.[odd.id] === undefined);

      if (newOddsAdded) {
        Vue.set(state.odds, marketId, oddsList);
      } else {
        Object.values(oddsList).forEach((odd) => {
          const oddChanged = Object.keys(odd).some((key) => odd[key] !== state.odds[marketId][odd.id][key]);

          if (oddChanged) {
            Vue.set(state.odds[marketId], odd.id, odd);
          }
        });
      }
    },
    [types.ODD_SELECT](state, { oddsId, market, marketRowId }) {
      let selectedMarketOdds = state.selectedOdds[marketRowId] || [];

      // unrelatedContingencyTips means there can be multiple odds from single market on the ticket - e.g. goad scorers
      if (market?.unrelatedContingencyTips) {
        selectedMarketOdds.push(oddsId);
      } else {
        selectedMarketOdds = [oddsId];
      }

      Vue.set(state.selectedOdds, marketRowId, selectedMarketOdds);
    },
    [types.ODD_DESELECT](state, { oddsId, marketRowId }) {
      let selectedMarketOdds = state.selectedOdds[marketRowId] || [];

      selectedMarketOdds = selectedMarketOdds.filter((odd) => odd !== oddsId);

      if (selectedMarketOdds.length > 0) {
        Vue.set(state.selectedOdds, marketRowId, selectedMarketOdds);
      } else {
        Vue.delete(state.selectedOdds, marketRowId);
      }
    },
    [types.ODDS_REMOVE](state, marketId) {
      Vue.delete(state.odds, marketId);
    },
    [types.CLEAR_SELECTED_ODDS](state) {
      Vue.set(state, `selectedOdds`, {});
      Vue.set(state.allTicketsOdds, state.activeTicketSlot, {});
    },
    [types.ODDS_UPDATE_BY_ID_AND_DISPLAY](state, { marketId, oddsIndex, field, value }) {
      // displayOrder is counted from 1, hence + 1
      const foundOdds = (Object.values(state.odds[marketId]) || []).find((odds) => odds.displayOrder === oddsIndex + 1);

      if (!foundOdds) return;
      const stateOdd = state.odds[marketId]?.[foundOdds.id];

      if (!stateOdd) return;
      Vue.set(stateOdd, field, value);
    },
    [types.ODDS_UPDATE_ODD_BY_MARKET_ID](state, newOddsValues) {
      (newOddsValues || []).forEach(({ marketId, selectedOddsId, value }) => {
        const oddsRowByMarketId = state.odds[marketId];

        // TODO: 2020-04-27 - Why is this situation possible, that `oddsRowByMarketId` is undefined at this point
        if (!oddsRowByMarketId) return;
        const oddsById = oddsRowByMarketId[selectedOddsId];
        const { value: currentValue, id: oddsId } = oddsById;

        if (oddsById && value !== currentValue) {
          Vue.set(state.odds[marketId][oddsId], `value`, value);
        }
      });
    },
  },
  actions: {
    [actions.ODDS_ADD_LIST]({ commit }, marketList) {
      Object
        .entries(marketList)
        .forEach((oddsEntries) => commit(types.ODDS_ADD, oddsEntries));
    },
    [actions.TOGGLE_ODDS_SELECTION]({ commit, getters: moduleGetters }, { isSelected, oddsId, marketId, slotId }) {
      const activeSlotId = slotId !== undefined ? slotId : moduleGetters[getters.GET_ACTIVE_TICKET_SLOT] || 0;

      if (isSelected) {
        const market = moduleGetters[marketStoreGetters.GET_MARKET_BY_MARKET_ROW_ID](marketId);

        commit(types.ODD_SELECT, { oddsId, market, marketRowId: marketId });
        commit(types.ODDS_ADD_TO_ALL_TICKET_ODDS, {
          oddsId,
          marketId,
          slotId: activeSlotId,
          unrelatedContingencyTips: market?.unrelatedContingencyTips,
        });
      } else {
        commit(types.ODD_DESELECT, { oddsId, marketRowId: marketId });
        commit(types.ODDS_REMOVE_FROM_ALL_TICKET_ODDS, {
          oddsId,
          marketId,
          slotId: activeSlotId,
        });
      }
    },
    [actions.ODDS_CHANGE_ACTIVE_TICKET_SLOT]({ commit }, slotId) {
      commit(types.ODDS_CHANGE_ACTIVE_TICKET_SLOT, slotId);
    },
    [actions.ODDS_REMOVE]({ commit }, marketId) {
      commit(types.ODDS_REMOVE, marketId);
    },
    [actions.ODDS_UPDATE_BY_MARKET_ID_AND_DISPLAY_NUMBER]({ commit, rootState },
      { marketRowId, oddsIndex, field, value }) {
      const marketId = rootState.LiveStore.LiveMarketStore.marketsMapping[marketRowId];

      commit(types.ODDS_UPDATE_BY_ID_AND_DISPLAY, { marketId, oddsIndex, field, value });
    },
    [actions.ODDS_ADD_BY_MARKET_ID]({ commit, rootState },
      { marketRowId, odds }) {
      const marketId = rootState.LiveStore.LiveMarketStore.marketsMapping[marketRowId];

      commit(types.ODDS_ADD, [marketId, odds]);
    },
    [actions.ODDS_CHANGE_BY_KOM_RESPONSE]({ commit, rootGetters }, { komMessages }) {
      const betslipCodes = rootGetters[
        `AppConfigurationStore/${APP_CONF_GET_CONF_VALUE}`](`checkOddsValueForBetslipCodes`);
      const actualTicket = rootGetters[`TicketStore/${ticketStoreGetters.GET_TICKET}`];
      const { items: actualTicketItems } = actualTicket;

      const newOddsValues = komMessages.reduce((acc, { params = [], itemIndex = null, code }) => {
        const canChangeOdds = betslipCodes.indexOf(code) !== -1;
        const ticketItem = actualTicketItems[itemIndex];
        let value = parseFloat(params[0]);

        if (Number.isNaN(value)) {
          value = -1;
        }

        if (canChangeOdds && !!ticketItem) {
          const { selectedOdds: { oddsId: selectedOddsId }, marketId } = ticketItem;

          acc.push({ marketId, selectedOddsId, value });
        }
        return acc;
      }, []);

      commit(types.ODDS_UPDATE_ODD_BY_MARKET_ID, newOddsValues);
    },
    /**
     * If optimistic selection fails,
     * odd selection needs to be synced with odds actually selected in the ticket
     * @param commit
     * @param rootGetters
     */
    [actions.RESTORE_TICKET_ODDS_SELECTION]({ commit, getters: moduleGetters, rootGetters }) {
      const ticketOddsList = rootGetters[`TicketStore/${ticketStoreGetters.GET_TICKET_ODDS}`];
      const activeSlotId = moduleGetters[getters.GET_ACTIVE_TICKET_SLOT] || 0;

      commit(types.CLEAR_SELECTED_ODDS);

      ticketOddsList.forEach(({ marketId, oddsId }) => {
        const market = moduleGetters[marketStoreGetters.GET_MARKET_BY_MARKET_ROW_ID](marketId);

        commit(types.ODD_SELECT, { marketRowId: marketId, market, oddsId });
        commit(types.ODDS_ADD_TO_ALL_TICKET_ODDS, {
          oddsId,
          marketId,
          slotId: activeSlotId,
          unrelatedContingencyTips: market?.unrelatedContingencyTips,
        });
      });
    },
  },
  getters: {
    [getters.GET_ACTIVE_TICKET_SLOT](state) {
      return state.activeTicketSlot;
    },
    [getters.GET_ALL_TICKET_ODDS](state) {
      return Object.values(state.allTicketsOdds).reduce((acc, odds) => {
        const spreadOddsIds = Object.values(odds).reduce((spreadOddsAcc, oddsIds) => {
          spreadOddsAcc.push(...oddsIds);
          return spreadOddsAcc;
        }, []);

        return [...acc, ...spreadOddsIds];
      }, []);
    },
    [getters.GET_ODDS_IDS_BY_MARKET_ID](state, _getters, rootState) {
      return (marketRowId) => {
        const marketId = rootState.LiveStore.LiveMarketStore.marketsMapping[marketRowId];

        return Object.values(state.odds[marketId] || {})
          .filter((odd) => odd.marketId === marketRowId)
          .map((odd) => odd.id) || [];
      };
    },
    [getters.GET_ODDS_IDS_BY_MARKET_ID_ORDERED_BY_VALUE](state, _getters, rootState) {
      return (marketRowId) => {
        const marketId = rootState.LiveStore.LiveMarketStore.marketsMapping[marketRowId];

        return Object.values(state.odds[marketId] || {})
          .filter((odd) => odd.marketId === marketRowId && odd.value !== -1)
          .sort((a, b) => a.value - b.value)
          .map((odd) => odd.id) || [];
      };
    },
    [getters.ODDS_GET_BY_MARKET_ID](state, moduleGetters) {
      return (marketRowId) => {
        let odds = state.odds[marketRowId];

        if (!odds) {
          const { id: marketId } = moduleGetters[marketStoreGetters.GET_MARKET_BY_MARKET_ROW_ID](marketRowId) || {};

          odds = Object
            .values(state.odds[marketId] || {})
            .reduce((acc, odd) => {
              if (odd && odd.marketId === marketRowId) {
                acc[odd.id] = odd;
              }
              return acc;
            }, {});
        }

        return Object.values(odds || {});
      };
    },
    [getters.SELECTED_ODDS](state) {
      return state.allTicketsOdds[state.activeTicketSlot] || {};
    },
    [getters.GET_BET_VARIANT_FROM_MARKET_BY_TIP_ID](state, moduleGetters) {
      return (currentMarketId, currentTipId) => {
        const currentOdds = moduleGetters[getters.ODDS_GET_BY_MARKET_ID](currentMarketId);
        const currentTip = currentOdds.find(({ tipId }) => tipId === currentTipId) || ``;

        return currentTip.names || ``;
      };
    },
    [getters.GET_ODD](state, _getters, rootState) {
      return (id, marketRowId) => {
        const marketId = rootState.LiveStore.LiveMarketStore.marketsMapping[marketRowId];

        return state.odds[marketId]?.[id];
      };
    },
  },
};

export default LiveOddsStore;
