import { LoggerService } from "@fortunaweb-fe/frontend-commons";
import { getKibanaFormatTimestamp, getUserID } from '@/services/Logger/Logger.helpers';
import { loggerSeverity, loggerCustomEvents, loggerEventTypes } from '@/globals/enums/Logger.enums';
import Lifecycle from '@/services/LifecycleHooks';
import { APP_LOGIN_RESOLVED } from '@/globals/enums/eventBus/App.enums';
import { resolveEndpoint } from '@/config/constants';
import api from '../../config/api/live';

export class Logger extends LoggerService {
  constructor() {
    super({
      api,
      endpoint: resolveEndpoint(`LOGGING`),
    });

    Lifecycle.$on(APP_LOGIN_RESOLVED, ({ isManualLogin }) => {
      if (isManualLogin) {
        this.trackUserLogin();
      }
    });
  }

  logMessage(severity, data, immediate) {
    data.username = getUserID();
    this.server(severity, data, immediate);
  }

  trackStakeChange = ({ element, prevValue, stakeValue }) => {
    const sliderUpdateInfo = {
      event: loggerCustomEvents.BETSLIP_STAKE_CHANGE,
      element,
      stakeValue,
      changed: prevValue !== stakeValue,
      timestamp: getKibanaFormatTimestamp(),
    };

    this.logMessage(loggerSeverity.INFO, sliderUpdateInfo);
  };

  trackUserLogin = () => {
    const userLoginInfo = {
      event: loggerCustomEvents.USER_LOGIN,
      status: loggerEventTypes.SUCCESS,
      timestamp: getKibanaFormatTimestamp(),
    };

    this.logMessage(loggerSeverity.INFO, userLoginInfo);
  };

  trackUserLoginError = (username, message = `Login error!`, errorCode) => {
    const userLoginError = {
      event: loggerCustomEvents.USER_LOGIN_FAILED,
      status: loggerEventTypes.FAIL,
      timestamp: getKibanaFormatTimestamp(),
      username,
      errorText: message,
      errorCode,
    };

    this.logMessage(loggerSeverity.ERROR, userLoginError);
  };

  trackHandlingTypeChange = (selected) => {
    const handlingTypeInfo = {
      event: loggerCustomEvents.HANDLING_TYPE_CHANGE,
      timestamp: getKibanaFormatTimestamp(),
      selected,
    };

    this.logMessage(loggerSeverity.INFO, handlingTypeInfo);
  };
}

const FortunaLogger = new Logger();

export default FortunaLogger;
