import { v4 as uuidv4 } from 'uuid';

const generateUuidAndWarn = () => {
  // eslint-disable-next-line no-console
  console.warn(`--- owner id generated randomly ---\nThis will and cause problems with unsubscribing!`);
  return uuidv4();
};

const throwDefaultError = (name) => {
  throw new Error(`Parameter ${name} is required`);
};

export class SubBox {
  constructor(subscribe, unSubscribe) {
    this.subscriptions = {};
    this.subscribe = subscribe;
    this.unSubscribe = unSubscribe;
  }

  addSubscriber = (id = throwDefaultError(`id`), owner = generateUuidAndWarn()) => {
    let shouldOpenSubscription = true;
    const subscription = this.subscriptions[id];

    if (!subscription) {
      this.subscriptions[id] = { id, owners: [owner] };
    } else {
      const { owners } = subscription;

      if (owners.indexOf(owner) === -1) {
        this.subscriptions[id] = { id, owners: [...owners, owner] };
      }

      shouldOpenSubscription = false;
    }

    if (shouldOpenSubscription) this.subscribe(id);

    return owner;
  };

  removeSubscriber = (id = throwDefaultError(`id`), owner = throwDefaultError(`owner`)) => {
    let shouldStopSubscription = false;
    const subscription = this.subscriptions[id];

    if (subscription) {
      const { owners } = subscription;
      const ownerIndex = owners.indexOf(owner);

      if (ownerIndex > -1) {
        owners.splice(ownerIndex, 1);
        const newOwners = owners;

        if (newOwners.length === 0) {
          shouldStopSubscription = true;
          delete this.subscriptions[id];
        } else {
          this.subscriptions[id] = { id, owners: newOwners };
        }
      }

      if (shouldStopSubscription) this.unSubscribe(id);
    }
  };
}
