/**
 * Creates a message dispatcher for processing of websocket messages
 * (both info or change message) that is able to process either
 * single message or an array of messages.
 *
 * @param {Function} singleMessageDispatcher
 * @returns {Function}
 */
function messageDispatcherFactory(singleMessageDispatcher) {
  return (message, handlers, options = {}) => {
    if (Array.isArray(message)) {
      message.forEach((m) => singleMessageDispatcher(m, handlers, options));
    } else {
      singleMessageDispatcher(message, handlers, options);
    }
  };
}

export function processSingleChangeMessage(message, handlers, options = {}) {
  const { operation, type } = message;
  const typeHandler = handlers[type];

  if (!typeHandler) {
    // eslint-disable-next-line no-console
    console.error(`[ws] Handler for '${type}' not implemented yet. Can't handle '${operation}'`);
    return;
  }
  const operationHandler = typeHandler[operation];

  if (!operationHandler) {
    // eslint-disable-next-line no-console
    console.error(`[ws] Handler for '${type}/${operation}' not implemented.`);
    return;
  }
  operationHandler(message, options);
}

export const processChangeMessage = messageDispatcherFactory(processSingleChangeMessage);

export const parseIncomingMessage = (message) => JSON.parse(message.body);

export function processSingleInfoMessage(message, handlers, options = {}) {
  const { type } = message;
  const typeHandler = handlers[type];

  if (!typeHandler) {
    // eslint-disable-next-line no-console
    console.error(`[ws] Handler for '${type}' not implemented yet.`);
    return;
  }
  typeHandler(message, options);
}

export const processInfoMessage = messageDispatcherFactory(processSingleInfoMessage);
