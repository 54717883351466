const polyfills = [];

// Intersection Observer
if (`IntersectionObserver` in window
  && `IntersectionObserverEntry` in window
  && `intersectionRatio` in window.IntersectionObserverEntry.prototype) {
  // Minimal polyfill for Edge 15's lack of `isIntersecting`
  // See: https://github.com/w3c/IntersectionObserver/issues/211
  if (!(`isIntersecting` in window.IntersectionObserverEntry.prototype)) {
    Object.defineProperty(window.IntersectionObserverEntry.prototype,
      `isIntersecting`,
      {
        get() {
          return this.intersectionRatio > 0;
        },
      });
  }

  polyfills.push(import(/* webpackChunkName: "intersection-observer" */ `intersection-observer`));
}

// No need for RAF or Mutation observer since we are IE 11+

export default polyfills;
