/**
 * Returns offset of element relative to viewport
 *
 * @param {Element} el
 * @return {{top: number, left: number}}
 */
export function offsetToViewport(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
}

/**
 * Returns offset of element relative to ancestor
 *
 * If ancestor is not passed, returns offset of element relative to document.
 *
 * @param {HtmlElement} el
 * @param {Element} ancestor
 * @return {{top: number, left: number}}
 * @link https://stanko.github.io/javascript-get-element-offset/
 */
export function offsetToAncestor(el, ancestor = document.documentElement) {
  let top = 0;
  let left = 0;
  let element = el;

  // Loop through the DOM tree
  // and add it's parent's offset to get page offset
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element && element !== ancestor);

  return {
    top,
    left,
  };
}

function isNumeric(val) {
  return !Number.isNaN(parseFloat(val)) && Number.isFinite(val);
}

export function elementHeight(element) {
  const height = element ? element.clientHeight : 0;

  return isNumeric(height) ? height : 0;
}

export function pixelsToNumber(val) {
  const parsed = parseFloat(val);

  return isNumeric(parsed) ? parsed : 0;
}

export function hasClass(element, className) {
  return element.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
}

export function removeClass(element, className) {
  if (hasClass(element, className)) {
    const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);

    element.className = element.className.replace(reg, ``);
  }
}

export function addClass(element, className) {
  if (!hasClass(element, className)) {
    element.className += ` ${className}`;
  }
}

/**
 * Link script dynamically into dom check for attributes and added or updated them
 *
 * @param {String} src
 * @param {String} elementId
 * @param {Object} dataAttributes
 * @return {Promise}
 */
export function addScriptDynamically(src, elementId = null, dataAttributes = {}) {
  let scriptElement = document.getElementById(elementId);
  const headElement = document.head;

  if (!scriptElement) {
    scriptElement = document.createElement(`script`);
    scriptElement.src = src;
    scriptElement.async = true;
    scriptElement.id = elementId;
    headElement.appendChild(scriptElement);
  } else {
    return Promise.resolve();
  }

  if (dataAttributes) {
    Object.keys(dataAttributes).forEach((key) => {
      if (scriptElement.getAttribute(key) !== dataAttributes[key]) {
        scriptElement.setAttribute(key, dataAttributes[key]);
      }
    });
  }

  return new Promise((resolve, reject) => {
    scriptElement.onload = resolve;
    scriptElement.onerror = reject;
  });
}

/**
 * Convert xml string into dom
 *
 * @param {String} xmlString
 * @return {Document} DOM
 */
export function domFromXml(xmlString) {
  if (!xmlString) return null;
  const parsedDom = new DOMParser().parseFromString(xmlString, `application/xml`);

  if (parsedDom.getElementsByTagName(`parsererror`).length > 0) return null;
  return parsedDom;
}

/**
 * @param el {node}
 * @returns {string}
 */
export function getElementText(el) {
  let text = ``;

  // Text node (3) or CDATA node (4) - return its text
  if ((el.nodeType === 3) || (el.nodeType === 4)) {
    text = el.nodeValue;
    // If node is an element (1) and an img, input[type=image], or area element, return its alt text
  } else if ((el.nodeType === 1) && (
    (el.tagName.toLowerCase() === `img`)
    || (el.tagName.toLowerCase() === `area`)
    || ((el.tagName.toLowerCase() === `input`)
      && el.getAttribute(`type`)
      && (el.getAttribute(`type`).toLowerCase() === `image`))
  )) {
    text = el.getAttribute(`alt`) || ``;
    // Traverse children unless this is a script or style element
  } else if ((el.nodeType === 1) && !el.tagName.match(/^(script|style)$/i)) {
    const children = el.childNodes;

    // eslint-disable-next-line no-plusplus
    for (let i = 0, l = children.length; i < l; i++) {
      text += getElementText(children[i]);
    }
  }
  return text;
}

export const testForStickySupport = () => {
  const testNode = document.createElement(`div`);
  const stickyTest = [``, `-webkit-`, `-moz-`, `-ms-`].some((prefix) => {
    try {
      testNode.style.position = `${prefix}sticky`;
      // eslint-disable-next-line no-empty
    } catch (e) {
    }

    return testNode.style.position !== ``;
  });

  return stickyTest;
};

export const hasStickySupport = testForStickySupport();

export function checkElementVisibility(element, threshold) {
  const rect = element.getBoundingClientRect();
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

  return !(rect.bottom < 0 || rect.top - (viewHeight + threshold) >= 0);
}
