export const MATCH_STATE_NO_MARKETS = `MATCH_STATE_NO_MARKETS`;
/**
 * State that describes that current match has finished
 *
 * Intentionally uses same value as scoreboard state
 * so that we may consider both state equal.
 * This approach is needed until fortuna sportsbook
 * (database) has match entity #callForMatchEntity
 *
 * @type {string}
 */
export const MATCH_STATE_FINISHED = `END`;
